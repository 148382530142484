import React, { useState } from "react";
import { PlayOutline } from "app/components/Icon";
import { DropdownOption } from "app/components/Dropdown";
import { Class } from "services/graphql";
import {
  MobileClassPreviewModal,
  DesktopClassPreviewModal,
} from "app/components/ClassComponents/ClassPreviewModal";
import { IS_MOBILE } from "constants/index";

interface Props {
  classData: Partial<Class>;
  onUserSelectsClass(): void;
}

export const PreviewClassOption = ({
  classData,
  onUserSelectsClass,
}: Props) => {
  const [isClassPreviewModalShowing, setIsClassPreviewModalShowing] = useState(
    false
  );

  return (
    <>
      <DropdownOption
        Svg={PlayOutline}
        onClick={() => {
          setIsClassPreviewModalShowing(true);
        }}
        text="Preview Class"
      />
      {isClassPreviewModalShowing && (
        <ClassPreviewModal
          classData={classData}
          closeModal={() => setIsClassPreviewModalShowing(false)}
          onUserSelectsClass={onUserSelectsClass}
        />
      )}
    </>
  );
};

function ClassPreviewModal({
  classData,
  closeModal,
  onUserSelectsClass,
}: {
  classData: Partial<Class>;
  closeModal(): void;
  onUserSelectsClass(): void;
}) {
  if (IS_MOBILE) {
    return (
      <MobileClassPreviewModal classData={classData} closeModal={closeModal} />
    );
  }

  return (
    <DesktopClassPreviewModal
      classId={classData.id?.toString()}
      closeModal={closeModal}
      goToClass={onUserSelectsClass}
    />
  );
}
