import React, { useState } from "react";
import { H2 } from "app/components/Typography";
import Thumbnail from "app/components/Thumbnail";
import env from "helpers/env";
import Flex from "app/components/Flex";
import IconButton from "app/components/Button/IconButton";
import Icon, {
  Close,
  Heart,
  HeartFilled,
  Share,
  ScheduleAdd,
  PlusBox,
} from "app/components/Icon";
import { Class } from "services/graphql";
import Div from "app/components/Div";
import Text from "app/components/Text";
import styled from "styled-components";
import useToggleSaveClass from "hooks/Classes/useToggleSaveClass";
import { useDispatch } from "react-redux";
import { toggleAddClassModalAction } from "modules/components";
import Modal from "app/components/Modal";
import { AddToPlaylistsModal } from "app/components/PlaylistComponents/PlaylistModals";
import { DownloadMobileAppBadges } from "../DownloadMobileAppBadges";

const StyledModal = styled(Modal)`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  border-radius: 0;
  overflow: auto;
`;

const THUMBNAIL_RATIO = 130 / 280;

const ThumbnailWrapper = styled(Div)`
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
`;

const ThumbnailContainer = styled(Div)`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  padding-top: ${THUMBNAIL_RATIO * 100}%;
  position: relative;
  margin: 16px 0;
`;

interface Props {
  classData: Partial<Class>;
  classRefId?: string;
  closeModal(): void;
}

export function MobileClassPreviewModal({
  classData,
  classRefId,
  closeModal,
}: Props) {
  const dispatch = useDispatch();
  const toggleSaveClassMutation = useToggleSaveClass();
  const [isAddToPlaylistModalOpen, setIsAddToPlaylistsModalOpen] = useState(
    false
  );

  return (
    <>
      <StyledModal isOpen p="16px">
        <Flex flexDirection="column" justifyContent="space-between">
          <Flex flexDirection="column">
            <Flex flexDirection="column" width="100%" p={2}>
              <Flex justifyContent="flex-end" width="100%">
                <IconButton
                  Icon={Close}
                  width="20px"
                  height="auto"
                  color="black"
                  onClick={closeModal}
                />
              </Flex>
            </Flex>

            <ThumbnailContainer>
              <ThumbnailWrapper>
                <Thumbnail
                  src={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                    classData.slug
                  }-basic.jpg?w=1200`}
                  placeholderSrc={`${env(
                    "PUBLIC_ASSET_PATH"
                  )}/class-thumbnails/${
                    classData.slug
                  }-basic.jpg?blur=200&px=16&auto=format`}
                />
              </ThumbnailWrapper>
            </ThumbnailContainer>
            <Flex flexDirection="column" alignItems="center">
              <H2 my={2} textAlign="center" fontSize="22px">
                {classData.title}
              </H2>
              {classData.instructor?.name && classData.instructor?.slug && (
                <Text fontSize="18px" fontWeight="500" textAlign="center">
                  {classData.instructor.name}
                </Text>
              )}
            </Flex>
            <Flex flexDirection="column" gap="24px" mt="16px">
              <Flex
                alignItems="center"
                gap="8px"
                onClick={() =>
                  toggleSaveClassMutation({
                    component: "MobileClassPreviewModal",
                    variables: {
                      classId: classData.id,
                      willSave: !classData.isSaved,
                      programClassRefId: classRefId,
                    },
                    classData,
                  })
                }
              >
                <Icon
                  width="18px"
                  height="18px"
                  as={classData.isSaved ? HeartFilled : Heart}
                />
                <Text fontSize="14px" fontWeight="400">
                  {classData.isSaved
                    ? "Remove from saved classes"
                    : "Add to saved classes"}
                </Text>
              </Flex>
              <Flex
                alignItems="center"
                gap="8px"
                onClick={() => {
                  setIsAddToPlaylistsModalOpen(true);
                }}
              >
                <Icon width="18px" height="18px" as={PlusBox} />
                <Text fontSize="14px" fontWeight="400">
                  Add to playlist
                </Text>
              </Flex>
              <Flex
                alignItems="center"
                gap="8px"
                onClick={() => {
                  dispatch(toggleAddClassModalAction(classData.id, classRefId));
                }}
              >
                <Icon width="18px" height="18px" as={ScheduleAdd} />
                <Text fontSize="14px" fontWeight="400">
                  Add to schedule
                </Text>
              </Flex>
              <Flex
                alignItems="center"
                gap="8px"
                onClick={() =>
                  navigator.share({
                    url: `${env("PUBLIC_PARKER_URL")}/class/preview/${
                      classData.id
                    }`,
                    text: `Check out this STEEZY Studio video from ${classData.instructor?.name}`,
                    title: classData.title,
                  })
                }
              >
                <Icon width="18px" height="18px" as={Share} />
                <Text fontSize="14px" fontWeight="400">
                  Share class
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Div my={4}>
            <DownloadMobileAppBadges />
          </Div>
        </Flex>
      </StyledModal>
      {isAddToPlaylistModalOpen && (
        <AddToPlaylistsModal
          classId={classData.studioClassId}
          classNumber={Number(classData.id)}
          closeModal={() => setIsAddToPlaylistsModalOpen(false)}
        />
      )}
    </>
  );
}
