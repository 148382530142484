import { useState, useEffect } from "react";
import isServer from "helpers/isServer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

export const useRedirectNoAccess = () => {
  const history = useHistory();

  const isAnonymous = useSelector(({ auth }: any) => auth.isAnonymous);

  return () => {
    if (isAnonymous) {
      history.push(`/login?redirect=${history.location?.pathname}`);
      return true;
    }

    return false;
  };
};

export function useGetScreenSizes() {
  const [screenSizes, setScreenSizes] = useState(getScreenSizes());
  const mockSizes = useSelector(({ server }: any) => server.mockDevice);

  useEffect(() => {
    const handleResize = () => {
      setScreenSizes(getScreenSizes());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenSizes ?? mockSizes;
}

function getScreenSizes() {
  if (isServer) {
    return undefined;
  }
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
