import styled from "styled-components";
import media from "styled-media-query";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${media.greaterThan("small")`
    flex-direction: row;
    padding: 24px;
    min-height: 466px;
  `}
`;

export const VideoPreviewContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.monochrome[8]};
  width: 100%;
  flex: 0 0 1;
  min-height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${media.greaterThan("small")`
    flex: 0 0 235px;
    height: 418px;
  `}
`;

export const Footer = styled.div`
  display: flex;
  padding: 12px 24px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.monochrome[1]};
  width: 100%;

  button {
    width: 100%;
  }

  ${media.greaterThan("small")`
    button {
      width: auto;
    }
  `}
`;

export const UploadingText = styled.div`
  display: none;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.monochrome[6]};

  ${media.greaterThan("small")`
    display: block;
  `}
`;

export const UploadedText = styled.div`
  display: none;
  font-size: 14px;
  color: ${props => props.theme.colors.primary[0]};

  ${media.greaterThan("small")`
    display: block;
  `}
`;

export const VideoPreviewOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
`;

export const VideoDescriptionLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 12px;
  display: none;

  ${media.greaterThan("small")`
    display: block;
  `}
`;

export const Video = styled.video`
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${media.greaterThan("small")`
    width: 100%;
    height: auto;
  `}
`;

export const VideoDescriptionTextarea = styled.textarea`
  border: 2px solid ${({ theme }) => theme.colors.monochrome[1]};
  outline: none;
  width: 100%;
  font-size: 14px;
  resize: none;
  min-height: 115px;
  padding: 10px 30px 10px 10px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.monochrome[3]};
  }
`;
