import ReactPlayerComponent from "react-player";
import styled from "styled-components";

export const ReactPlayer = styled(ReactPlayerComponent)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;

  /* iFrame styling for YouTube links to hide title */
  iframe {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: calc(100% + 120px);
  }

  video {
    object-fit: cover;
  }
`;
