import { gql } from "@apollo/client";

const ACTIVITY_REACTION_CREATE = gql`
  mutation ActivityReactionCreate($input: ActivityReactionCreateInput!) {
    activityReactionCreate(input: $input) {
      id
      reactionType
    }
  }
`;

const ACTIVITY_REACTION_DELETE = gql`
  mutation ActivityReactionDelete($input: ActivityReactionDeleteInput!) {
    activityReactionDelete(input: $input)
  }
`;

const FLAGGED_ACTIVITY_CREATE = gql`
  mutation FlaggedActivityCreate($input: FlaggedActivityCreateInput!) {
    flaggedActivityCreate(input: $input) {
      id
    }
  }
`;

export {
  ACTIVITY_REACTION_CREATE,
  ACTIVITY_REACTION_DELETE,
  FLAGGED_ACTIVITY_CREATE,
};
