import IconButton from "app/components/Button/IconButton";
import { ThreeDots } from "app/components/Icon";
import ReportModal from "app/components/ReportModal";
import { useRedirectNoAccess } from "hooks/Challenges";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import DeleteCommentModal from "../DeleteCommentModal";
import {
  CommentsMenuContainer,
  CommentsMenuOption,
  CommentsMenuOptions,
} from "./styles";

const CommentsMenu = ({
  comment,
  activityId,
  activityType,
  onReportComment,
  isFirstTierComment,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const userId = useSelector(({ user }) => user && user.id);

  const containerRef = useRef(null);

  const redirectNoAccess = useRedirectNoAccess();

  const handleToggleCommentMenu = () => setIsMenuOpen(!isMenuOpen);
  const handleToggleReportModal = () =>
    setIsReportModalOpen(!isReportModalOpen);
  const handleToggleDeleteModal = () =>
    setIsDeleteModalOpen(!isDeleteModalOpen);
  const handleClickReportCommentMenuItem = () => {
    if (redirectNoAccess()) {
      return;
    }

    if (comment.reportedByMe) {
      return;
    }
    handleToggleCommentMenu();
    handleToggleReportModal();
  };
  const handleClickDeleteCommentMenuItem = () => {
    handleToggleCommentMenu();
    handleToggleDeleteModal();
  };

  useEffect(() => {
    if (!isMenuOpen) {
      return () => {};
    }
    const handleClickOutside = e => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, containerRef]);

  const commentOrReplyText = isFirstTierComment ? "comment" : "reply";

  return (
    <CommentsMenuContainer ref={containerRef}>
      <IconButton
        width={15}
        mb={3}
        Icon={ThreeDots}
        onClick={handleToggleCommentMenu}
      />
      <CommentsMenuOptions isOpen={isMenuOpen}>
        {comment.commentBy.id !== userId && (
          <CommentsMenuOption onClick={handleClickReportCommentMenuItem}>
            Report {commentOrReplyText}
          </CommentsMenuOption>
        )}
        {comment.commentBy.id === userId && (
          <CommentsMenuOption onClick={handleClickDeleteCommentMenuItem}>
            Delete {commentOrReplyText}
          </CommentsMenuOption>
        )}
      </CommentsMenuOptions>
      <ReportModal
        activityId={comment.id}
        activityType="user_comment"
        isOpen={isReportModalOpen}
        setIsOpen={handleToggleReportModal}
        onClickReport={onReportComment}
      />
      <DeleteCommentModal
        commentId={comment.id}
        activityId={activityId}
        activityType={activityType}
        isDeleteModalOpen={isDeleteModalOpen}
        onToggleDeleteModal={handleToggleDeleteModal}
        isFirstTierComment={isFirstTierComment}
      />
    </CommentsMenuContainer>
  );
};

CommentsMenu.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string,
    commentBy: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  onReportComment: PropTypes.func.isRequired,
  activityId: PropTypes.string.isRequired,
  activityType: PropTypes.oneOf(["user_video", "user_comment", "challenge"])
    .isRequired,
  isFirstTierComment: PropTypes.bool,
};

export default CommentsMenu;
