import React from "react";
import Icon, { KebabMenu } from "app/components/Icon";
import Tooltip from "app/components/Tooltip";
import styled from "styled-components";

export const StyledButton = styled.button`
  padding: 0;
  border: 0;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  gap: 4px;
  color: ${({ theme }) => theme.colors.monochrome[3]};
`;

export function MenuButton() {
  return (
    <StyledButton>
      <Tooltip overlay="More options">
        <>
          <Icon
            as={KebabMenu}
            width="20px"
            height="20px"
            transform="rotate(90deg)"
            color="monochrome.4"
          />
          More
        </>
      </Tooltip>
    </StyledButton>
  );
}
