import React from "react";
import Icon, { Share } from "app/components/Icon";
import Tooltip from "app/components/Tooltip";
import { StyledButton } from "./styles";

interface Props {
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export function ShareIconWithTextButton({ onClick }: Props) {
  return (
    <Tooltip overlay="Share content">
      <StyledButton
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          // Stop propagation since we handle clicks on Wrapper
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();

          onClick(e);
        }}
      >
        <Icon as={Share} width="20px" height="20px" />
        Share
      </StyledButton>
    </Tooltip>
  );
}
