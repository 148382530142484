import React, { useState } from "react";
import { useGetCommunityAccess } from "hooks/User";
import Flex from "app/components/Flex";
import env from "helpers/env";
import useToggleSaveClass from "hooks/Classes/useToggleSaveClass";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import {
  SaveIconWithTextButton,
  ShareIconWithTextButton,
} from "app/components/Button/IconWithTextButton";
import { Class } from "services/graphql";
import { ANALYTICS_MODULE } from "constants/analytics";
import { UserVideos } from "app/components/UserVideos";
import { ClassesByInstructor } from "../ClassesByInstructor";
import { TrackCopyrights } from "../TrackCopyrights";
import { TrackInfoList } from "../TrackInfoList";
import { ShareModal } from "../ShareModal";
import { SimilarClasses } from "../SimilarClasses";
import { DropdownMenuOptions } from "./DropdownMenuOptions";
import { ClassInfo } from "../ClassInfo";
import {
  Avatar,
  AvatarWrapper,
  ClassTitle,
  HeaderLink,
  InstructorName,
  StyledLink,
  TracksWrapper,
  Wrapper,
} from "./styles";
import {
  SectionTitle,
  MoreContentSection,
  BrowserContainer,
  BrowserWrapper,
} from "../shared-styles";

interface Props {
  classData: Class;
  classRefId?: string;
}

export function DesktopClassDetails({ classData, classRefId }: Props) {
  const [hasCommunityAccess] = useGetCommunityAccess();
  const [isShareModalShowing, setIsShareModalShowing] = useState(false);
  const classTracks = classData?.tracks?.map(({ track }) => track);
  const toggleSaveClassMutation = useToggleSaveClass();

  const { isFamilyModeOn } = useFamilyModeSetting();

  return (
    <>
      <Wrapper>
        <BrowserWrapper>
          <BrowserContainer>
            <Flex
              align-items="center"
              m="12px 10px"
              gap="16px"
              justifyContent="space-between"
              flexDirection={{ _: "column", md: "row" }}
              position="relative"
            >
              <Flex flexDirection="column" mb="12px">
                <ClassTitle>{classData.title}</ClassTitle>
                <AvatarWrapper>
                  <StyledLink
                    to={`/library/instructors/${classData.instructor?.slug}`}
                  >
                    <Avatar
                      src={`${env("PUBLIC_ASSET_PATH")}/choreographers/${
                        classData.instructor?.slug
                      }.png?w=120`}
                    />
                  </StyledLink>
                  <Flex flexDirvection="column" ml="22px">
                    <StyledLink
                      to={`/library/instructors/${classData.instructor?.slug}`}
                    >
                      <InstructorName>
                        {classData.instructor?.name}
                      </InstructorName>
                    </StyledLink>
                  </Flex>
                </AvatarWrapper>
                <ClassInfo classData={classData} />
              </Flex>
              {!isFamilyModeOn && (
                <Flex
                  gap="24px"
                  alignItems={{ _: "center", md: "start" }}
                  justifyContent={{
                    _: "space-evenly",
                    sm: "start",
                  }}
                >
                  <ShareIconWithTextButton
                    onClick={() => setIsShareModalShowing(true)}
                  />
                  <SaveIconWithTextButton
                    isSaved={classData.isSaved}
                    onClick={() => {
                      toggleSaveClassMutation({
                        component: "ClassDetails",
                        variables: {
                          classId: classData.id,
                          willSave: !classData.isSaved,
                          programClassRefId: classRefId,
                        },
                        classData,
                      });
                    }}
                  />
                  <DropdownMenuOptions classData={classData} />
                </Flex>
              )}
            </Flex>

            {hasCommunityAccess && (
              <MoreContentSection>
                <SectionTitle>
                  <Flex gap="16px">
                    Connect and Upload
                    <HeaderLink to={`/class/${classData.id}/posts`}>
                      See all
                    </HeaderLink>
                  </Flex>
                </SectionTitle>
                <UserVideos
                  classNumber={Number(classData.id)}
                  fromModule={ANALYTICS_MODULE.class_details}
                />
              </MoreContentSection>
            )}

            {classTracks && classTracks.length > 0 && (
              <TracksWrapper>
                <TrackInfoList tracks={classTracks} />
              </TracksWrapper>
            )}

            {/* z-index divs are for the class cards with drop downs to render properly above the next div */}
            <MoreContentSection>
              <SectionTitle>Similar Classes</SectionTitle>
              <SimilarClasses classData={classData} />
            </MoreContentSection>

            {classData.instructor && (
              <ClassesByInstructor
                instructorName={classData.instructor?.name}
              />
            )}

            {classTracks && classTracks.length > 0 && (
              <TracksWrapper>
                <TrackCopyrights tracks={classTracks} />
              </TracksWrapper>
            )}
          </BrowserContainer>
        </BrowserWrapper>
      </Wrapper>
      <ShareModal
        onCancel={() => setIsShareModalShowing(false)}
        isOpen={isShareModalShowing}
        classNumber={+classData.id}
        classThumbnailUrl={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
          classData.thumbnail
        }-basic.jpg?w=600&auto=format}`}
        shareMessage={`Check out this STEEZY Studio video from ${classData.instructor?.name}`}
      />
    </>
  );
}
