import React from "react";
import Flex from "app/components/Flex";
import Thumbnail from "app/components/Thumbnail";
import { H3 } from "app/components/Typography";
import { AlgoliaClassData } from "app/components/Algolia/types";
import env from "helpers/env";
import { formatDuration } from "helpers/formatDuration";
import { useGetUserClassProgressPercent } from "modules/selectors";
import {
  CompletedIcon,
  ProgressBar,
  ThumbnailContainer,
  ThumbnailWrapper,
  ClassInfoText,
  LockedLabel,
} from "../styles";
import { ClassInfoLabel } from "../ClassInfoLabel";
import { ExplicitBadge } from "app/components/ClassCard/Card/components";
import { AccessType } from "services/graphql";
import { useCanUserTakeClass } from "hooks/Classes";

interface Props {
  classHit: Partial<AlgoliaClassData> & { isAddedToPlaylist: boolean };
}

export function AlgoliaClassRow({ classHit }: Props) {
  const {
    classProgressPercent,
    isClassCompleted,
  } = useGetUserClassProgressPercent({
    classId: classHit.id,
    classDurationInSeconds: classHit.duration_in_seconds,
  });

  const { canUserTakeClass } = useCanUserTakeClass({
    classId: classHit.id,
  });

  return (
    <Flex gap="16px" flexWrap="wrap">
      <Flex width="200px">
        <ThumbnailContainer>
          <ThumbnailWrapper>
            <Thumbnail
              src={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                classHit.thumbnail
              }-basic.jpg?w=600&auto=format}`}
              placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                classHit.thumbnail
              }-basic.jpg?blur=200&px=16&auto=format`}
            />
            <Flex
              position="absolute"
              top="12px"
              left="12px"
              alignItems="center"
              gap="5px"
            >
              {!canUserTakeClass && classHit.accessType !== AccessType.Free && (
                <LockedLabel />
              )}
              {classHit.explicit && <ExplicitBadge />}
            </Flex>
            {isClassCompleted && <CompletedIcon />}
            {classProgressPercent > 0 && (
              <ProgressBar percent={classProgressPercent} />
            )}
          </ThumbnailWrapper>
        </ThumbnailContainer>
      </Flex>
      <Flex flexDirection="column" flex="1">
        <H3 mb="12px" limitLines={2}>
          {classHit.title}
        </H3>
        <Flex gap="8px">
          <ClassInfoLabel label={classHit.level} />
          <ClassInfoLabel label={formatDuration(classHit.duration)} />
          <ClassInfoLabel label={classHit.type} />
        </Flex>
        <Flex gap="8px" alignItems="center">
          <ClassInfoText>{classHit.style}</ClassInfoText>
          <div>{" • "}</div>
          <ClassInfoText>{classHit.instructor_name}</ClassInfoText>
        </Flex>
      </Flex>
    </Flex>
  );
}
