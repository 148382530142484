import React, { useState } from "react";
import Flex from "app/components/Flex";
import Text from "app/components/Text";
import { Track } from "services/graphql";
import { BaseText } from "./styles";

interface Props {
  tracks: Track[];
}

export const TrackCopyrights = ({ tracks }: Props) => {
  const [viewAll, setViewAll] = useState(false);

  const toggleViewAll = () => setViewAll(previousValue => !previousValue);

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center">
        <Text
          color="monochrome.3"
          fontWeight="700"
          fontSize="16px"
          fontHeight="110%"
        >
          Music Copyrights
        </Text>
        <Text
          ml="12px"
          color="blue"
          fontWeight="700"
          fontSize="11px"
          fontHeight="150%"
          letterSpacing="0.05em"
          cursor="pointer"
          onClick={toggleViewAll}
        >
          {viewAll ? "Show less" : "View all"}
        </Text>
      </Flex>
      <Flex
        flexWrap="wrap"
        flexDirection="column"
        display={viewAll ? "flex" : "none"}
        mb="15px"
      >
        {tracks.map((track, index) => (
          <Flex flexDirection="column" mt="24px" maxWidth="658px">
            <BaseText>
              {index + 1}. {track?.title} - {track?.artists.join(", ")}
            </BaseText>
            {track?.copyright && (
              <Flex>
                <BaseText margin="0 7px">&bull;</BaseText>
                <BaseText>{track?.copyright}</BaseText>
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
