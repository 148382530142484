import React from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { H3, P1 } from "app/components/Typography";
import { useVideoUploadDelete } from "hooks/Challenges";
import { IS_MOBILE } from "constants/index";
import { DeletePostModalContainer, DeleteButtonOption } from "./styles";

const DeletePostModal = ({ videoId, isOpen, onToggleDeleteModal }) => {
  const history = useHistory();
  const [videoUploadDelete] = useVideoUploadDelete({
    onCompleted: () => {
      // TODO: Fire analytics event?

      const historyState = history?.location?.state;
      if (historyState?.previousLocation) {
        history.replace(historyState.previousLocation.pathname);
      } else {
        history.replace("/profile");
      }

      toast.success("Post deleted.");
    },
  });

  const handleDelete = () => {
    videoUploadDelete({
      variables: {
        videoId,
      },
    });
  };

  return (
    <DeletePostModalContainer
      isOpen={isOpen}
      onBackgroundClick={onToggleDeleteModal}
      onEscapeKeydown={onToggleDeleteModal}
    >
      <H3>Delete Post?</H3>
      <P1 margin="15px 12px 40px 12px">
        {IS_MOBILE ? "Tapping" : "Clicking"} “Delete Post” will permanently
        delete your video. It can’t be restored later!
      </P1>
      <DeleteButtonOption color="redMercedes.3" onClick={handleDelete}>
        DELETE POST
      </DeleteButtonOption>
      <DeleteButtonOption fontWeight="normal" onClick={onToggleDeleteModal}>
        CANCEL
      </DeleteButtonOption>
    </DeletePostModalContainer>
  );
};

DeletePostModal.propTypes = {
  videoId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggleDeleteModal: PropTypes.func.isRequired,
};

export default DeletePostModal;
