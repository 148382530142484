import CommentsList from "app/components/Comments/CommentsList";
import CreateComment from "app/components/Comments/CreateComment";
import Flex from "app/components/Flex";
import { LineSeparator } from "app/components/SideNavbar/lines";
import { ANALYTICS_LOCATION } from "constants/index";
import React, { useCallback, useRef, useState } from "react";
import {
  CommunityContentFlagContentType,
  Type,
  communityContentFlag,
  communityVideoActivity,
} from "services/typewriter/segment";
import { ActivityComment, ActivityType } from "services/graphql";
import { P1 } from "app/components/Typography";
import styled from "styled-components";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { ReportOverlay } from "../ReportOverlay";
import { useGetScreenSizes } from "../hooks";
import { UserVideoPlayer } from "../UserVideoPlayer";
import { ClassPreviewModal } from "../ClassPreviewModal";

const UsernameLink = styled(Link)`
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 8px;
  overflow-wrap: break-word;

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const OverflowText = styled(P1)`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  padding: 0 5px;
  overflow-wrap: break-word;
`;

interface Props {
  userVideoData: any;
  fromModule?: string;
  playOnLoad?: boolean;
}

export function UserVideoPost({
  userVideoData,
  fromModule,
  playOnLoad,
}: Props) {
  const location = useLocation();
  const [classPreviewNumber, setClassPreviewNumber] = useState(null);
  const createCommentInputRef = useRef(null);
  const [replyActivityComment, setReplyActivityComment] = useState<
    ActivityComment
  >(null);

  const { height } = useGetScreenSizes();
  const currentScreenHeight = height;
  const containerHeight = { _: currentScreenHeight, lg: "900px" };
  const containerWidth = { _: "100%", lg: "463px" };

  const resetReplyActivity = () => {
    setReplyActivityComment(null);
  };

  const handleReplyComments = useCallback((comment: ActivityComment) => {
    setReplyActivityComment(comment);
    createCommentInputRef.current.focus();
  }, []);

  const { uploadedBy, id: userVideoId, studioClass } = userVideoData;
  const isCurrentlyOnClassPage =
    location.pathname === `/class/${studioClass?.classId}`;
  const canLearn = !!studioClass && !isCurrentlyOnClassPage;

  const onCreateComment = () => {
    if (replyActivityComment) {
      communityVideoActivity({
        video_id: userVideoId,
        to_user_id: replyActivityComment?.commentBy?.uid,
        type: Type.Reply,
        comment_id: replyActivityComment?.id,
      });
    } else {
      communityVideoActivity({
        video_id: userVideoId,
        to_user_id: uploadedBy?.uid,
        type: Type.Comment,
      });
    }

    resetReplyActivity();
  };

  const onReportComment = (reportReason: any) => {
    communityContentFlag({
      element_name: "Community Feed - Report Comment",
      location: ANALYTICS_LOCATION.community,
      module: fromModule,
      content_id: userVideoId,
      content_user_id: uploadedBy?.uid,
      content_type: CommunityContentFlagContentType.UserVideo,
      reason: reportReason,
    });
  };

  const onClickLearn = () => {
    setClassPreviewNumber(studioClass?.classId);
  };

  return (
    <>
      <Flex background="transparent" width="100%" position="relative" mt={0}>
        <Flex
          width="100%"
          flexDirection={{ _: "column", lg: "row" }}
          justifyContent="center"
          alignItems="start"
          pb={{ _: 100, lg: 0 }}
        >
          <Flex width={{ _: "100%", lg: "auto" }} position="relative">
            <Flex
              position="relative"
              flexDirection="column"
              alignItems="center"
              height={containerHeight}
              width={containerWidth}
              maxHeight={containerHeight}
              maxWidth={containerWidth}
              minHeight={containerHeight}
              minWidth={containerWidth}
              overflowY="hidden"
              overflowX="hidden"
              borderRadius={{ _: "20px 20px 0 0", lg: "20px 0 0 20px" }}
            >
              {userVideoData.reportedByMe && (
                <ReportOverlay
                  style={{ zIndex: 1 }}
                  iconWidth="72px"
                  iconHeight="72px"
                >
                  <P1 color="white" mt={3}>
                    Post Reported
                  </P1>
                </ReportOverlay>
              )}
              <UserVideoPlayer
                userVideoData={userVideoData}
                fromModule={fromModule}
                canLearn={canLearn}
                onClickLearn={onClickLearn}
                playOnLoad={playOnLoad}
                focusCommentInput={() => createCommentInputRef.current.focus()}
              />
            </Flex>
          </Flex>
          {/* fix this following maxHeight when modal sizing is fixed */}
          <Flex
            flexDirection="column"
            width={{ _: "100%", lg: "400px" }}
            maxHeight={{ _: currentScreenHeight, lg: "900px" }}
            height="100%"
            borderRadius={{ _: "0 0 20px 20px", lg: "0 20px 20px 0" }}
            bg="white"
          >
            <Flex flexDirection="column" p="16px">
              <UsernameLink to={`/profile/${uploadedBy?.username}`}>
                @{uploadedBy.username}
              </UsernameLink>
              {userVideoData.description && (
                <OverflowText>{userVideoData.description}</OverflowText>
              )}
            </Flex>
            <LineSeparator />
            <Flex flexDirection="column" flex="1" overflowY="scroll">
              <CommentsList
                videoId={userVideoId}
                activityId={userVideoId}
                activityType={ActivityType.UserVideo}
                onReportComment={onReportComment}
                isFirstTierComment
                handleReplyComments={handleReplyComments}
              />
            </Flex>
            <CreateComment
              activityId={userVideoId}
              activityType={ActivityType.UserVideo}
              onCreateComment={onCreateComment}
              inputref={createCommentInputRef}
              replyActivityComment={replyActivityComment}
              resetReplyActivity={resetReplyActivity}
            />
          </Flex>
        </Flex>
      </Flex>
      {classPreviewNumber && (
        <ClassPreviewModal
          classNumber={classPreviewNumber}
          closeModal={() => setClassPreviewNumber(null)}
        />
      )}
    </>
  );
}
