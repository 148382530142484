import React from "react";
import { DropdownMenu } from "app/components/Dropdown";
import { MenuButton } from "./MenuButton";

interface Props {
  children: React.ReactNode;
  menuButton?: any;
  portal?: boolean;
}

export const DropdownMenuWrapper = ({
  children,
  portal = true,
  menuButton,
}: Props) => {
  return (
    <DropdownMenu
      menuButton={menuButton ?? <MenuButton />}
      align="end"
      direction="bottom"
      portal={portal}
    >
      {children}
    </DropdownMenu>
  );
};
