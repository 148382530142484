import React from "react";
import Icon, { Heart, HeartFilled } from "app/components/Icon";
import Tooltip from "app/components/Tooltip";
import { StyledButton } from "./styles";

interface Props {
  isSaved: boolean;
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export function SaveIconWithTextButton({ isSaved = false, onClick }: Props) {
  return (
    <Tooltip overlay={isSaved ? "Saved" : "Save"}>
      <StyledButton
        isActive={isSaved}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          // Stop propagation since we handle clicks on Wrapper
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();

          onClick(e);
        }}
      >
        <Icon as={isSaved ? HeartFilled : Heart} width="20px" height="20px" />
        {isSaved ? "Saved" : "Save"}
      </StyledButton>
    </Tooltip>
  );
}
