import Flex from "app/components/Flex";
import Text from "app/components/Text";
import styled from "styled-components";
import DefaultModal from "app/components/Modal";
import { IS_MOBILE } from "constants/index";

const DesktopModal = styled(DefaultModal)`
  width: 100%;
  max-width: 600px;
  padding: 16px;
  border-radius: 24px;
`;

const MobileModal = styled(DefaultModal)`
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  margin: 0;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
`;

export const Modal = IS_MOBILE ? MobileModal : DesktopModal;

export const Img = styled.img`
  height: 100%;
  border-radius: 16px;
  position: absolute;
  top: 0;
`;

export const Icon = styled.div`
  width: 36px;
  height: 36px;
`;

export const SocialWrapper = styled(Flex).attrs({
  alignItems: "center",
  cursor: "pointer",
})``;

export const SocialText = styled(Text).attrs({
  fontSize: "16px",
  fontWeight: "500",
  marginLeft: "16px",
})``;
