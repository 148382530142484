import Button from "app/components/Button";
import styled from "styled-components";

export const CtaButton = styled(Button)`
  height: 45px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  gap: 8px;
  width: auto;
  padding: 0 30px;
`;
