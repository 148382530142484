import React from "react";
import { Heart, HeartFilled } from "app/components/Icon";
import { DropdownOption } from "app/components/Dropdown";
import { Class } from "services/graphql";
import useToggleSaveClass from "hooks/Classes/useToggleSaveClass";
import { useIsClassSaved } from "modules/selectors/content";
import { useGetUserIsAnonymous } from "modules/selectors";

interface Props {
  classData: Partial<Class>;
}

export const SaveClassOption = ({ classData }: Props) => {
  const isAnonymous = useGetUserIsAnonymous();
  const toggleSaveClassMutation = useToggleSaveClass();
  const isSaved = useIsClassSaved({ classNumber: Number(classData.id) });

  if (isAnonymous) {
    return null;
  }

  return (
    <>
      <DropdownOption
        Svg={isSaved ? HeartFilled : Heart}
        onClick={() => {
          toggleSaveClassMutation({
            component: "DropdownMenuOptions",
            variables: {
              classId: classData.id,
              willSave: !isSaved,
            },
            classData,
          });
        }}
        text={isSaved ? "Class Saved" : "Save Class"}
      />
    </>
  );
};
