import Flex from "app/components/Flex";
import { TrackInformation } from "app/components/TrackInformation";
import React from "react";
import { Track } from "services/graphql";
import { SectionTitle } from "../shared-styles";

interface Props {
  tracks: Track[];
}

export const TrackInfoList = ({ tracks }: Props) => {
  return (
    <Flex flexDirection="column">
      <SectionTitle>Music</SectionTitle>
      <Flex flexDirection="column" gap="16px">
        {tracks.map(
          ({ id, image, artists, title, albumName, label, isExplicit }) => {
            const artistName = artists.join(", ");

            if (!artistName || !title || !label) {
              return null;
            }

            return (
              <TrackInformation
                key={`${id}-track-information`}
                cover={image}
                artistName={artistName}
                songName={title}
                albumName={albumName}
                recordLabelName={label}
                isExplicit={isExplicit}
              />
            );
          }
        )}
      </Flex>
    </Flex>
  );
};
