import React, { useState, memo } from "react";
import styled from "styled-components";
import { Portal } from "react-portal";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Flex from "app/components/Flex";
import { KebabMenu } from "app/components/Icon";
import { P1 } from "app/components/Typography";
import ReportModal from "app/components/ReportModal";
import { useRedirectNoAccess } from "hooks/Challenges";
import env from "helpers/env";
import { BaseButton } from "../BaseButton";
import DeletePostModal from "./DeletePostModal";

const MenuWrapper = styled(Flex)`
  position: absolute;
  z-index: 1;
  bottom: 90px;
  right: 50px;
  width: 180px;
  background-color: white;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.monochrome[2]};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  overflow: hidden;
`;

const MenuList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;

  > li:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.monochrome[2]};
  }
`;

const MenuListItem = styled.li`
  padding: ${({ theme }) => theme.space[3]}px;

  :hover {
    background-color: ${({ theme }) => theme.colors.monochrome[1]};
  }
`;

let timeout: any = null;

interface Props {
  videoId: string;
  isAuthoredByMe: boolean;
  onClickReport: (reason: string) => void;
}

export const Settings = memo(
  ({ videoId, isAuthoredByMe, onClickReport }: Props) => {
    const shareButtonCopy = "Share post";
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const redirectNoAccess = useRedirectNoAccess();

    const toggleMenuOpen = () => setIsMenuOpen(previousState => !previousState);
    const onToggleDeleteModal = () =>
      setIsDeleteModalOpen(previousState => !previousState);

    const onDeletePost = (e: any) => {
      e.stopPropagation();
      setIsDeleteModalOpen(true);
      setIsMenuOpen(false);
    };

    const onReportPost = (e: any) => {
      e.stopPropagation();

      if (redirectNoAccess()) {
        return;
      }

      setIsReportModalOpen(true);
      setIsMenuOpen(false);
    };

    const onSharePost = () => {
      setIsMenuOpen(false);
      toast.success("Link copied!");
    };

    return (
      <Flex
        onMouseEnter={() => {
          clearTimeout(timeout);
        }}
        onMouseLeave={() => {
          timeout = setTimeout(() => {
            setIsMenuOpen(false);
          }, 500);
        }}
        id={`video-portal-${videoId}`}
      >
        <BaseButton
          Icon={KebabMenu}
          onClick={e => {
            e.stopPropagation();
            toggleMenuOpen();
          }}
        />
        {isMenuOpen && (
          <Portal node={document.getElementById(`video-portal-${videoId}`)}>
            <MenuWrapper>
              <MenuList>
                <CopyToClipboard
                  text={`${env("PUBLIC_DOMAIN")}/post/${videoId}`}
                  onCopy={onSharePost}
                >
                  <MenuListItem>
                    <P1>{shareButtonCopy}</P1>
                  </MenuListItem>
                </CopyToClipboard>
                {isAuthoredByMe ? (
                  <MenuListItem onClick={onDeletePost}>
                    <P1 color="redMercedes.3">Delete post</P1>
                  </MenuListItem>
                ) : (
                  <MenuListItem onClick={onReportPost}>
                    <P1 color="redMercedes.3">Report post</P1>
                  </MenuListItem>
                )}
              </MenuList>
            </MenuWrapper>
          </Portal>
        )}
        <DeletePostModal
          videoId={videoId}
          isOpen={isDeleteModalOpen}
          onToggleDeleteModal={onToggleDeleteModal}
        />
        <ReportModal
          activityType="user_video"
          activityId={videoId}
          isOpen={isReportModalOpen}
          setIsOpen={setIsReportModalOpen}
          onClickReport={onClickReport}
        />
      </Flex>
    );
  }
);
