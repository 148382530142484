import { getAnalyticsLocationFromPath } from "helpers/analytics";
import {
  useClassSelectContentEvent,
  useContentSelectClassEvent,
} from "hooks/analytics";
import { enterContinuity } from "modules/continuity";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import { isNil, omitBy } from "lodash";
import {
  LibrarySelectContentContainer,
  HomeSelectContentContainer,
} from "services/typewriter/segment";
import { useGetUserIsAnonymous } from "modules/selectors";
import convertTimeObjectToSeconds from "helpers/convertTimeObjectToSeconds";

const resolveQueryParams = ({
  classData,
  queryParams,
}: {
  classData: any;
  queryParams: any;
}) => {
  const queryParamResult = {
    ...queryParams,
  };

  if (classData.refId) {
    queryParamResult.classRefId = classData.refId;
  } else if (classData.programs) {
    // TODO: VERIFY CLASS + PROGRAMS 1-to-many or 1-to-1 relationship?
    // eslint-disable-next-line prefer-destructuring
    queryParamResult.program = classData.programs[0];
  }

  if (classData.playlistId) {
    queryParamResult.playlist = classData.playlistId;
  }

  return queryString.stringify(omitBy(queryParamResult, isNil));
};

export const useSelectClass = ({
  container,
  selectedFrom,
  classData,
  element,
}: {
  container?: HomeSelectContentContainer & LibrarySelectContentContainer;
  selectedFrom: string;
  classData: any;
  element: string;
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { contentSelectClassEvent } = useContentSelectClassEvent({ classData });
  const { selectContentEvent } = useClassSelectContentEvent({ classData });
  const isAnonymous = useGetUserIsAnonymous();
  const queryParams = queryString.parse(location.search);

  const continuityState = useSelector(
    ({ continuity }: { continuity: any }) => continuity
  );

  function selectClass() {
    if (isAnonymous && !classData?.isFree) {
      history.push("/register");
      return;
    }

    const stringifiedParams = resolveQueryParams({
      classData,
      queryParams,
    });
    const routeToPush = `/class/${classData.id}${
      stringifiedParams ? `?${stringifiedParams}` : ""
    }`;
    contentSelectClassEvent({
      location: getAnalyticsLocationFromPath(location.pathname),
      path: location.pathname,
      selected_from: selectedFrom,
      element,
    });
    selectContentEvent({ container });
    if (!continuityState.entryRoute) {
      dispatch(enterContinuity({ entryRoute: location.pathname }));
    }
    history.push(routeToPush, {
      refComponent: selectedFrom,
      refElement: element,
      fromApp: true,
    });
  }

  return { selectClass };
};

export function useClassProgress({ classData }: any) {
  const user = useSelector(
    ({ user: _user }: { user: { progress: any } }) => _user
  );
  let progressTimeInSeconds = 0;

  // progress object can be nested in data if received from graphQL
  // if not, check for progress in firebase
  // we are using IN operator because progress can be null, but key can exist
  const isFromGraphQL = "progress" in (classData || {});

  const progress =
    (classData &&
      (isFromGraphQL
        ? classData.progress
        : user.progress["class-progress"] &&
          user.progress["class-progress"][classData.id])) ||
    {};
  if (progress && progress.time) {
    progressTimeInSeconds = convertTimeObjectToSeconds(progress.time);
  }
  const progressPercent =
    (progressTimeInSeconds / classData?.duration_in_seconds) * 100;

  return {
    progressPercent,
    isProgressCompleted: Boolean(progress?.completed),
  };
}
