import React from "react";
import styled from "styled-components";
import Loader from "./index";

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default () => (
  <LoaderOverlay>
    <Loader />
  </LoaderOverlay>
);
