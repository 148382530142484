import Button from "app/components/Button";
import HeartIconButton from "app/components/Button/IconButton/HeartIconButton";
import VolumeIconButton from "app/components/Button/IconButton/VolumeIconButton";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import Icon, {
  CheckSquareV2,
  Lock,
  LockedIndicatorV2,
} from "app/components/Icon";
import ResponsiveVideoPlayer from "app/components/ResponsiveVideoPlayer";
import Text from "app/components/Text";
import Thumbnail from "app/components/Thumbnail";
import { IS_MOBILE } from "constants/index";
import { formatDuration } from "helpers/formatDuration";
import useOnClickOutside from "hooks/useOnClickOutside";
import useOnScreen from "hooks/useOnScreen";
import { toggleSettingsVolumeAction } from "modules/components";
import {
  userPreviewingContentAction,
  userStartPreviewingContentAction,
  userStopPreviewingContentAction,
} from "modules/content";
import { useComponentsSettingsVolume } from "modules/selectors/components";
import moment from "moment-timezone";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import { useDispatch } from "react-redux";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import queryString from "query-string";
import { ModalContext } from "app/components/Modal/ModalWithContext";
import { useUserAccessType } from "hooks/Subscriptions/useUserAccessType";
import { AccessType } from "services/graphql";
import { ExplicitBadge } from "./components";
import {
  CardInfoContainer,
  CARD_PREVIEW_PADDING_RIGHT,
  FadeOutThumbnail,
  LockOverlay,
  StyledProgressBar,
  ThumbnailContainer,
  Wrapper,
  Label,
  LabelText,
  OverlayShadow,
  ThumbnailWrapper,
  Title,
  VideoDetails,
  ClassTypeLabel,
  ExclusiveUpgradeSpan,
  FreeClassSpan,
} from "./styles";
import { DropdownMenuOptions } from "./DropdownMenuOptions";

export { Wrapper, ThumbnailContainer, CardInfoContainer };

interface Props {
  contentData: any;
  goToContent(): void;
  isProgressCompleted?: boolean;
  isSaved?: boolean;
  isSubscribeModalShowing: boolean;
  progressPercent?: number;
  onClick?(_e: any): void;
  onMouseEnter?(): void;
  saveUserContent(): void;
  selectedFrom: string;
  togglePreviewModal(): void;
  thumbnailPlaceholderSrc: string;
  thumbnailSrc: string;
  disableClick?: boolean;
  cardHeight: string;
}

let mouseEnterTimeout: any = null;

export const Card = ({
  contentData,
  goToContent,
  isProgressCompleted = false,
  isSaved = false,
  isSubscribeModalShowing,
  progressPercent = null,
  onClick,
  onMouseEnter,
  saveUserContent,
  selectedFrom,
  togglePreviewModal,
  thumbnailPlaceholderSrc,
  thumbnailSrc,
  disableClick,
  cardHeight = "220px",
}: Props) => {
  const modalContext = useContext(ModalContext);
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLAnchorElement>();
  const containerRef = useRef();

  const [isMoreDetailsShowing, toggleMoreDetailsShowing] = useState(false);
  const [isPreviewVideoShowing, togglePreviewVideoShowing] = useState(false);
  const [cardWidth, setCardWidth] = useState(0);
  const publishDate = contentData.publish_date || contentData.publishDate;
  const published = moment().isAfter(publishDate) || !publishDate;
  const previewVideoUrl =
    contentData.preview_url || contentData?.content?.assets?.previewVideoURL;
  const instructorName =
    contentData.instructor_name || contentData.instructor?.name;

  const { isFamilyModeOn } = useFamilyModeSetting();
  const settingsVolume = useComponentsSettingsVolume();
  const onScreen = useOnScreen(wrapperRef, "-100px");

  const compileQueryParams = useCallback(() => {
    const queryParams = {} as { playlist: string; classRefId: string };

    if (contentData?.playlistId) {
      queryParams.playlist = contentData.playlistId;
    }

    if (contentData?.refId) {
      queryParams.classRefId = contentData.refId;
    }

    return queryString.stringifyUrl({
      url: `/class/${contentData.id}`,
      query: queryParams,
    });
  }, [contentData?.playlistId, contentData?.refId]);

  useOnClickOutside(wrapperRef, () => {
    if (IS_MOBILE && isMoreDetailsShowing) {
      toggleMoreDetails(false);
    }
  });

  const handleClick = (e: any) => {
    e.preventDefault(); // To ignore link tag href
    if (disableClick) {
      return;
    }
    if (onClick) {
      onClick(e);
    }

    if (modalContext.isRenderedInModal) {
      goToContent();
    }

    if (IS_MOBILE) {
      goToContent();
    } else {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      togglePreviewModal();
    }
  };

  // eslint-disable-next-line consistent-return
  const handleMouseEnter = () => {
    if (onMouseEnter) {
      return onMouseEnter();
    }
    if (IS_MOBILE || !published) {
      // eslint-disable-next-line consistent-return
      return;
    }
    if (!isMoreDetailsShowing) {
      mouseEnterTimeout = setTimeout(() => {
        toggleMoreDetails(true);
      }, 100);
    }
  };

  const handleMouseLeave = () => {
    if (IS_MOBILE || !published) {
      return;
    }

    toggleMoreDetails(false);
    clearTimeout(mouseEnterTimeout);
  };

  const toggleMoreDetails = useCallback(
    (isToggled: any) => {
      toggleMoreDetailsShowing(isToggled);
      togglePreviewVideoShowing(isToggled);
      if (isPreviewVideoShowing) {
        dispatch(
          userStartPreviewingContentAction({
            contentData,
            component: selectedFrom,
          })
        );
      }
      if (!isPreviewVideoShowing) {
        dispatch(userStopPreviewingContentAction());
      }
    },
    [contentData, dispatch, isPreviewVideoShowing, selectedFrom]
  );

  const wrapperWidth = wrapperRef?.current?.offsetWidth;
  useEffect(() => {
    if (wrapperWidth) {
      setCardWidth(wrapperWidth);
    }
  }, [wrapperWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (isMoreDetailsShowing && !IS_MOBILE) {
        toggleMoreDetails(false);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, [isMoreDetailsShowing, toggleMoreDetails]);

  useEffect(
    function onAppearOnScreen() {
      if (!onScreen && isPreviewVideoShowing) {
        toggleMoreDetails(false);
      }
    },
    [isPreviewVideoShowing, onScreen, toggleMoreDetails]
  );

  const playerVolume = contentData.isPreviewVideoMuted ? 0 : settingsVolume;
  const PreviewUrlComponent = () =>
    contentData.externalPreviewUrl ? (
      <>
        Volume disabled due to copyright. To preview with music,{" "}
        <a
          href={contentData.externalPreviewUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          please click here.
        </a>
      </>
    ) : (
      <>Volume disabled due to copyright.</>
    );

  const isRenderedOnDarkBackground = selectedFrom === "ForYou";
  const textColor = isRenderedOnDarkBackground
    ? "monochrome.0"
    : "monochrome.8";

  return (
    <Flex flexDirection="column" width="100%">
      <Wrapper
        href={compileQueryParams()} // used to allow for Open in New Tab functionality
        ref={wrapperRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        height={cardHeight}
      >
        <ThumbnailContainer ref={containerRef}>
          <ThumbnailWrapper>
            {isPreviewVideoShowing && (
              <Div
                position="absolute"
                height="100%"
                top={0}
                right={0}
                // To be honest I don't why this works
                // Something something math
                pr={CARD_PREVIEW_PADDING_RIGHT}
              >
                <Div position="absolute" width="100%">
                  {previewVideoUrl && (
                    <ResponsiveVideoPlayer
                      position="absolute"
                      playing
                      playsinline
                      controls={false}
                      volume={playerVolume}
                      muted={!playerVolume}
                      url={previewVideoUrl}
                      onProgress={(videoProgress: any) => {
                        dispatch(
                          userPreviewingContentAction({
                            previewDuration: Math.floor(
                              videoProgress.playedSeconds
                            ),
                          })
                        );
                      }}
                      loop
                      showLoader={false}
                      height="100%"
                      width="auto"
                    />
                  )}
                </Div>
              </Div>
            )}
            <FadeOutThumbnail
              unmountOnExit
              timeout={500}
              in={!isPreviewVideoShowing}
            >
              <Thumbnail
                width="auto"
                height="125%"
                placeholderSrc={thumbnailPlaceholderSrc}
                src={thumbnailSrc}
              />
            </FadeOutThumbnail>
            <OverlayShadow height={isProgressCompleted ? "75%" : "50%"} />
            <VideoDetails isPreviewVideoShowing={isPreviewVideoShowing}>
              <Flex
                flexDirection="row"
                width="100%"
                height={isMoreDetailsShowing ? "100%" : "50%"}
              >
                <Flex width="50%">
                  {/* We want anonymous users to see free classes as unlocked, they will be hit with a sign up modal on class landing */}
                  {!contentData.canUserTakeClass && !contentData.isFree && (
                    <Div
                      backgroundColor="monochrome.10"
                      borderRadius="2px"
                      color="white"
                      width="18px"
                      height="18px"
                      mr="5px"
                    >
                      <Icon color="white" as={LockedIndicatorV2} />
                    </Div>
                  )}
                  {(contentData?.isExplicit ?? contentData?.explicit) && (
                    <ExplicitBadge />
                  )}
                </Flex>
                {!isMoreDetailsShowing && isProgressCompleted && (
                  <Flex
                    height="100%"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    width="50%"
                  >
                    <Div borderRadius="2px">
                      <Icon
                        color="#81F1AE"
                        as={CheckSquareV2}
                        width="18px"
                        height="18px"
                      />
                    </Div>
                  </Flex>
                )}
                {isMoreDetailsShowing && (
                  <Flex
                    flexDirection="column"
                    height="100%"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    width="50%"
                    pt="12px"
                    pb="8px"
                  >
                    {!isFamilyModeOn && (
                      <HeartIconButton
                        height={
                          cardWidth <= 320
                            ? "16px"
                            : { sm: "24px", md: "20px", lg: "24px" }
                        }
                        width={
                          cardWidth <= 320
                            ? "16px"
                            : { sm: "24px", md: "20px", lg: "24px" }
                        }
                        isActive={isSaved}
                        onClick={(e: any) => {
                          e.preventDefault();
                          saveUserContent();
                        }}
                      />
                    )}
                    <VolumeIconButton
                      tooltipOverlay={
                        contentData.isPreviewVideoMuted ? (
                          <PreviewUrlComponent />
                        ) : null
                      }
                      height={
                        cardWidth <= 320
                          ? "16px"
                          : { _: "24px", md: "20px", lg: "24px" }
                      }
                      width={
                        cardWidth <= 320
                          ? "16px"
                          : { _: "24px", md: "20px", lg: "24px" }
                      }
                      isActive={Boolean(playerVolume)}
                      onClick={(e: any) => {
                        e.preventDefault();
                        if (contentData.isPreviewVideoMuted) {
                          return;
                        }
                        dispatch(
                          toggleSettingsVolumeAction(playerVolume ? 0 : 1)
                        );
                      }}
                    />

                    <Button
                      cursor={isSubscribeModalShowing && "not-allowed"}
                      height="19px"
                      width="48px"
                      p="12px 30px"
                      onClick={e => {
                        e.preventDefault();
                        goToContent();
                      }}
                    >
                      <Flex>
                        <Text fontSize="12px">Play</Text>
                      </Flex>
                    </Button>
                  </Flex>
                )}
              </Flex>
              {!isMoreDetailsShowing && (
                <Title mb="10px">{contentData.title}</Title>
              )}
            </VideoDetails>
            <StyledProgressBar
              percent={progressPercent}
              hide={!progressPercent}
            />
            {!published && (
              <LockOverlay>
                <Icon width="20px" as={Lock} />
                <p>
                  Available&nbsp;
                  {moment(publishDate)
                    .tz("America/Los_Angeles")
                    .format("MMM D ha z")}
                </p>
              </LockOverlay>
            )}
          </ThumbnailWrapper>
        </ThumbnailContainer>
      </Wrapper>
      <CardInfoContainer>
        <Flex justifyContent="space-between" gap="4px">
          <Flex flexDirection="column">
            <Flex justifyContent="flex-start" width="100%" flexWrap="wrap">
              <ClassTypeLabel
                variant={contentData.level}
                mr={cardWidth <= 290 ? "5px" : "8px"}
                padding={cardWidth <= 290 ? "3px 3px 2px" : "3px 5px 2px"}
                mb="2px"
              >
                <LabelText>{contentData.level}</LabelText>
              </ClassTypeLabel>
              {contentData.duration !== undefined && contentData.duration && (
                <Label
                  mr={cardWidth <= 290 ? "5px" : "8px"}
                  padding={cardWidth <= 290 ? "3px 3px 2px" : "3px 5px 2px"}
                >
                  <LabelText>{formatDuration(contentData.duration)}</LabelText>
                </Label>
              )}
              {contentData.type !== undefined && (
                <Label
                  padding={cardWidth <= 290 ? "3px 3px 2px" : "3px 5px 2px"}
                >
                  <LabelText>{contentData.type}</LabelText>
                </Label>
              )}
            </Flex>
            <Flex justifyContent="flex-start" mt="3px">
              {contentData.style !== undefined && (
                <>
                  {instructorName ? (
                    <Text fontSize="12px" limitLines="2" color={textColor}>
                      {contentData.style}
                      &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                      {instructorName}
                      <AccessTypeLabel
                        contentAccessType={contentData.accessType}
                      />
                    </Text>
                  ) : (
                    <Text fontSize="12px" color={textColor}>
                      {contentData.style}
                    </Text>
                  )}
                </>
              )}
            </Flex>
          </Flex>
          <Flex alignItems="center">
            <DropdownMenuOptions
              classData={contentData}
              onUserSelectsClass={goToContent}
            />
          </Flex>
        </Flex>
      </CardInfoContainer>
    </Flex>
  );
};

function AccessTypeLabel({
  contentAccessType,
}: {
  contentAccessType: AccessType;
}) {
  const userAccessType = useUserAccessType();
  if (
    userAccessType === AccessType.Free &&
    contentAccessType === AccessType.Free
  ) {
    return (
      <FreeClassSpan>&nbsp;&nbsp;&bull;&nbsp;&nbsp;Free Class</FreeClassSpan>
    );
  }

  if (
    userAccessType === AccessType.Basic &&
    [AccessType.Standard, AccessType.Premium].includes(contentAccessType)
  ) {
    return (
      <ExclusiveUpgradeSpan>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;Exclusive Upgrade
      </ExclusiveUpgradeSpan>
    );
  }

  return null;
}
