import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "app/components/Button";
import IconButton from "app/components/Button/IconButton";
import Icon, { Close, Camera, ChevronLeft } from "app/components/Icon";
import { useVideoUploadMutations } from "hooks/Challenges";
import { usePrimarySubscription } from "hooks/Subscriptions/usePrimarySubscription";
import LinkButton from "app/components/Button/LinkButton";
import UploadFileContent from "./UploadFileContent";
import { SubmitPostContent } from "./SubmitPostContent";
import uploadFile from "./uploadVideoFileUtil";
import { StyledModal, Header } from "./styles";
import { SearchClassTag } from "./SearchClassTag";

const INITIAL_VIDEO_STATE = {
  videoFile: null as any,
  videoId: null as any,
  percentProgress: 0,
  canSubmit: false,
};

interface Props {
  buttonProps: any;
  classNumber?: number;
}

export const UploadUserVideoModal = ({ buttonProps, classNumber }: Props) => {
  const [classNumberToTag, setClassNumberToTag] = useState(classNumber);
  const [isTaggingClass, setIsTaggingClass] = useState(false);
  const { primarySubscription } = usePrimarySubscription();

  const buttonCopy = "Upload";
  const {
    videoUploadCreate,
    videoUploadComplete,
    videoUploadSubmit,
  } = useVideoUploadMutations();

  const [isOpen, setIsOpen] = useState(false);
  const [isInFirstStep, setIsInFirstStep] = useState(false);

  const [videoFileState, setVideoFileState] = useState(INITIAL_VIDEO_STATE);
  const [description, setDescription] = useState(null);
  const isUploading = videoFileState.videoFile && !videoFileState.canSubmit;

  const resetState = () => {
    setIsInFirstStep(true);
    setVideoFileState(INITIAL_VIDEO_STATE);
  };

  const toggleModal = () => {
    if (!isOpen) {
      setIsInFirstStep(true);
      setVideoFileState(INITIAL_VIDEO_STATE);
      setDescription(null);
    } else if (isUploading) {
      // prevent closing of modal while uploading
      return;
    }
    setIsOpen(!isOpen);
  };

  const handleUploadFile = async (videoFile: any) => {
    setIsInFirstStep(false);
    setVideoFileState(oldVideoFileState => ({
      ...oldVideoFileState,
      canSubmit: false,
      videoFile,
    }));

    const success = await uploadFile(
      classNumberToTag,
      videoFile,
      videoUploadCreate,
      videoUploadComplete,
      setVideoFileState
    );
    if (!success) {
      toast.error("Failed to upload video. Please try again.");
      resetState();
      return;
    }

    setVideoFileState(oldVideoFileState => ({
      ...oldVideoFileState,
      canSubmit: true,
    }));
  };

  const handleDescriptionChange = (e: any) => setDescription(e.target.value);

  const onSubmitVideo = async () => {
    try {
      const data = await videoUploadSubmit(
        videoFileState.videoId,
        description,
        classNumberToTag
      );
      if (data) {
        toast.success("Post uploaded! View all posts on your profile.");
        toggleModal();
      } else {
        toast.error("Failed to submit video. Please try again.");
        resetState();
      }
    } catch (e) {
      toast.error("Failed to submit video. Please try again.");
      resetState();
    }
  };

  const resetTaggedClass = () => {
    setClassNumberToTag(null);
  };

  if (isTaggingClass) {
    return (
      <StyledModal isOpen={isOpen}>
        <Header>
          <IconButton
            Icon={ChevronLeft}
            width="20px"
            color="black"
            onClick={() => setIsTaggingClass(false)}
          />
          <div>What Class Did You Take?</div>
          {/* Empty div for spacing */}
          <div />
        </Header>
        <SearchClassTag
          setClassNumberToTag={(classNumber: number) => {
            setClassNumberToTag(classNumber);
            setIsTaggingClass(false);
          }}
        />
      </StyledModal>
    );
  }

  return (
    <>
      {primarySubscription?.isActive ? (
        <Button
          buttonBg="monochrome.8"
          hoverBg="monochrome.6"
          onClick={toggleModal}
          width="100%"
          p={2}
          height="40px"
          fontSize="16px"
          borderRadius="8px"
          {...buttonProps}
        >
          <Icon as={Camera} height="100%" mr="8px" />
          {buttonCopy}
        </Button>
      ) : (
        <LinkButton
          bg="monochrome.8"
          hoverBg="monochrome.6"
          p={2}
          height="40px"
          fontSize="16px"
          borderRadius="8px"
          width="450px"
          to="/checkout"
        >
          Subscribe Now to Upload
        </LinkButton>
      )}
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
      >
        <Header>
          <div>{isInFirstStep ? "Upload a video" : "Submit Post"}</div>
          {!isUploading && (
            <IconButton
              Icon={Close}
              width="20px"
              color="black"
              onClick={toggleModal}
            />
          )}
        </Header>
        {isInFirstStep ? (
          <UploadFileContent
            onUploadFile={handleUploadFile}
            onError={() => {
              resetState();
            }}
          />
        ) : (
          <SubmitPostContent
            percentProgress={videoFileState.percentProgress}
            canSubmit={videoFileState.canSubmit}
            videoFile={videoFileState.videoFile}
            description={description}
            onDescriptionChange={handleDescriptionChange}
            onSubmitVideo={onSubmitVideo}
            setIsTaggingClass={setIsTaggingClass}
            classNumberToTag={classNumberToTag}
            resetTaggedClass={resetTaggedClass}
          />
        )}
      </StyledModal>
    </>
  );
};
