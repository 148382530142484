import styled, { css } from "styled-components";
import DefaultModal, {
  CloseModalButton as CloseButton,
} from "app/components/Modal";
import { IS_MOBILE } from "constants/index";

export const CloseModalButton = styled(CloseButton)``;

const DesktopModal = styled(DefaultModal)`
  width: 100%;
  max-width: 352px;
`;

const MobileModal = styled(DefaultModal)`
  width: 100vw;
  height: 100vh;
  margin: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${CloseModalButton} {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }
`;

export const Modal = IS_MOBILE ? MobileModal : DesktopModal;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const inputStyling = css`
  height: 48px;
  width: 100%;
  border-radius: 6px;
  border: ${({ theme }) => `2px solid ${theme.colors.monochrome[3]}`};
  padding: 8px;
`;

export const Input = styled.input`
  ${inputStyling};
`;

export const Select = styled.select`
  ${inputStyling};

  :after {
    margin-right: 4px;
  }
`;

export const Option = styled.option``;
