import React from "react";
import {
  InstantSearch,
  Configure,
  useHits,
} from "react-instantsearch-hooks-web";
import { Slider } from "app/components/Slider";
import ClassCard from "app/components/ClassCard";
import { ClassCardSkeleton } from "app/components/ClassCard/ClassCardSkeleton";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { searchClient } from "app/components/Algolia/constants";
import { MoreContentSection, SectionTitle } from "../shared-styles";

interface Props {
  instructorName: string;
}

const InstructorHits = ({ instructorName }: Props) => {
  const { hits } = useHits();
  if (!hits) {
    return <LoadingSlider />;
  }

  if (hits.length <= 0) {
    return null;
  }

  return (
    <MoreContentSection>
      <SectionTitle>More by {instructorName}</SectionTitle>
      <Slider
        data={hits}
        slideComponent={ClassCard}
        selectedFrom="InstructorClasses"
        slidesSpacing={24}
        cardHeight="190px"
        centered={false}
      />
    </MoreContentSection>
  );
};

export function ClassesByInstructor({ instructorName }: Props) {
  if (!instructorName) {
    return null;
  }

  return (
    <InstantSearch
      indexName={AlgoliaIndexes.classes}
      searchClient={searchClient}
    >
      <Configure
        filters={`instructor_name:"${instructorName}"`}
        hitsPerPage={10}
      />
      <InstructorHits instructorName={instructorName} />
    </InstantSearch>
  );
}

function LoadingSlider() {
  return (
    <Slider
      data={[{}, {}, {}, {}, {}]}
      slideComponent={ClassCardSkeleton}
      slidesSpacing={24}
      cardHeight="190px"
    />
  );
}
