import styled from "styled-components";
import { P1 } from "app/components/Typography";

export const CommentsMenuContainer = styled.div`
  position: relative;
`;

export const CommentsMenuOptions = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.monochrome[1]};
  background-color: ${({ theme }) => theme.colors.monochrome[0]};
  white-space: nowrap;
  font-size: 14px;
  display: ${props => (props.isOpen ? "block" : "none")};
`;

export const CommentsMenuOption = styled(P1)`
  padding: 16px;
  color: #fb6c6c;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }
`;
