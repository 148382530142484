import styled, { keyframes } from "styled-components";
import Div from "app/components/Div";

const shimmer = keyframes`
0% {
  opacity: 1;
    -webkit-transform: translateX(-50%) scale(.95, 1);
    transform: translateX(-50%) scale(.95, 1);
  }

30% {
  opacity: 1;
  -webkit-transform: translateX(75%) scale(.95, 1);
  transform: translateX(75%) scale(.95, 1);
}

37% {
  opacity: 0;
  -webkit-transform: translateX(80%) scale(.95, 1);
  transform: translateX(80%) scale(.95, 1);
}

75% {
  opacity: 0;
  -webkit-transform: translateX(50%) scale(0.95, 1);
  transform: translateX(50%) scale(0.95, 1);
}

100% {
  opacity: 0;
  -webkit-transform: translateX(0%) scale(0.95, 1);
  transform: translateX(0%) scale(0.95, 1);
}
`;

const previewShimmer = keyframes`
0% {
  opacity: 1;
    -webkit-transform: translateX(-100%) scale(.95, 1);
    transform: translateX(-100%) scale(.95, 1);
  }

30% {
  opacity: 1;
  -webkit-transform: translateX(100%) scale(.95, 1);
  transform: translateX(100%) scale(.95, 1);
}

37% {
  opacity: 0;
  -webkit-transform: translateX(100%) scale(.95, 1);
  transform: translateX(100%) scale(.95, 1);
}

75% {
  opacity: 0;
  -webkit-transform: translateX(25%) scale(0.95, 1);
  transform: translateX(25%) scale(0.95, 1);
}

100% {
  opacity: 0;
  -webkit-transform: translateX(-50%) scale(0.95, 1);
  transform: translateX(-50%) scale(0.95, 1);
}
`;

export const SkeletonPreviewAnimation = styled(Div)`
    background: linear-gradient(to right, #f0f3f3 10%, #ecefef 25%, #eaeeee 50%, #ecefef 75%, #f0f3f3 90%);
    height: 100%;
    animation: ${previewShimmer} 2.2s infinite linear;
`;

export const SkeletonDivAnimation = styled(Div)`
    background: linear-gradient(to right, #ecefef 0%, #d2d6d6 25%, #d2d6d6 50%, #ecefef 100%);
    height: 100%;
    animation: ${shimmer} 2.2s infinite linear;
`;

export const SkeletonTextAnimation = styled(Div)`
  height: 100%;
  background: linear-gradient(to right, #cdd0d0 0%, #b4b6b6 25%, #b4b6b6 75%, #cdd0d0 100%);
  animation: ${shimmer} 2.2s infinite linear;
`;