import Flex from "app/components/Flex";
import { P1 } from "app/components/Typography";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const CommentWrapper = styled(Flex)`
  width: 100%;
  height: auto;
  flex-direction: column;
  ${({ isFirstTierComment }) =>
    isFirstTierComment &&
    `
    :last-child {
      margin-bottom: 20px;
    }
  `}
  ${({ isFirstTierComment }) =>
    !isFirstTierComment &&
    `
    :first-child {
      margin-top: 14px;
    }
  `}
`;

export const CommentItem = styled(Flex)`
  margin-top: 20px;
  justify-content: space-between;
`;

export const CommentBody = styled(P1)`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.monochrome[4]};
  word-break: break-word;
`;

export const CommentByUserLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  letter-spacing: 0.04em;
  line-height: 140%;
  font-weight: bold;
  margin-bottom: 8px;
`;
