import {
  usePlaylistAddStudioClassMutation,
  useCreatePlaylistMutation,
  GetMyPlaylistsDocument,
} from "services/graphql";
import { toast } from "react-toastify";
import { playlistsEditPlaylist } from "services/typewriter/segment";
import { ANALYTICS_MODULE, ANALYTICS_ACTION } from "constants/analytics";
import {
  getAnalyticsLocationFromPath,
  getAnalyticsModuleFromPath,
} from "helpers/analytics";
import { useHistory, useLocation } from "react-router";

export function useCreatePlaylistAddStudioClass({
  classId,
  classNumber,
}: {
  classId: string;
  classNumber: number;
}) {
  const location = useLocation();
  const history = useHistory();
  const [createdPlaylistAddStudioClass] = usePlaylistAddStudioClassMutation();
  const [createPlaylistMutation] = useCreatePlaylistMutation();

  const createPlaylistAndAddClass = ({
    playlistName,
    playlistPrivacy,
  }: {
    playlistName: string;
    playlistPrivacy: string;
  }) => {
    createPlaylistMutation({
      variables: {
        input: {
          name: playlistName,
          hidden: playlistPrivacy === "true",
        },
      },
      refetchQueries: [
        {
          query: GetMyPlaylistsDocument,
          variables: { pagination: { first: 10 }, studioClassIds: [] },
        },
      ],
      onCompleted({ playlistCreate }) {
        playlistsEditPlaylist({
          action: ANALYTICS_ACTION.playlistsCreate,
          playlist_id: playlistCreate.id,
          playlist_name: playlistCreate.name,
          privacy: playlistCreate?.hidden === true ? "private" : "public",
          location: getAnalyticsLocationFromPath(location.pathname),
          module: getAnalyticsModuleFromPath(location.pathname),
        });
        toast.success(`Created ${playlistCreate.name} playlist`);
        createdPlaylistAddStudioClass({
          variables: {
            input: {
              playlistId: playlistCreate.id,
              studioClassId: classId,
            },
          },
          onCompleted() {
            playlistsEditPlaylist({
              action: ANALYTICS_ACTION.playlistsAddClass,
              playlist_id: playlistCreate.id,
              location: getAnalyticsLocationFromPath(location.pathname),
              class_id: classNumber.toString(),
              module: ANALYTICS_MODULE.playlist_details,
            });
            history.push(`/playlists/${playlistCreate.id}`);
          },
        });
      },
    });
  };

  return {
    createPlaylistAndAddClass,
  };
}
