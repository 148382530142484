import Avatar from "app/components/Avatar";
import IconButton from "app/components/Button/IconButton";
import Flex from "app/components/Flex";
import { Heart, HeartFilled } from "app/components/Icon";
import { P2 } from "app/components/Typography";
import {
  useActivityReactionCreate,
  useActivityReactionDelete,
} from "hooks/Activities";
import { useRedirectNoAccess } from "hooks/Challenges";
import moment from "moment";
import { PropTypes } from "prop-types";
import React from "react";
// eslint-disable-next-line import/no-cycle
import { Type, communityVideoActivity } from "services/typewriter/segment";
import RepliesList from "../../RepliesList";
import CommentsMenu from "../CommentsMenu";
import {
  CommentBody,
  CommentItem,
  CommentWrapper,
  CommentByUserLink,
} from "./styles";

const CommentsItem = ({
  comment,
  videoId,
  activityId,
  activityType,
  onReportComment,
  waypoint,
  isFirstTierComment = false,
  handleReplyComments,
}) => {
  const redirectNoAccess = useRedirectNoAccess();
  const activityReactionCreate = useActivityReactionCreate();
  const activityReactionDelete = useActivityReactionDelete();

  const didLike = comment.userReactions.includes("like");

  const handleLikeToggle = async () => {
    if (redirectNoAccess()) {
      return;
    }

    const createOrDeleteFn = didLike
      ? activityReactionDelete
      : activityReactionCreate;

    createOrDeleteFn({
      activityId: comment.id,
      activityType: "user_comment",
      reactionType: "like",
    });

    const newLike = !didLike;

    communityVideoActivity({
      to_user_id: comment.commentBy.uid,
      video_id: videoId,
      comment_id: comment.id,
      type: newLike ? Type.Like : Type.Unlike,
    });
  };

  return (
    <CommentWrapper key={comment.id} isFirstTierComment={isFirstTierComment}>
      <CommentItem>
        <Flex>
          <Avatar
            flex="0 0 30px"
            width={30}
            height={30}
            src={comment.commentBy.photoURL}
            alt={comment.commentBy.username}
          />
          <Flex
            ml={10}
            flexDirection="column"
            maxWidth={{
              _: "210px",
              xs: "240px",
              sm: "340px",
              md: "620px",
              lg: "280px",
            }}
            style={{ overflowWrap: "break-word" }}
          >
            <CommentByUserLink to={`/profile/${comment.commentBy.username}`}>
              {comment.commentBy.username}
            </CommentByUserLink>
            <CommentBody>{comment.body}</CommentBody>
            <Flex>
              <P2 color="monochrome.5">
                {moment(comment.createdAt).fromNow(true)}
              </P2>
              {isFirstTierComment && (
                <P2
                  color="monochrome.6"
                  ml={2}
                  fontWeight="600"
                  cursor="pointer"
                  onClick={() => handleReplyComments(comment)}
                >
                  Reply
                </P2>
              )}
              {waypoint}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          paddingLeft="20px"
          justifyContent="start"
          alignItems="center"
        >
          <CommentsMenu
            comment={comment}
            activityId={activityId}
            activityType={activityType}
            onReportComment={onReportComment}
            isFirstTierComment={isFirstTierComment}
          />
          <IconButton
            width={17}
            mb={2}
            color="monochrome.9"
            Icon={didLike ? HeartFilled : Heart}
            onClick={handleLikeToggle}
          />
          <P2>
            {comment.reactions.filter(r => r.reactionType === "like")[0]
              ?.totalCount || 0}
          </P2>
        </Flex>
      </CommentItem>
      {isFirstTierComment && (
        <RepliesList videoId={activityId} comment={comment} />
      )}
    </CommentWrapper>
  );
};

CommentsItem.propTypes = {
  comment: PropTypes.shape({}).isRequired,
  activityId: PropTypes.string.isRequired,
  onReportComment: PropTypes.func.isRequired,
  activityType: PropTypes.oneOf(["user_video", "user_comment", "challenge"])
    .isRequired,
  waypoint: PropTypes.node,
  isFirstTierComment: PropTypes.bool,
  handleReplyComments: PropTypes.func,
};

export default CommentsItem;
