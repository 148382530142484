import styled from "styled-components";
import media from "styled-media-query";

export const DragDropContainer = styled.div`
  background-color: ${({ isDragActive, theme }) =>
    isDragActive ? theme.colors.monochrome[1] : ""};
  opacity: ${({ isDragActive }) => (isDragActive ? 0.2 : 1)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100%;
`;

export const IconWrapper = styled.div`
  margin-bottom: 34px;
`;

export const DragDropContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const DragDropContentTitle = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  text-align: center;
`;

export const DragDropContentSubtitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.monochrome[5]};
  margin-bottom: 24px;
  text-align: center;
`;

export const LegalSection = styled.div`
  padding: 24px 32px;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.monochrome[4]};
  text-align: center;
  margin: 0 auto;
  ${media.greaterThan("medium")`
    width: 80%;
  `}
`;
