import React from "react";
// eslint-disable-next-line import/no-cycle
import CommentsList from "../CommentsList";

export default ({ comment, videoId }) => (
  <CommentsList
    videoId={videoId}
    activityId={comment.id}
    activityType="user_comment"
    onReportComment={() => {}}
  />
);
