import React from "react";
import { DropdownLineSeparator } from "app/components/Dropdown";
import { Class } from "services/graphql";
import {
  AddClassToScheduleOption,
  AddClassToPlaylistOption,
  ShareClassOption,
  GoToClassStyleOption,
  GoToClassLevelOption,
  GoToInstructorOption,
  DropdownMenuWrapper,
} from "app/components/ClassComponents/DropdownMenuOptions";
import { MenuButton } from "./MenuButton";

interface Props {
  classData: Partial<Class>;
}

export const DropdownMenuOptions = ({ classData }: Props) => {
  if (!classData) {
    return null;
  }

  const classNumber = Number(classData.id);
  const classId = classData.studioClassId;

  return (
    <DropdownMenuWrapper menuButton={MenuButton}>
      <AddClassToPlaylistOption classNumber={classNumber} classId={classId} />
      <AddClassToScheduleOption classNumber={classNumber} />
      <ShareClassOption classData={classData} />
      <DropdownLineSeparator />
      <GoToClassStyleOption styleName={classData.style} />
      <GoToClassLevelOption classLevel={classData.level} />
      <GoToInstructorOption instructorSlug={classData.instructor?.slug} />
    </DropdownMenuWrapper>
  );
};
