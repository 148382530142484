import React from "react";
import { useDispatch } from "react-redux";
import { userPreviewingContentAction } from "modules/content";
import { Class } from "services/graphql";
import Icon, { Play, VolumeOff } from "app/components/Icon";
import PlayerWithControls from "app/components/ResponsiveVideoPlayer/PlayerWithControls";
import Flex from "app/components/Flex";
import { useCanUserTakeClass } from "hooks/Classes";
import { CtaButton } from "./styles";

interface Props {
  classData: Class;
  goToClass(): void;
}

export function PreviewPlayer({ classData, goToClass }: Props) {
  const { canUserTakeClass } = useCanUserTakeClass({ classId: classData.id });
  const dispatch = useDispatch();

  if (!classData) {
    return null;
  }

  return (
    <PlayerWithControls
      playsinline
      volume={classData.isPreviewVideoMuted ? 0 : null}
      url={classData.preview_url}
      onProgress={(videoProgress: any) => {
        dispatch(
          userPreviewingContentAction({
            contentData: classData,
            component: "PreviewPlayer",
            previewDuration: Math.floor(videoProgress.playedSeconds),
          })
        );
      }}
      CtaComponents={() => (
        <CtaButton onClick={goToClass}>
          <Icon as={Play} color="white" height="25px" width="25px" />
          {canUserTakeClass ? "Start" : "Upgrade to Start"}
        </CtaButton>
      )}
      AlertComponent={() =>
        classData.isPreviewVideoMuted && (
          <Flex
            p="12px"
            bg="rgb(0, 0, 0, 0.5)"
            borderRadius="4px"
            color="white"
          >
            <Icon
              as={VolumeOff}
              width="26px"
              height="26px"
              mr="8px"
              color="white"
            />
            <div>
              <p>
                This music can't be played due to the recent licensing changes
                with the rights holders.{" "}
              </p>
              {classData.externalPreviewUrl && (
                <p>
                  <>
                    To preview with music,{" "}
                    <a
                      href={classData.externalPreviewUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      please click here.
                    </a>
                  </>
                </p>
              )}
            </div>
          </Flex>
        )
      }
      loop
      showLoader={false}
      position="absolute"
      height="100%"
      width="auto"
    />
  );
}
