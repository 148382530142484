import React from "react";
import PropTypes from "prop-types";
import ProgressCircle from "app/components/ProgressCircle";

const CharacterCountCircle = ({ maxLength, currentLength }) => {
  const isUnderCharLimit = currentLength <= maxLength;
  const progress = Math.round((currentLength / maxLength) * 100);
  const isCloseToCharLimit = progress > 80;
  const text = isCloseToCharLimit ? maxLength - currentLength : "";
  let progressColor = "transparent";
  if (isUnderCharLimit) {
    progressColor = isCloseToCharLimit ? "redMercedes.5" : "blue";
  }

  return (
    <ProgressCircle
      fontSize="9px"
      percentProgress={progress}
      progressColor={progressColor}
      progressPathColor={
        isUnderCharLimit ? "rgba(0, 0, 0, 0.2)" : "transparent"
      }
      text={text}
      textColor={isUnderCharLimit ? "monochrome.8" : "redMercedes.5"}
      width={25}
    />
  );
};

CharacterCountCircle.propTypes = {
  maxLength: PropTypes.number.isRequired,
  currentLength: PropTypes.number.isRequired,
};

export default CharacterCountCircle;
