import Flex from "app/components/Flex";
import Icon, { Explicit } from "app/components/Icon";
import Img from "app/components/Img";
import React from "react";
import { Text } from "./styles";

type Props = {
  cover: string;
  artistName: string;
  songName: string;
  albumName?: string;
  recordLabelName: string;
  isExplicit: boolean;
  mr?: string;
  mb?: string;
};

export const TrackInformation = ({
  cover,
  artistName,
  songName,
  albumName,
  recordLabelName,
  isExplicit,
  ...rest
}: Props) => {
  return (
    <Flex {...rest}>
      {cover && (
        <Img
          width="60px"
          height="60px"
          borderRadius="4px"
          src={cover}
          mr="10px"
        />
      )}
      <Flex minHeight="45px" flexDirection="column">
        <Flex>
          <Text fontWeight="700">{artistName}</Text>
        </Flex>
        <Flex flexDirection="row" justifyContent="space-between">
          <Flex>
            <Text>
              {songName}
              {albumName && (
                <>
                  &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                  {albumName}
                </>
              )}
            </Text>
          </Flex>
          {isExplicit && (
            <Flex width="12px" justifyContent="center" ml="10px">
              <Icon as={Explicit} />
            </Flex>
          )}
        </Flex>
        {recordLabelName && (
          <Flex>
            <Text>{recordLabelName}</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
