import styled from "styled-components";

export const StyledButton = styled.button<{ isActive: boolean }>`
  padding: 0;
  border: 0;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  gap: 4px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.blue : theme.colors.monochrome[3]};

  svg {
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.blue : theme.colors.monochrome[3]};
  }

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;
