import Flex from "app/components/Flex";
import React, { useRef, useState } from "react";

type SliderProps = {
  data: any;
  slideComponent: any;
  width: number;
  [x: string]: any;
};

export const Slide = ({
  data,
  slideComponent: SlideComponent,
  width,
  ...props
}: SliderProps) => {
  const [shouldDisableClick, setShouldDisableClick] = useState(false);
  const slideRef = useRef<any>(null);

  const onMouseMove = () => {
    if (!shouldDisableClick) {
      setShouldDisableClick(true);
    }
  };

  const onClickEvent = () => {
    slideRef.current.onmousemove = null;
    setShouldDisableClick(false);
  };

  return (
    <Flex
      width={width}
      ref={slideRef}
      onMouseDown={() => {
        slideRef.current.onmousemove = onMouseMove;
      }}
      onMouseLeave={() => {
        slideRef.current.onmousemove = null;
        setShouldDisableClick(false);
      }}
      onClick={onClickEvent}
    >
      <SlideComponent
        {...props}
        key={data.id}
        data={data}
        shouldPropagateClickEvent
        disableClick={shouldDisableClick}
      />
    </Flex>
  );
};
