import { useMutation } from "@apollo/client";
import { cloneDeep } from "lodash";
import { ACTIVITY_REACTION_CREATE } from "graphql/activities/mutations";
import { readActivityTypeFragment, writeActivityTypeFragment } from "./utils";

export default () => {
  const [activityReactionCreate] = useMutation(ACTIVITY_REACTION_CREATE);

  return async ({ activityId, activityType, reactionType }) => {
    const resp = await activityReactionCreate({
      variables: {
        input: {
          contentId: activityId,
          contentType: activityType,
          reactionType,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        activityReactionCreate: {
          id: "success",
        },
      },
      update(_, { data }) {
        if (!data.activityReactionCreate.id) {
          return;
        }

        const activityFragment = readActivityTypeFragment(
          activityType,
          activityId
        );
        const newActivityFragment = cloneDeep(activityFragment);
        // add that user has reacted to this content
        if (
          newActivityFragment.userReactions &&
          newActivityFragment.userReactions.indexOf(reactionType) < 0
        ) {
          newActivityFragment.userReactions.push(reactionType);
        }
        // add to total count of reactions for this content
        const reaction =
          newActivityFragment.reactions &&
          newActivityFragment.reactions.filter(
            r => r.reactionType === reactionType
          )[0];
        if (reaction) {
          reaction.totalCount++;
        }
        writeActivityTypeFragment(activityType, newActivityFragment);
      },
    });
    const success = !!resp.data?.activityReactionCreate?.id;
    return success;
  };
};
