import React from "react";
import { Link } from "react-router-dom";
import { DropdownOption } from "app/components/Dropdown";

interface Props {
  instructorSlug: string;
}

export const GoToInstructorOption = ({ instructorSlug }: Props) => {
  if (!instructorSlug) {
    return null;
  }

  return (
    <Link to={`/library/instructors/${instructorSlug}`}>
      <DropdownOption text="Go to Instructor" />
    </Link>
  );
};
