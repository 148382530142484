import React from "react";
import { DropdownLineSeparator } from "app/components/Dropdown";
import { Class } from "services/graphql";
import {
  AddClassToScheduleOption,
  AddClassToPlaylistOption,
  GoToClassStyleOption,
  GoToClassLevelOption,
  GoToInstructorOption,
  DropdownMenuWrapper,
  PreviewClassOption,
  MobilePreviewClassOption,
  SaveClassOption,
  ShareClassOption,
} from "app/components/ClassComponents/DropdownMenuOptions";
import { AlgoliaClassData } from "app/components/Algolia/types";
import { IS_MOBILE } from "constants/index";

interface Props {
  classData: Partial<Class> | AlgoliaClassData;
  onUserSelectsClass(): void;
}

export const DropdownMenuOptions = ({
  classData,
  onUserSelectsClass,
}: Props) => {
  const classNumber = Number(classData.id);
  const classId = classData.studioClassId;
  const instructorSlug =
    (classData as Partial<Class>).instructor?.slug ||
    (classData as AlgoliaClassData).instructor_slug;

  return (
    <DropdownMenuWrapper>
      {IS_MOBILE ? (
        <MobilePreviewClassOption classData={classData} />
      ) : (
        <PreviewClassOption
          classData={classData}
          onUserSelectsClass={onUserSelectsClass}
        />
      )}
      <SaveClassOption classData={classData} />
      <AddClassToPlaylistOption classNumber={classNumber} classId={classId} />
      <AddClassToScheduleOption classNumber={classNumber} />
      <ShareClassOption classData={classData} />
      <DropdownLineSeparator />
      <GoToClassStyleOption styleName={classData.style} />
      <GoToClassLevelOption classLevel={classData.level} />
      <GoToInstructorOption instructorSlug={instructorSlug} />
    </DropdownMenuWrapper>
  );
};
