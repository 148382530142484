import React, { useState } from "react";
import { H2 } from "app/components/Typography";
import Flex from "app/components/Flex";
import Text from "app/components/Text";
import Tooltip from "app/components/Tooltip";
import {
  ContentCopy,
  FAFacebook,
  TwitterWithCircle,
  Reddit,
  Pinterest,
} from "app/components/Icon";
import env from "helpers/env";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import { Modal, CloseModalButton } from "./styles";
import { PartialPlaylistV2 } from "../types";

const Icon = styled.div`
  width: 36px;
  height: 36px;
`;

const SocialWrapper = styled(Flex).attrs({
  alignItems: "center",
  cursor: "pointer",
})``;

const SocialText = styled(Text).attrs({
  fontSize: "16px",
  fontWeight: "500",
  marginLeft: "16px",
})``;

interface Props {
  closeModal(): void;
  playlist: PartialPlaylistV2;
}

export function SharePlaylistModal({ closeModal, playlist }: Props) {
  const playlistUrl = `${env("PUBLIC_DOMAIN")}/playlists/${playlist.id}`;
  const [copyLinkMessage, setCopyLinkMessage] = useState("Copy Link");

  return (
    <Modal isOpen onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <H2>Share</H2>
        <CloseModalButton onClick={closeModal} variant="light" />
      </Flex>
      <Flex flexDirection="column" gap="12px" width="100%">
        <SocialWrapper
          onClick={() =>
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${playlistUrl}`,
              "blank"
            )
          }
        >
          <Icon as={FAFacebook} color="#1877F1" />
          <SocialText>Share on Facebook</SocialText>
        </SocialWrapper>
        <SocialWrapper
          onClick={() =>
            window.open(
              `https://twitter.com/intent/tweet?url=${playlistUrl}`,
              "blank"
            )
          }
        >
          <Icon as={TwitterWithCircle} color="#00acee" />
          <SocialText>Share on Twitter</SocialText>
        </SocialWrapper>
        <SocialWrapper
          onClick={() =>
            window.open(
              `http://www.reddit.com/submit?url=${playlistUrl}`,
              "blank"
            )
          }
        >
          <Icon as={Reddit} color="#FF4500" />
          <SocialText>Share on Reddit</SocialText>
        </SocialWrapper>
        <SocialWrapper
          onClick={() =>
            window.open(
              `https://pinterest.com/pin-builder/?url=${playlistUrl}&media=${classThumbnailUrl}`,
              "blank"
            )
          }
        >
          <Icon as={Pinterest} color="#E60023" />
          <SocialText>Share on Pinterest</SocialText>
        </SocialWrapper>
        <CopyToClipboard
          text={`${playlistUrl}`}
          onCopy={() => setCopyLinkMessage("Link Copied!")}
        >
          <Tooltip overlay={copyLinkMessage}>
            <SocialWrapper onMouseLeave={() => setCopyLinkMessage("Copy Link")}>
              <Icon as={ContentCopy} color="black" />
              <SocialText>Copy link to clipboard</SocialText>
            </SocialWrapper>
          </Tooltip>
        </CopyToClipboard>
      </Flex>
    </Modal>
  );
}
