import React, { useEffect, useState } from "react";
import "rc-slider/assets/index.css";
import convertTimeToString from "helpers/convertTimeToString";
import {
  ProgressBarWrapper,
  SliderWrapper,
  StyledProgressBarSlider,
} from "./styles";

interface Props {
  currentTimeInSeconds?: number;
  onAfterChange?: (newValue: number) => void;
  max?: number;
  totalDuration?: number;
}

export const ProgressBar = ({
  currentTimeInSeconds = 0,
  onAfterChange,
  max,
  ...rest
}: Props) => {
  const [dragging, setDragging] = useState(false);
  const [value, setValue] = useState(currentTimeInSeconds);

  useEffect(() => {
    if (!dragging) {
      setValue(currentTimeInSeconds);
    }
  }, [currentTimeInSeconds, dragging]);

  return (
    <ProgressBarWrapper
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <SliderWrapper>
        <StyledProgressBarSlider
          tipFormatter={(newValue: any) => convertTimeToString(newValue)}
          value={value}
          onBeforeChange={() => {
            setDragging(true);
          }}
          onChange={setValue}
          onAfterChange={(newValue: any) => {
            if (onAfterChange) {
              onAfterChange(newValue);
            }
            setDragging(false);
          }}
          max={max}
          {...rest}
        />
      </SliderWrapper>
    </ProgressBarWrapper>
  );
};
