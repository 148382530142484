import React, { useState } from "react";
import { PlusBox } from "app/components/Icon";
import { DropdownOption } from "app/components/Dropdown";
import { AddToPlaylistsModal } from "app/components/PlaylistComponents/PlaylistModals";
import { useGetUserIsAnonymous } from "modules/selectors";

interface Props {
  classNumber: number;
  classId: string;
}

export const AddClassToPlaylistOption = ({ classNumber, classId }: Props) => {
  const isAnonymous = useGetUserIsAnonymous();
  const [isAddToPlaylistModalOpen, setIsAddToPlaylistsModalOpen] = useState(
    false
  );

  if (isAnonymous) {
    return null;
  }

  if (!classNumber || !classId) {
    return null;
  }

  return (
    <>
      <DropdownOption
        Svg={PlusBox}
        onClick={() => {
          setIsAddToPlaylistsModalOpen(true);
        }}
        text="Add to Playlist"
      />
      {isAddToPlaylistModalOpen && (
        <AddToPlaylistsModal
          classNumber={classNumber}
          classId={classId}
          closeModal={() => setIsAddToPlaylistsModalOpen(false)}
        />
      )}
    </>
  );
};
