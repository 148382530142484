import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Icon, { Upload } from "app/components/Icon";
import SpanLink from "app/components/SpanLink";
import Button from "app/components/Button";
import env from "helpers/env";
import { UploadUserVideoContentWrapper } from "./styles";
import {
  DragDropContainer,
  IconWrapper,
  DragDropContent,
  DragDropContentTitle,
  DragDropContentSubtitle,
  LegalSection,
} from "./UploadFileContent.styles";

const MAX_FILE_MB_SIZE = 300;

const UploadFileContent = ({ onUploadFile, onError }) => {
  const onDrop = async acceptedFiles => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const file = acceptedFiles[0];
    onUploadFile(file);
  };

  const onDropRejected = fileRejections => {
    if (fileRejections.length === 0 || fileRejections[0].errors.length === 0) {
      return;
    }

    const error = fileRejections[0].errors[0];
    if (error.code === "file-invalid-type") {
      toast.error("Invalid file type. Please upload a video.");
    } else if (error.code === "file-too-large") {
      toast.error(
        `File size too large. Please select a file size of ${MAX_FILE_MB_SIZE}mb or less.`
      );
    } else {
      toast.error("Error uploading video. Please try again.");
    }

    onError();
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: MAX_FILE_MB_SIZE * 1000 * 1000,
    minSize: 1,
    multiple: false,
    maxFiles: 1,
    accept: "video/*",
    noClick: true,
  });

  return (
    <UploadUserVideoContentWrapper>
      <DragDropContainer {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} />
        <DragDropContent justifyContent="center" flexDirection="column">
          <IconWrapper>
            <Icon as={Upload} width="36px" height="auto" />
          </IconWrapper>
          <DragDropContentTitle>
            Drag and drop video files to upload
          </DragDropContentTitle>
          <DragDropContentSubtitle>
            Upon publishing, your videos will be viewable to the public.
            <br />
            Max file size limit: 300MB
          </DragDropContentSubtitle>
          <Button maxWidth="150px" onClick={open}>
            Select File
          </Button>
        </DragDropContent>
      </DragDropContainer>
      <LegalSection>
        By submitting your videos to STEEZY, you acknowledge that you agree to
        STEEZY&#39;s{" "}
        <SpanLink
          onClick={() => window.open(`${env("PUBLIC_URL")}/terms`, "blank")}
        >
          Terms of Service
        </SpanLink>{" "}
        and{" "}
        <SpanLink
          onClick={() =>
            window.open("/community?showCommunityGuidelines=1", "blank")
          }
        >
          Community Guidelines
        </SpanLink>
        . Please be sure not to violate others&#39; copyright or privacy rights.
      </LegalSection>
    </UploadUserVideoContentWrapper>
  );
};

UploadFileContent.defaultProps = {};

UploadFileContent.propTypes = {
  onUploadFile: PropTypes.func,
};

export default UploadFileContent;
