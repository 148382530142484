import { useSelector } from "react-redux";

export const useContentPreviewingProgramSlug = () =>
  useSelector(
    ({ content }: { content: { previewingProgramSlug: string } }) =>
      content.previewingProgramSlug
  );

export const useIsClassSaved = ({ classNumber }: { classNumber: number }) =>
  useSelector(
    ({ user }: { user: { public: { saved_classes: string[] } } }) =>
      !!user?.public?.saved_classes?.[classNumber]
  );
