import React, { useContext } from "react";
import Flex from "app/components/Flex";
import Thumbnail from "app/components/Thumbnail";
import { VideoCameraBackground } from "app/components/Icon";
import { P1, H4 } from "app/components/Typography";
import Text from "app/components/Text";
import env from "helpers/env";
import styled from "styled-components";
import { VideoUpload } from "services/graphql";
import { useHistory } from "react-router-dom";
import { ModalContext } from "app/components/Modal/ModalWithContext";
import { ReportOverlay } from "./ReportOverlay";

const UploadedByText = styled(H4)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 4px;
`;

interface Props {
  key: string;
  userVideo: VideoUpload;
  openModal(userVideo: VideoUpload): void;
}

export const UserVideoUpload = ({ key, userVideo, openModal }: Props) => {
  const modalContext = useContext(ModalContext);
  const history = useHistory();
  const {
    id,
    uploadedBy,
    thumbnailUrl,
    encodingStatus,
    reportedByMe,
  } = userVideo;
  const isEncoding = encodingStatus !== "available";
  const onClick = () => {
    // Don't want to render 2 modals
    if (modalContext.isRenderedInModal) {
      history.push(`/post/${id}`);
      return;
    }

    openModal(userVideo);
  };

  return (
    <Flex
      justifyContent="center"
      onClick={onClick}
      key={key}
      cursor={isEncoding ? "default" : "pointer"}
    >
      <Flex flexDirection="column">
        <Flex position="relative" flexDirection="column">
          <Thumbnail
            as={isEncoding && VideoCameraBackground}
            src={thumbnailUrl}
            placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/challenges/black.jpg`}
            width="120px"
            mb="12px"
            borderRadius="5px"
          />
          {isEncoding && (
            <Text
              position="absolute"
              color="white"
              fontSize={{ _: "20px", md: "14px" }}
              maxWidth="70%"
              top="43%"
              left="15%"
              textAlign="center"
            >
              Your video is still processing. Check back in a few minutes!
            </Text>
          )}
          {reportedByMe && (
            <ReportOverlay iconWidth="40px" iconHeight="40px">
              <P1 color="white" mt={3}>
                Post Reported
              </P1>
            </ReportOverlay>
          )}
        </Flex>
        <Flex height="40px" maxHeight="40px" maxWidth="120px" mb={3}>
          <UploadedByText>@{uploadedBy.username}</UploadedByText>
        </Flex>
      </Flex>
    </Flex>
  );
};
