import styled from "styled-components";
import { buttonCss } from "app/components/Button";

export const ClassRowWrapper = styled.div`
  padding: 16px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.monochrome[1]};
  }
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;

  svg {
    position: absolute;
    top: 0;
    left: 4px;
    padding: 2px;
  }

  .ais-SearchBox {
    height: 32px;
  }

  .ais-SearchBox-form {
    height: 100%;
    display: flex;
    gap: 16px;
  }

  .ais-SearchBox-input {
    border: none;
    background: ${({ theme }) => theme.colors.monochrome[1]};
    border-radius: 4px;
    max-width: 335px;
    width: 100%;
    padding-left: 36px;
    padding-right: 12px;
    :focus {
      outline: none;
    }
  }

  .ais-SearchBox-submit {
    ${buttonCss}
    padding: 12px 20px;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 14px;
  }

  .ais-SearchBox-reset {
    display: none;
  }
`;
