import React from "react";
import { useGetClassByIdQuery } from "services/graphql";
import { UserVideoUploadList } from "./UserVideoUploadList";

interface Props {
  classNumber: number;
  fromModule: string;
}

export function UserVideos({ classNumber, fromModule }: Props) {
  const { data, loading, error } = useGetClassByIdQuery({
    variables: {
      classId: classNumber.toString(),
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <UserVideoUploadList
      videos={data.classById.userVideos.edges}
      classNumber={classNumber}
      fromModule={fromModule}
    />
  );
}
