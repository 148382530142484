import gqlCache from "config/gql-cache";
import { useActivityCommentDeleteMutation } from "services/graphql";
import { activityTypeToFragmentName } from "../Activities/utils";

export default () => {
  const [activityCommentDelete] = useActivityCommentDeleteMutation();

  return async ({
    commentId,
    activityId,
    activityType,
    isFirstTierComment,
  }) => {
    const resp = await activityCommentDelete({
      variables: {
        commentId,
      },
      update(_, { data }) {
        if (!data.activityCommentDelete) {
          return;
        }

        const fieldName = isFirstTierComment ? "comments" : "replies";

        gqlCache.modify({
          id: gqlCache.identify({
            id: activityId,
            __typename: activityTypeToFragmentName(activityType),
          }),
          fields: {
            [fieldName](existingActivityTypeRef, { readField }) {
              const { edges = [], pageInfo = {} } = existingActivityTypeRef;

              const leftEdges = edges.filter(
                edge => readField("id", edge.node) !== commentId
              );

              const updatedEdges = leftEdges.map(edge => ({
                ...edge,
                cursor: `${parseInt(edge.cursor) - 1}`,
              }));

              const newPageInfo = {
                ...pageInfo,
                endCursor: `${parseInt(pageInfo.endCursor) - 1}`,
              };

              const updatedActivityTypeRef = {
                ...existingActivityTypeRef,
                edges: updatedEdges,
                totalCount: updatedEdges.length,
                pageInfo: newPageInfo,
              };

              return updatedActivityTypeRef;
            },
          },
        });
      },
    });
    const success = resp.data?.activityCommentDelete;
    return success;
  };
};
