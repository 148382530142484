import React from "react";
import Flex from "app/components/Flex";
import { AppStoreBadge, GooglePlayBadge } from "app/components/Icon";
import { IS_IOS } from "constants/index";
import { AppIcon, DownloadButton } from "../shared-styles";

export function DownloadMobileAppBadges() {
  return (
    <Flex width="100%" gap="8px">
      <Flex flex="auto">
        <DownloadButton
          onClick={() =>
            window.open(
              IS_IOS
                ? "https://itunes.apple.com/us/app/steezy-studio/id1296001664"
                : "https://play.google.com/store/apps/details?id=co.steezy.app"
            )
          }
        >
          Download the app to take class
        </DownloadButton>
      </Flex>
      {IS_IOS && (
        <a href="https://itunes.apple.com/us/app/steezy-studio/id1296001664">
          <AppIcon as={AppStoreBadge} />
        </a>
      )}
      {!IS_IOS && (
        <a
          ml={2}
          href="https://play.google.com/store/apps/details?id=co.steezy.app"
        >
          <AppIcon as={GooglePlayBadge} />
        </a>
      )}
    </Flex>
  );
}
