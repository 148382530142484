import React from "react";
import useGetClass from "hooks/useGetClass";
import Thumbnail from "app/components/Thumbnail";
import env from "helpers/env";
import { LimitLineText } from "app/components/Typography";
import Flex from "app/components/Flex";
import Icon, { CircleX } from "app/components/Icon";

interface Props {
  classNumber: number;
  resetTaggedClass(): void;
}

export function TaggedClass({ classNumber, resetTaggedClass }: Props) {
  const [getClassData] = useGetClass({
    variables: { classId: classNumber.toString() },
  });
  const classData = getClassData?.current;

  if (!classData) {
    // @TODO: Add loading state
    return <></>;
  }

  return (
    <Flex
      width="100%"
      height="60px"
      borderRadius="2px"
      bg="monochrome.1"
      p="8px"
      alignItems="center"
    >
      <Flex
        position="relative"
        borderRadius="2px"
        overflow="hidden"
        height="100%"
      >
        <Thumbnail
          height="100%"
          width="auto"
          src={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
            classData.thumbnail
          }-basic.jpg?w=200`}
          placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
            classData.thumbnail
          }-basic.jpg?blur=200&px=16&auto=format`}
        />
      </Flex>
      <Flex flex={1} p={2} flexDirection="column" justifyContent="center">
        <LimitLineText
          fontSize="12px"
          fontWeight="bold"
          color="black"
          textTransform="uppercase"
          letterSpacing="1.2px"
        >
          {classData.title}
        </LimitLineText>
        {classData?.instructor && (
          <LimitLineText fontSize="12px" color="black">
            {classData?.instructor?.name}
          </LimitLineText>
        )}
      </Flex>
      <Icon
        color="monochrome.3"
        height="20px"
        as={CircleX}
        cursor="pointer"
        onClick={() => {
          resetTaggedClass();
        }}
      />
    </Flex>
  );
}
