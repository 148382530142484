import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { P1, P2 } from "app/components/Typography";
import { Waypoint } from "app/components/Waypoint";
import {
  useGetCommentsByChallengeWithPagination,
  useGetCommentsByVideoUploadWithPagination,
  useGetCommentsReplies,
} from "hooks/Comments";
import { PropTypes } from "prop-types";
import React from "react";
// eslint-disable-next-line import/no-cycle
import CommentsItem from "./CommentsItem";
import EmptyState from "./EmptyState";

const ACTIVITY_TYPE = {
  challenge: "challenge",
  userComment: "user_comment",
  userVideo: "user_video",
};

const getCommentsDataHook = (activityId, activityType) => {
  switch (activityType) {
    case ACTIVITY_TYPE.challenge:
      return {
        useDataFn: useGetCommentsByChallengeWithPagination,
        arg: { id: activityId },
      };
    case ACTIVITY_TYPE.userComment:
      return {
        useDataFn: useGetCommentsReplies,
        arg: { id: activityId },
      };
    case ACTIVITY_TYPE.userVideo:
    default:
      return {
        useDataFn: useGetCommentsByVideoUploadWithPagination,
        arg: { input: { id: activityId } },
      };
  }
};

const CommentsList = ({
  videoId,
  activityId,
  activityType,
  onReportComment,
  isFirstTierComment = false,
  handleReplyComments,
}) => {
  const { useDataFn, arg } = getCommentsDataHook(activityId, activityType);
  const [comments, hasMore, fetchNextPage] = useDataFn(arg);

  const isUserCommentActivity = activityType === ACTIVITY_TYPE.userComment;

  const viewableComments = comments.filter(comment => !comment.reportedByMe);

  const viewMoreReplies = isUserCommentActivity && hasMore;

  if (!isFirstTierComment && !viewableComments.length) {
    return null;
  }

  return (
    <Flex width="100%">
      {isUserCommentActivity && (
        <Div padding="20px 25px 0 14px" pb={viewMoreReplies && "14px"}>
          <Div bg="rgba(0, 0, 0, 0.1)" height="100%" width="2px" />
        </Div>
      )}
      <Flex
        width="100%"
        flexDirection="column"
        padding={!isUserCommentActivity && "0 20px"}
        height="100%"
      >
        {!isUserCommentActivity && (
          <Flex width="100%" mb="3px" mt="14px">
            <P1 fontWeight="bold">Comments</P1>
          </Flex>
        )}
        <Flex flexDirection="column" height="100%">
          {!isUserCommentActivity && viewableComments.length === 0 && (
            <EmptyState />
          )}
          {viewableComments.map((comment, index) => (
            <CommentsItem
              key={comment.id}
              comment={comment}
              videoId={videoId}
              activityId={activityId}
              activityType={activityType}
              onReportComment={onReportComment}
              waypoint={
                !isUserCommentActivity &&
                index === viewableComments.length - 1 && (
                  <Waypoint fetchData={fetchNextPage} hasMore={hasMore} />
                )
              }
              isFirstTierComment={isFirstTierComment}
              handleReplyComments={handleReplyComments}
            />
          ))}
        </Flex>
        {viewMoreReplies && (
          <Flex mb={14}>
            <P2
              padding="24px 0 8px 0"
              fontWeight="600"
              color="#2D8CFC"
              cursor="pointer"
              onClick={fetchNextPage}
            >
              View more replies
            </P2>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

CommentsList.propTypes = {
  activityId: PropTypes.string.isRequired,
  onReportComment: PropTypes.func.isRequired,
  activityType: PropTypes.oneOf(["user_video", "user_comment", "challenge"])
    .isRequired,
  isFirstTierComment: PropTypes.bool,
  handleReplyComments: PropTypes.func,
};

export default CommentsList;
