import React from "react";
import { Trash } from "app/components/Icon";
import { DropdownOption, DropdownLineSeparator } from "app/components/Dropdown";
import { ANALYTICS_MODULE } from "constants/analytics";
import {
  AddClassToScheduleOption,
  AddClassToPlaylistOption,
  SaveStudioClassOption,
  GoToClassStyleOption,
  GoToClassLevelOption,
  GoToInstructorOption,
  DropdownMenuWrapper,
  ShareStudioClassOption,
  PreviewStudioClassOption,
  MobilePreviewClassOption,
} from "app/components/ClassComponents/DropdownMenuOptions";
import { IS_MOBILE } from "constants/index";
import { useGetPlaylist, usePlaylistRemoveStudioClass } from "../../hooks";
import { PartialStudioClass } from "../../types";

interface Props {
  studioClass: PartialStudioClass;
  playlistId: string;
  onUserSelectsClass(): void;
}

export const DropdownMenuOptions = ({
  studioClass,
  playlistId,
  onUserSelectsClass,
}: Props) => {
  const { playlist } = useGetPlaylist({ playlistId });
  const { playlistRemoveStudioClass } = usePlaylistRemoveStudioClass({
    playlistId,
    studioClassId: studioClass.id,
    analyticsData: {
      class_id: studioClass.classId?.toString(),
      module: ANALYTICS_MODULE.playlist_details,
    },
  });

  if (!studioClass) {
    return null;
  }

  const classNumber = studioClass.classId;
  const classId = studioClass.id;

  return (
    <DropdownMenuWrapper>
      {IS_MOBILE ? (
        <MobilePreviewClassOption classData={studioClass} />
      ) : (
        <PreviewStudioClassOption
          studioClass={studioClass}
          onUserSelectsClass={onUserSelectsClass}
        />
      )}
      <SaveStudioClassOption studioClass={studioClass} />
      <AddClassToPlaylistOption classNumber={classNumber} classId={classId} />
      {playlist?.owner?.isMe && (
        <DropdownOption
          Svg={Trash}
          onClick={() => {
            playlistRemoveStudioClass();
          }}
          text="Remove Class"
        />
      )}
      <AddClassToScheduleOption classNumber={classNumber} />
      <ShareStudioClassOption studioClass={studioClass} />
      <DropdownLineSeparator />
      <GoToClassStyleOption styleName={studioClass.styleV2?.name} />
      <GoToClassLevelOption classLevel={studioClass.level} />
      <GoToInstructorOption instructorSlug={studioClass.instructor?.slug} />
    </DropdownMenuWrapper>
  );
};
