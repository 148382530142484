import React from "react";
import Flex from "app/components/Flex";
import Icon, { VisibleOff } from "app/components/Icon";

interface Props {
  children?: React.ReactNode;
  iconWidth?: string;
  iconHeight?: string;
  style?: any;
}

export const ReportOverlay = ({
  children = null,
  iconWidth = null,
  iconHeight = null,
  style,
}: Props) => (
  <Flex
    position="absolute"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="100%"
    bg="rgba(35, 35, 35, 0.7)"
    backdropFilter="blur(30px)"
    style={style}
  >
    <Icon as={VisibleOff} width={iconWidth} height={iconHeight} />
    {children}
  </Flex>
);
