import { cloneDeep } from "lodash";
import { useGetVideoUploadCommentsQuery } from "services/graphql";

const PER_PAGE = 10;

export default variables => {
  const { data = {}, fetchMore } = useGetVideoUploadCommentsQuery({
    variables: {
      ...variables,
      first: PER_PAGE,
    },
  });

  const videoUpload = data?.videoUploadByIdPublic;

  if (!videoUpload || !videoUpload.comments) {
    return [[], false, () => {}];
  }

  const hasMore = videoUpload.comments.pageInfo?.hasNextPage;
  const fetchNextPage = () => {
    const lastComment =
      videoUpload.comments.edges[videoUpload.comments.edges.length - 1];
    return (
      hasMore &&
      fetchMore({
        variables: {
          ...variables,
          after: lastComment.cursor,
          first: PER_PAGE,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }
          const newResult = cloneDeep(previousResult);
          newResult.videoUploadByIdPublic.comments.edges = previousResult.videoUploadByIdPublic.comments.edges.concat(
            fetchMoreResult.videoUploadByIdPublic.comments.edges
          );
          newResult.videoUploadByIdPublic.pageInfo = fetchMoreResult.pageInfo;
          newResult.videoUploadByIdPublic.totalCount =
            fetchMoreResult.totalCount;
          return newResult;
        },
      })
    );
  };
  return [videoUpload.comments.edges.map(c => c.node), hasMore, fetchNextPage];
};
