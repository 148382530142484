import React from "react";
import { CloseModalButton, ModalWithContext } from "app/components/Modal";
import styled from "styled-components";
import Div from "app/components/Div";
import { DesktopClassDetails } from "app/components/ClassComponents/ClassDetails";
import useGetClass from "hooks/useGetClass";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useCanUserTakeClass } from "hooks/Classes";
import Flex from "app/components/Flex";
import { H2, P1 } from "app/components/Typography";
import { PreviewPlayer } from "./PreviewPlayer";

const StyledModal = styled(ModalWithContext)`
  width: 100%;
  max-width: 1180px;
  top: 24px;
  height: calc(100vh - 48px);
  padding: 0;
  margin: 16px;
  background: transparent;
`;

const AccessTypeSpan = styled.span`
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.gold};
`;

interface Props {
  classId: string;
  closeModal(): void;
  goToClass(): void;
}

export function DesktopClassPreviewModal({
  classId,
  closeModal,
  goToClass,
}: Props) {
  const [getClassData, isClassLoading] = useGetClass({
    variables: {
      classId,
    },
  });
  const classData = getClassData?.current || {};
  const { canUserTakeClass } = useCanUserTakeClass({ classId: classData?.id });

  const closeModalAndGoToClass = () => {
    /*
      This is to ensure modal closes properly to cover cases
      where the preview modal is still persisted on the class page
    */
    goToClass();
    closeModal();
  };

  return (
    <StyledModal
      isOpen
      onBackgroundClick={closeModal}
      onEscapeKeydown={closeModal}
    >
      {isClassLoading && <LoaderCentered />}
      {!isClassLoading && (
        <>
          <Div
            position="relative"
            overflow="hidden"
            borderRadius="24px 24px 0 0"
          >
            <PreviewPlayer
              goToClass={closeModalAndGoToClass}
              classData={classData}
            />
          </Div>
          <Div position="absolute" right="16px" top="16px">
            <CloseModalButton onClick={closeModal} />
          </Div>
          <Div bg="white">
            {!canUserTakeClass && (
              <Div p="16px">
                <Flex
                  p="12px"
                  borderRadius="8px"
                  bg="black"
                  color="white"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="12px"
                  flexWrap="wrap"
                >
                  <P1 color="gold" fontWeight="bold">
                    Unlock this class and more by upgrading your membership.
                  </P1>
                </Flex>
              </Div>
            )}
            <DesktopClassDetails classData={classData} />
          </Div>
        </>
      )}
    </StyledModal>
  );
}
