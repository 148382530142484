import React from "react";
import Icon, { KebabMenu } from "app/components/Icon";
import Tooltip from "app/components/Tooltip";
import styled from "styled-components";

const StyledButton = styled.button<{ ref: any }>`
  padding: 0;
  border: 0;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 4px;
  color: ${({ theme }) => theme.colors.monochrome[7]};

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const MenuButton = React.forwardRef(
  ({ iconColor = "monochrome.6", ...rest }: { iconColor?: string }, ref) => (
    <StyledButton ref={ref} {...rest}>
      <Tooltip overlay="More options">
        <Icon as={KebabMenu} width="20px" height="20px" color={iconColor} />
      </Tooltip>
    </StyledButton>
  )
);
