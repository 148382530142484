import { gql, useMutation } from "@apollo/client";
import { TOGGLE_SAVE_CLASS_MUTATION } from "graphql/mutations";
import { contentToggleSaveClass } from "services/typewriter/segment";

export default () => {
  const [toggleSaveProgramClassMutation] = useMutation(
    TOGGLE_SAVE_CLASS_MUTATION
  );

  return async ({ variables, component, classData }) => {
    const { classId, willSave, programClassRefId } = variables;
    const mutationResults = await toggleSaveProgramClassMutation({
      variables: {
        classId: String(classId),
        willSave,
        programClassRefId,
      },
      optimisticResponse: {
        toggleSaveClass: {
          id: String(classId),
          refId: programClassRefId,
          isSaved: willSave,
          __typename: "Class",
        },
      },
      update: (cache, { data }) => {
        // Save state for class is unique to ID and not refId
        // Looking through cache to find all classes with the same ID
        // And update the cache save state.
        const cacheKeyFields = Object.keys(cache.data?.data);
        const classRefIds = cacheKeyFields.reduce((arr, keyField) => {
          if (keyField.includes("Class:{")) {
            const splitString = keyField.split("Class:");
            const classObj = JSON.parse(splitString[1]);
            if (classObj.id === data?.toggleSaveClass?.id) {
              return [...arr, classObj.refId];
            }
          }

          return arr;
        }, []);
        classRefIds.forEach(refId => {
          const key = JSON.stringify({
            id: data?.toggleSaveClass?.id,
            refId,
          });
          cache.writeFragment({
            id: `Class:${key}`,
            fragment: gql`
              fragment saveProgramV2Class on Class {
                isSaved
              }
            `,
            data: {
              isSaved: data?.toggleSaveClass?.isSaved,
            },
          });
        });
      },
    });

    contentToggleSaveClass({
      saved: willSave,
      component,
      classId,
      programClassRefId,
      instructor: classData.instructor_name || classData.instructor?.name,
      level: classData.level,
      style: classData.style,
      title: classData.title,
      categories: classData.categories,
    });

    return mutationResults;
  };
};
