import React, { useState } from "react";
import Tooltip from "app/components/Tooltip";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import {
  CloseCircle,
  ContentCopy,
  FAFacebook,
  TwitterWithCircle,
  Reddit,
  Pinterest,
} from "app/components/Icon";
import env from "helpers/env";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal, Img, Icon, SocialWrapper, SocialText } from "./styles";

interface Props {
  classNumber: number;
  classThumbnailUrl: string;
  isOpen: boolean;
  onCancel(): void;
  shareMessage: string;
}

export function ShareModal({
  classThumbnailUrl,
  classNumber,
  isOpen,
  onCancel,
  shareMessage,
}: Props) {
  const [copyLinkMessage, setCopyLinkMessage] = useState("Copy Link");

  const classPreviewUrl = `${env(
    "PUBLIC_PARKER_URL"
  )}/class/preview/${classNumber}`;
  return (
    <Modal isOpen={isOpen} onBackgroundClick={() => onCancel()}>
      <Div top="16px" right="16px" position="absolute" zIndex="1">
        <Icon
          as={CloseCircle}
          width="32px"
          height="32px"
          cursor="pointer"
          onClick={() => onCancel()}
        />
      </Div>
      <Flex flexDirection={{ _: "column", sm: "row  " }}>
        <Flex flexBasis="50%">
          <Flex
            width="100%"
            position="relative"
            overflow="hidden"
            justifyContent="center"
            borderRadius="16px"
            pt="100%"
          >
            <Img src={classThumbnailUrl} />
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          flexBasis="50%"
          flexDirection="column"
          p="24px"
          gap="8px"
        >
          <SocialWrapper
            onClick={() =>
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${classPreviewUrl}`,
                "blank"
              )
            }
          >
            <Icon as={FAFacebook} color="#1877F1" />
            <SocialText>Share on Facebook</SocialText>
          </SocialWrapper>
          <SocialWrapper
            onClick={() =>
              window.open(
                `https://twitter.com/intent/tweet?text=${shareMessage}&url=${classPreviewUrl}`,
                "blank"
              )
            }
          >
            <Icon as={TwitterWithCircle} color="#00acee" />
            <SocialText>Share on Twitter</SocialText>
          </SocialWrapper>
          <SocialWrapper
            onClick={() =>
              window.open(
                `http://www.reddit.com/submit?title=${shareMessage}&url=${classPreviewUrl}`,
                "blank"
              )
            }
          >
            <Icon as={Reddit} color="#FF4500" />
            <SocialText>Share on Reddit</SocialText>
          </SocialWrapper>
          <SocialWrapper
            onClick={() =>
              window.open(
                `https://pinterest.com/pin-builder/?url=${classPreviewUrl}&media=${classThumbnailUrl}&description=${shareMessage}`,
                "blank"
              )
            }
          >
            <Icon as={Pinterest} color="#E60023" />
            <SocialText>Share on Pinterest</SocialText>
          </SocialWrapper>
          <CopyToClipboard
            text={`${classPreviewUrl}`}
            onCopy={() => setCopyLinkMessage("Link Copied!")}
          >
            <Tooltip overlay={copyLinkMessage}>
              <SocialWrapper
                onMouseLeave={() => setCopyLinkMessage("Copy Link")}
              >
                <Icon as={ContentCopy} color="black" />
                <SocialText>Copy link to clipboard</SocialText>
              </SocialWrapper>
            </Tooltip>
          </CopyToClipboard>
        </Flex>
      </Flex>
    </Modal>
  );
}
