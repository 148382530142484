import Flex from "app/components/Flex";
import Icon from "app/components/Icon";
import { P1 } from "app/components/Typography";
import styled from "styled-components";
import media from "styled-media-query";

export const CreateCommentContainer = styled(Flex)`
  position: relative;
  width: 100%;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;

  ${media.greaterThan("large")`
    align-items: flex-end;
    border-top: 1px solid ${({ theme }) => theme.colors.monochrome[1]};
    padding-top: 10px;
    `}
`;

export const CommentInput = styled.textarea`
  border: none;
  resize: none;
  height: 30px;
  min-height: 30px;
  border-radius: 4px;
  background-color: rgba(235, 239, 239, 0.7);
  padding: 0 10px;
  padding-top: 5px;
  width: 100%;
  flex: 1;
  margin: 0 12px;
  font-size: 14px;
`;

export const PostLink = styled(P1)`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 5px;
  display: none;

  ${media.greaterThan("large")`
    display: block;
  `}
`;

export const PostIcon = styled(Icon)`
  ${media.greaterThan("large")`
    display: none;
  `}
`;
