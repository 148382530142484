import React from "react";
import { P, H2 } from "app/components/Typography";
import Flex from "app/components/Flex";
import Button from "app/components/Button";
import {
  useDeletePlaylistMutation,
  GetMyPlaylistsDocument,
} from "services/graphql";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { playlistsEditPlaylist } from "services/typewriter/segment";
import {
  ANALYTICS_ACTION,
  ANALYTICS_LOCATION,
  ANALYTICS_MODULE,
} from "constants/analytics";
import { Routes } from "constants/routes";
import { Modal, CloseModalButton } from "./styles";
import { PartialPlaylistV2 } from "../types";

interface Props {
  closeModal(): void;
  playlist: PartialPlaylistV2;
}

export function DeletePlaylistModal({ closeModal, playlist }: Props) {
  const history = useHistory();
  const [deletePlaylistMutation] = useDeletePlaylistMutation({
    onCompleted: () => {
      playlistsEditPlaylist({
        action: ANALYTICS_ACTION.playlistsDelete,
        playlist_id: playlist.id,
        playlist_name: playlist.name,
        location: ANALYTICS_LOCATION.playlists,
        module: ANALYTICS_MODULE.playlist_details,
      });
      history.push(Routes.playlists__created);
      toast.success(`Delete ${playlist.name}`);
      closeModal();
    },
    refetchQueries: [
      {
        query: GetMyPlaylistsDocument,
        variables: { pagination: { first: 10 }, studioClassIds: [] },
      },
    ],
  });
  const deletePlaylist = () => {
    deletePlaylistMutation({
      variables: {
        input: {
          playlistId: playlist.id,
        },
      },
    });
  };

  return (
    <Modal isOpen onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <H2>Delete Playlist</H2>
        <CloseModalButton onClick={closeModal} variant="light" />
      </Flex>
      <Flex flexDirection="column" width="100%">
        <P my="16px">
          Are you sure you want to delete {playlist.name}?
          <br />
          <br />
          Note: Deleting this playlist is a permanent action and can not be
          undone.
        </P>
        <Button
          color="blue"
          bg="monochrome.1"
          hbg="monochrome.2"
          onClick={deletePlaylist}
        >
          Delete
        </Button>
      </Flex>
    </Modal>
  );
}
