import styled from "styled-components";
import media from "styled-media-query";
import Modal from "app/components/Modal";

export const StyledModal = styled(Modal)`
  height: 90vh;
  width: 100%;
  padding: 0;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  max-height: 620px;

  ${media.greaterThan("small")`
    height: 80vh;
  `}

  ${media.greaterThan("medium")`
    max-width: 760px;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.monochrome[1]};

  svg rect {
    height: 2px;
  }
`;

export const UploadUserVideoContentWrapper = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const TagClassWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 12px;

  &:hover {
    background: ${({ theme }) => theme.colors.monochrome[1]};
  }
`;
