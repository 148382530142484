import {
  useGetMyPlaylistsQuery,
  usePlaylistRemoveStudioClassMutation,
  usePlaylistAddStudioClassMutation,
  useGetPersonalizedPlaylistQuery,
  useGetPlaylistLazyQuery,
} from "services/graphql";
import { toast } from "react-toastify";
import { playlistsEditPlaylist } from "services/typewriter/segment";
import { ANALYTICS_ACTION } from "constants/analytics";
import { getAnalyticsLocationFromPath } from "helpers/analytics";
import { useLocation } from "react-router";
import { useEffect } from "react";

export function useGetMyPersonalizedPlaylist() {
  const { data, loading, error } = useGetPersonalizedPlaylistQuery();

  if (!data || error) {
    return {
      loadingPersonalizedPlaylist: loading,
    };
  }

  const { myPersonalizedPlaylist } = data;
  const personalizedPlaylist = myPersonalizedPlaylist?.personalizedPlaylist;

  return {
    loadingPersonalizedPlaylist: loading,
    personalizedPlaylist,
  };
}

export function useGetMyPlaylists({
  studioClassIds = [],
}: {
  studioClassIds?: string[];
}) {
  const perPage = 10;

  const {
    data,
    fetchMore: fetchMoreData,
    loading: loadingMyPlaylists,
    error: errorMyPlaylists,
  } = useGetMyPlaylistsQuery({
    variables: {
      studioClassIds,
      pagination: {
        first: perPage,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageInfoMyPlaylists = data?.me?.myPlaylists?.pageInfo;
  const myPlaylists = data?.me?.myPlaylists?.edges.map(edge => edge.node);

  const fetchMoreMyPlaylists = () => {
    fetchMoreData({
      variables: {
        pagination: {
          first: myPlaylists?.length,
          after: pageInfoMyPlaylists?.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousEntry = previousResult.me?.myPlaylists;
        const newEdges = fetchMoreResult.me?.myPlaylists.edges;

        return {
          me: {
            ...previousResult.me,
            myPlaylists: {
              ...fetchMoreResult.me?.myPlaylists,
              edges: [...previousEntry.edges, ...newEdges],
            },
          },
        };
      },
    });
  };

  return {
    isFetchingInitialMyPlaylists: !myPlaylists && loadingMyPlaylists,
    loadingMyPlaylists,
    errorMyPlaylists,
    myPlaylists,
    pageInfoMyPlaylists,
    fetchMoreMyPlaylists,
  };
}

export function useGetPlaylist({
  playlistId,
  studioClassIds = [],
}: {
  playlistId: string;
  studioClassIds?: string[];
}) {
  const perPage = 10;
  const [
    callGetPlaylistsQuery,
    { data, loading: loadingPlaylist, refetch: refetchPlaylist, called },
  ] = useGetPlaylistLazyQuery({
    variables: {
      playlistId,
      studioClassIds,
      pagination: {
        first: perPage,
      },
    },
  });

  useEffect(() => {
    if (!playlistId) {
      return;
    }

    if (loadingPlaylist) {
      return;
    }

    callGetPlaylistsQuery();
  }, [playlistId]);

  if (!called) {
    return { loadingPlaylist: true };
  }

  const { playlist } = data || {};

  return {
    loadingPlaylist,
    refetchPlaylist,
    playlist,
  };
}

export function useGetPlaylistStudioClasses({
  playlistId,
  studioClassIds = [],
}: {
  playlistId: string;
  studioClassIds?: string[];
}) {
  const perPage = 10;
  const [
    callGetPlaylistsQuery,
    { data, loading: loadingPlaylistStudioClasses, fetchMore },
  ] = useGetPlaylistLazyQuery({
    variables: {
      playlistId,
      studioClassIds,
      pagination: {
        first: perPage,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (loadingPlaylistStudioClasses) {
      return;
    }

    if (!playlistId) {
      return;
    }

    callGetPlaylistsQuery();
  }, [playlistId]);

  if (!data) {
    return {};
  }

  const { playlist } = data || {};
  const studioClasses = playlist?.studioClasses?.edges.map(
    studioClassEdge => studioClassEdge.node
  );
  const pageInfoPlaylistStudioClasses = playlist?.studioClasses?.pageInfo;

  const fetchMorePlaylistStudioClasses = async () => {
    await fetchMore({
      variables: {
        pagination: {
          first: studioClasses?.length + perPage,
        },
      },
    });
  };

  return {
    studioClasses,
    loadingPlaylistStudioClasses,
    pageInfoPlaylistStudioClasses,
    fetchMorePlaylistStudioClasses,
  };
}

export function usePlaylistRemoveStudioClass({
  studioClassId,
  playlistId,
  onCompleted,
  analyticsData,
  ...options
}: {
  studioClassId: string;
  playlistId: string;
  onCompleted?(): void;
  analyticsData: {
    module: string;
    class_id: string;
  };
}) {
  const location = useLocation();
  const { playlist } = useGetPlaylist({ playlistId });
  const [playlistRemoveStudioClass] = usePlaylistRemoveStudioClassMutation({
    variables: {
      input: {
        playlistId,
        studioClassId,
      },
    },
    update(cache) {
      cache.modify({
        id: `PlaylistV2:${playlistId}`,
        fields: {
          studioClasses(existing, { readField }) {
            const { edges: existingEdges = [] } = existing;

            const edges = existingEdges.filter(
              (edge: any) => readField("id", edge.node) !== studioClassId
            );

            return {
              ...existing,
              edges,
            };
          },
        },
      });
    },
    onCompleted() {
      playlistsEditPlaylist({
        action: ANALYTICS_ACTION.playlistRemoveClass,
        playlist_id: playlistId,
        location: getAnalyticsLocationFromPath(location.pathname),
        ...analyticsData,
      });
      toast.success(`Removed from ${playlist.name}`);
      if (onCompleted) {
        onCompleted();
      }
    },
    ...options,
  });

  return {
    playlistRemoveStudioClass,
  };
}

export function usePlaylistAddStudioClass({
  studioClassId,
  playlistId,
  onCompleted,
  analyticsData,
  ...options
}: {
  studioClassId: string;
  playlistId: string;
  onCompleted?(): void;
  analyticsData: {
    module: string;
    class_id: string;
  };
}) {
  const location = useLocation();
  const { playlist, refetchPlaylist } = useGetPlaylist({ playlistId });
  const [playlistAddStudioClass] = usePlaylistAddStudioClassMutation({
    variables: {
      input: {
        playlistId,
        studioClassId,
      },
    },
    onCompleted() {
      playlistsEditPlaylist({
        action: ANALYTICS_ACTION.playlistsAddClass,
        playlist_id: playlistId,
        location: getAnalyticsLocationFromPath(location.pathname),
        ...analyticsData,
      });
      toast.success(`Added to ${playlist.name}`);
      refetchPlaylist();
      if (onCompleted) {
        onCompleted();
      }
    },
    ...options,
  });

  return {
    playlistAddStudioClass,
  };
}
