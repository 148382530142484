import React from "react";
import styled from "styled-components";
import Flex from "app/components/Flex";
import Icon, { Facebook, Google } from "../Icon";
import Button from "../Button/index";

const IconWrapper = styled(Flex)`
  position: absolute;
  left: 8px;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  padding: 5px;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 12px;
  border-radius: 50px;
`;

const ButtonText = styled.p`
  font-size: 12px;
  letter-spacing: 0.04em;
  font-weight: bold;
  line-height: 128%;
  text-transform: none;
`;

interface Props {
  onClick(): void;
}

export const GoogleSignUpButton = ({ onClick }: Props) => (
  <StyledButton mb={3} variant="google" onClick={onClick}>
    <IconWrapper>
      <Icon height="100%" as={Google} color="google" />
    </IconWrapper>
    <ButtonText>Continue with Google</ButtonText>
  </StyledButton>
);

export const FacebookSignUpButton = ({ onClick }: Props) => (
  <StyledButton variant="facebook" onClick={onClick}>
    <IconWrapper>
      <Icon height="100%" color="facebook" as={Facebook} />
    </IconWrapper>
    <ButtonText>Continue with Facebook</ButtonText>
  </StyledButton>
);
