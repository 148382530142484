import React from "react";
import styled from "styled-components";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { H3, P1 } from "app/components/Typography";
import { ClassesInactive } from "app/components/Icon";
import { Link } from "react-router-dom";

const StyledIcon = styled.i`
  color: ${({ theme }) => theme.colors.monochrome[3]};
`;

export function EmptyStateList() {
  return (
    <Flex
      mt={{ _: 3, md: 6 }}
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexDirection="column" alignItems="center" textAlign="center">
        <Div mb={4}>
          <StyledIcon as={ClassesInactive} width="52.5px" height="35px" />
        </Div>
        <H3 fontWeight="bold" color="monochrome.6" mb={2}>
          Find classes to tag to this video
        </H3>
        <P1 color="monochrome.5" textTransform="none">
          Search here or <Link to="/library/classes">browse the library</Link>{" "}
          for inspiration!
        </P1>
      </Flex>
    </Flex>
  );
}
