import gqlCache from "config/gql-cache";
import { CHALLENGE_FIELDS } from "graphql/challenges/fragments";
import {
  ActivityCommentFieldsFragmentDoc,
  CoreVideoUploadsPublishedFieldsFragmentDoc,
} from "services/graphql";

const readActivityTypeFragment = (contentType, contentId) => {
  return gqlCache.readFragment({
    id: gqlCache.identify({
      id: contentId,
      __typename: activityTypeToFragmentName(contentType),
    }),
    fragment: activityTypeToFragment(contentType),
  });
};

const writeActivityTypeFragment = (contentType, newData) => {
  return gqlCache.writeFragment({
    id: gqlCache.identify(newData),
    fragment: activityTypeToFragment(contentType),
    data: newData,
  });
};

const activityTypeToFragmentName = contentType => {
  switch (contentType) {
    case "challenge":
      return "Challenge";
    case "user_comment":
      return "ActivityComment";
    case "user_video":
    default:
      return "VideoUpload";
  }
};

const activityTypeToFragment = contentType => {
  switch (contentType) {
    case "challenge":
      return CHALLENGE_FIELDS;
    case "user_comment":
      return ActivityCommentFieldsFragmentDoc;
    case "user_video":
    default:
      return CoreVideoUploadsPublishedFieldsFragmentDoc;
  }
};

export {
  activityTypeToFragmentName,
  readActivityTypeFragment,
  writeActivityTypeFragment,
};
