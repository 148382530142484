import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { color as colorSystem, layout, system } from "styled-system";

const Wrapper = styled.div.attrs(({ bg, height }) => ({
  height: height || "20px",
  bg: bg || "darkGrey",
}))`
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  ${layout}
  ${colorSystem}
  ${system({
    opacity: true,
  })}
`;

const Progress = styled.div`
  float: left;
  height: 100%;
  background-color: ${({ color, theme }) =>
    theme.colors[color] || color || theme.colors.primary[0]};
  transition: width 0.6s ease;
  ${layout}
`;

const ProgressBar = ({ className, color, percent, ...rest }) => (
  <Wrapper className={className} {...rest}>
    <Progress color={color} width={`${percent || 0}%`} />
  </Wrapper>
);

ProgressBar.defaultProps = {
  color: null,
  className: "",
  percent: 0,
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  percent: PropTypes.number,
};

export default ProgressBar;
