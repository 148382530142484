import React from "react";
import Flex from "app/components/Flex";
import { H3 } from "app/components/Typography";
import Icon, { Comments } from "app/components/Icon";

export default () => (
  <Flex
    alignItems="center"
    justifyContent="center"
    height="100%"
    flexDirection="column"
    mt={4}
    mb={4}
  >
    <Icon color="monochrome.9" as={Comments} height="35px" width="38px" />
    <H3 color="monochrome.9" mt="23px">
      Seems quiet here.
    </H3>
    <H3 color="monochrome.9">Be the first to comment.</H3>
  </Flex>
);
