import React from "react";
import Icon, { KebabMenu } from "app/components/Icon";
import Tooltip from "app/components/Tooltip";
import { StyledButton } from "./styles";

interface Props {
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export function MoreIconWithTextButton({ onClick }: Props) {
  return (
    <Tooltip overlay="More options">
      <StyledButton
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          // Stop propagation since we handle clicks on Wrapper
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();

          onClick(e);
        }}
      >
        <Icon
          as={KebabMenu}
          width="20px"
          height="20px"
          transform="rotate(90deg)"
        />
        More
      </StyledButton>
    </Tooltip>
  );
}
