import { ANALYTICS_MODULE } from "constants/index";
import React from "react";
import Modal, { CloseModalButton } from "app/components/Modal";
import Div from "app/components/Div";
import { useVideoUploadByIdPublicQuery } from "services/graphql";
import { UserVideoPost } from "./UserVideoPost";

interface Props {
  userVideoId: string;
  closeModal(): void;
  fromModule: string;
}

export function UserVideoModal({
  userVideoId,
  closeModal,
  fromModule = ANALYTICS_MODULE.user_video_modal,
}: Props) {
  const {
    data: videoUploadData,
    loading,
    error,
  } = useVideoUploadByIdPublicQuery({
    variables: {
      videoId: userVideoId,
    },
  });

  const userVideoData = videoUploadData?.videoUploadByIdPublic;

  if (error) {
    // @TODO: Show error page
    return <></>;
  }

  if (loading || !userVideoData) {
    // @TODO: Show empty state
    return <></>;
  }

  return (
    <Modal
      isOpen
      onBackgroundClick={closeModal}
      onEscapeKeydown={closeModal}
      background="none"
      width={{ _: "100%", lg: "auto" }}
      height={{ _: "100%", lg: "auto" }}
      p={0}
    >
      <UserVideoPost fromModule={fromModule} userVideoData={userVideoData} />
      <Div position="absolute" right="16px" top="16px">
        <CloseModalButton onClick={closeModal} />
      </Div>
    </Modal>
  );
}
