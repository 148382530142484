import React from "react";
import {
  MobileClassPreviewModal,
  DesktopClassPreviewModal,
} from "app/components/ClassComponents/ClassPreviewModal";
import {
  IS_MOBILE,
  ANALYTICS_LOCATION,
  ANALYTICS_MODULE,
} from "constants/index";
import { useGetClassByIdForPreviewModalQuery } from "services/graphql";
import { NotFound } from "app/components/NotFound";
import { useContentSelectClassEvent } from "hooks/analytics";
import { useHistory } from "react-router";
import LoaderOverlay from "app/components/Loader/LoaderOverlay";

interface Props {
  classNumber: number;
  closeModal(): void;
}

export function ClassPreviewModal({ classNumber, closeModal }: Props) {
  const { data, loading, error } = useGetClassByIdForPreviewModalQuery({
    variables: {
      classId: classNumber.toString(),
    },
  });

  if (!data || loading) {
    return <LoaderOverlay />;
  }

  if (error) {
    return <NotFound />;
  }

  const classData = data.classById;

  return <PreviewModalWithData classData={classData} closeModal={closeModal} />;
}

function PreviewModalWithData({
  classData,
  closeModal,
}: {
  classData: any;
  closeModal(): void;
}) {
  const history = useHistory();
  const { contentSelectClassEvent } = useContentSelectClassEvent({
    classData,
  });
  const goToClass = () => {
    contentSelectClassEvent({
      location: ANALYTICS_LOCATION.community,
      selected_from: ANALYTICS_MODULE.community_feed,
    });

    history.push({
      pathname: `/class/${classData.id}`,
    });
  };

  if (IS_MOBILE) {
    return (
      <MobileClassPreviewModal classData={classData} closeModal={closeModal} />
    );
  }

  return (
    <DesktopClassPreviewModal
      classId={classData.id?.toString()}
      closeModal={closeModal}
      goToClass={goToClass}
    />
  );
}
