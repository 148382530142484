import styled from "styled-components";
import Modal from "app/components/Modal";
import { smallCtaStyle } from "app/components/Typography";

export const DeleteCommentModalContainer = styled(Modal)`
  width: 295px;
  padding: 24px 0 0 0;
  border-radius: 8px;
  text-align: center;
`;

export const DeleteButtonOption = styled.div`
  ${smallCtaStyle}
  cursor: pointer;
  font-weight: ${props => props.fontWeight};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.monochrome[1]};
`;
