import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 16px;
  letter-spacing: 1px;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    padding: 8px;
  }
`;

export const ClassTitle = styled.h1`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 12px;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    font-size: 24px;
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 64px;
  border-radius: 50%;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    width: 42px;
  }
`;

export const TracksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 10px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  :hover {
    opacity: 0.8;
    text-decoration: none;
  }
`;

export const InstructorName = styled.a`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.monochrome[9]};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blue};
`;

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blue};

  :hover {
    opacity: 0.8;
    text-decoration: none;
  }
`;
