import React from "react";
import {
  useActivityReactionCreate,
  useActivityReactionDelete,
} from "hooks/Activities";
import {
  communityContentFlag,
  communityVideoActivity,
  Type,
  CommunityContentFlagContentType,
} from "services/typewriter/segment";
import { ANALYTICS_LOCATION } from "constants/index";
import Flex from "app/components/Flex";
import {
  Play,
  VolumeOn,
  VolumeOff,
  Pause,
  Heart,
  HeartFilled,
  Comments,
} from "app/components/Icon";
import { ContentType, UserReactionType } from "services/graphql";
import { useRedirectNoAccess } from "../hooks";
import { ProgressBar } from "./ProgressBar";
import { BaseButton } from "./BaseButton";
import { Settings } from "./Settings";
import { PartialVideoUpload } from "../types";

interface Props {
  durationOfVideo: any;
  fromModule: string;
  focusCommentInput(): void;
  muted: boolean;
  canLearn?: boolean;
  onClickLearn(): void;
  playerProgressInSeconds: number;
  playing: boolean;
  seekTo(seconds: number): void;
  setMuted(muted: boolean): void;
  setPlaying(playing: boolean): void;
  userVideoData: PartialVideoUpload;
}

export function ChildControls({
  durationOfVideo,
  fromModule,
  focusCommentInput,
  muted,
  canLearn = false,
  onClickLearn,
  playerProgressInSeconds,
  playing,
  seekTo,
  setMuted,
  setPlaying,
  userVideoData,
}: Props) {
  const redirectNoAccess = useRedirectNoAccess();
  const learnButtonCopy = "Learn";
  const didLike = userVideoData.userReactions.includes(UserReactionType.Like);
  const { id: videoId, isAuthoredByMe, comments } = userVideoData;

  const activityReactionCreate = useActivityReactionCreate();
  const activityReactionDelete = useActivityReactionDelete();

  const [{ totalCount: likesCount = 0 } = {}] = userVideoData.reactions.filter(
    ({ reactionType }) => reactionType === UserReactionType.Like
  );

  const onLike = async () => {
    const createOrDeleteFn = didLike
      ? activityReactionDelete
      : activityReactionCreate;

    await createOrDeleteFn({
      activityId: userVideoData.id,
      activityType: ContentType.UserVideo,
      reactionType: UserReactionType.Like,
    });

    return !didLike;
  };

  const onClickLike = async (e: any) => {
    e.stopPropagation();

    if (redirectNoAccess()) {
      return;
    }

    const likeStatus = await onLike();
    communityVideoActivity({
      video_id: videoId,
      to_user_id: userVideoData?.uploadedBy?.uid,
      type: likeStatus ? Type.Like : Type.Unlike,
    });
  };

  const onClickLearnButton = (e: any) => {
    e.stopPropagation();
    if (!canLearn) {
      return;
    }

    onClickLearn();
  };

  const onClickReport = (reportReason: string) => {
    communityContentFlag({
      element_name: "Community Feed - Report Post",
      location: ANALYTICS_LOCATION.community,
      module: fromModule,
      content_id: videoId,
      content_user_id: userVideoData?.uploadedBy?.uid,
      content_type: CommunityContentFlagContentType.UserVideo,
      reason: reportReason,
    });
  };

  const onClickComments = () => {
    focusCommentInput();
  };

  return (
    <>
      <Flex position="absolute" bottom="60px" right="0">
        <Flex justifyContent="flex-end">
          <Flex flexDirection="column" mr={2}>
            <BaseButton
              show={canLearn}
              Icon={Play}
              text={learnButtonCopy}
              onClick={onClickLearnButton}
            />
            <BaseButton
              show
              Icon={didLike ? HeartFilled : Heart}
              text={likesCount.toString()}
              onClick={onClickLike}
            />
            <BaseButton
              show
              Icon={Comments}
              text={comments?.totalCount?.toString()}
              onClick={onClickComments}
            />
            <Settings
              videoId={videoId}
              isAuthoredByMe={isAuthoredByMe}
              onClickReport={onClickReport}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        position="absolute"
        width="100%"
        bottom={0}
        backgroundImage="linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, #222 100%)"
        transition="bottom 0.25s ease-in-out"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Flex width="100%" mb={3} justifyContent="center" alignItems="center">
          <BaseButton
            show
            Icon={playing ? Pause : Play}
            onClick={(e: any) => {
              e.stopPropagation();
              setPlaying(!playing);
            }}
            iconWidth={14}
            ml={2}
            mb={1}
          />
          <Flex width="100%" mb={2}>
            <ProgressBar
              currentTimeInSeconds={playerProgressInSeconds}
              onAfterChange={seekTo}
              max={durationOfVideo}
              totalDuration={durationOfVideo}
            />
          </Flex>
          <BaseButton
            show
            Icon={muted ? VolumeOff : VolumeOn}
            onClick={(e: any) => {
              e.stopPropagation();
              setMuted(!muted);
            }}
            mb={1}
            mr={2}
          />
        </Flex>
      </Flex>
    </>
  );
}
