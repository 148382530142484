import { H3, P1 } from "app/components/Typography";
import { IS_MOBILE } from "constants/index";
import { useActivityCommentDelete } from "hooks/Comments";
import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import { DeleteButtonOption, DeleteCommentModalContainer } from "./styles";

const DeleteCommentModal = ({
  commentId,
  activityId,
  activityType,
  isDeleteModalOpen,
  onToggleDeleteModal,
  isFirstTierComment,
}) => {
  const activityCommentDelete = useActivityCommentDelete();
  const handleClickDelete = async () => {
    onToggleDeleteModal();
    await activityCommentDelete({
      commentId,
      activityId,
      activityType,
      isFirstTierComment,
    });
    toast.success(`${isFirstTierComment ? "Comment" : "Reply"} deleted.`);
  };

  const deleteText = isFirstTierComment ? "Delete comment" : "Delete reply";
  const commentOrReplyText = isFirstTierComment ? "comment" : "reply";
  return (
    <DeleteCommentModalContainer
      isOpen={isDeleteModalOpen}
      onBackgroundClick={onToggleDeleteModal}
      onEscapeKeydown={onToggleDeleteModal}
    >
      <H3>{deleteText}?</H3>
      <P1 margin="15px 12px 40px 12px">
        {IS_MOBILE ? "Tapping" : "Clicking"} “{deleteText}” will permanently
        delete your {commentOrReplyText}. It can’t be restored later!
      </P1>
      <DeleteButtonOption color="redMercedes.3" onClick={handleClickDelete}>
        {isFirstTierComment ? "DELETE COMMENT" : "DELETE REPLY"}
      </DeleteButtonOption>
      <DeleteButtonOption fontWeight="normal" onClick={onToggleDeleteModal}>
        CANCEL
      </DeleteButtonOption>
    </DeleteCommentModalContainer>
  );
};

DeleteCommentModal.propTypes = {
  commentId: PropTypes.string.isRequired,
  activityId: PropTypes.string.isRequired,
  activityType: PropTypes.oneOf(["user_video", "user_comment", "challenge"])
    .isRequired,
  isDeleteModalOpen: PropTypes.bool,
  onToggleDeleteModal: PropTypes.func,
  isFirstTierComment: PropTypes.bool,
};

export default DeleteCommentModal;
