import React from "react";
import Flex from "app/components/Flex";
import { formatDuration } from "helpers/formatDuration";
import {
  ClassInfoContainer,
  ClassInfoWrapper,
  StyledLink,
  Time,
  Value,
} from "./styles";
import { Class } from "services/graphql";

interface Props {
  classData: Class;
}

export function ClassInfo({ classData }: Props) {
  return (
    <Flex justifyContent="space-between">
      <ClassInfoContainer>
        {classData.style && (
          <>
            <ClassInfoWrapper>
              <StyledLink
                to={`/results/classes?refinementList[style][0]=${classData.style}`}
              >
                <Value>{classData.style}</Value>
              </StyledLink>
            </ClassInfoWrapper>
            &bull;
          </>
        )}
        {classData.level && (
          <>
            <ClassInfoWrapper>
              <StyledLink
                to={`/results/classes?refinementList[level][0]=${classData.level}`}
              >
                <Value>{classData.level}</Value>
              </StyledLink>
            </ClassInfoWrapper>
            &bull;
          </>
        )}

        {classData.duration && (
          <ClassInfoWrapper>
            <Time>{formatDuration(classData.duration)}</Time>
          </ClassInfoWrapper>
        )}
      </ClassInfoContainer>
    </Flex>
  );
}
