import { useQuery } from "@apollo/client";
import { GET_CLASS } from "graphql/queries";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGetUserCredentials } from "../modules/selectors";

export default ({ variables, options = {}, notFoundRedirectPath = null }) => {
  const { classId, programClassRefId } = variables;
  const withProgramData = !!programClassRefId;
  const { uid } = useGetUserCredentials();
  const isSignedIn = !!uid;
  const history = useHistory();
  const { data, loading, error } = useQuery(GET_CLASS, {
    variables: { classId, programClassRefId, withProgramData, isSignedIn },
    ...options,
  });

  const getClassData = data?.getClass;

  useEffect(() => {
    if (!notFoundRedirectPath) {
      return;
    }

    const errorCode = error?.graphQLErrors?.[0]?.extensions?.code;
    if (errorCode === "ENTITY_NOT_FOUND") {
      history.push(notFoundRedirectPath);
    }
  }, [error, history, notFoundRedirectPath]);

  return [getClassData, loading, error];
};
