import React, { useState } from "react";
import { H2, A } from "app/components/Typography";
import Flex from "app/components/Flex";
import { Waypoint } from "app/components/Waypoint";
import styled from "styled-components";
import { ANALYTICS_MODULE } from "constants/analytics";
import { IS_MOBILE } from "constants/index";
import { Modal, CloseModalButton } from "./styles";
import {
  useGetMyPlaylists,
  usePlaylistAddStudioClass,
  usePlaylistRemoveStudioClass,
} from "../../../routes/Playlists/hooks";
import { PartialPlaylistV2 } from "../types";
import { CreatedPlaylistAddClassModal } from "./CreatePlaylistAddClassModal";

interface Props {
  closeModal(): void;
  classNumber: number;
  classId: string;
}

export function AddToPlaylistsModal({
  closeModal,
  classNumber,
  classId,
}: Props) {
  const {
    myPlaylists,
    loadingMyPlaylists,
    fetchMoreMyPlaylists,
    pageInfoMyPlaylists,
  } = useGetMyPlaylists({
    studioClassIds: [classId],
  });
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] = useState(
    false
  );

  if (isCreatePlaylistModalOpen) {
    return (
      <CreatedPlaylistAddClassModal
        closeModal={() => {
          setIsCreatePlaylistModalOpen(false);
        }}
        classId={classId}
        classNumber={classNumber}
      />
    );
  }

  return (
    <Modal isOpen onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <H2>Add to Playlists</H2>
        <CloseModalButton onClick={closeModal} variant="light" />
      </Flex>
      <Flex
        flexDirection="column"
        overflow="scroll"
        maxHeight={IS_MOBILE ? "100%" : "200px"}
        width="100%"
      >
        {myPlaylists &&
          myPlaylists.map(playlist => {
            return (
              <PlaylistOption
                playlist={playlist}
                classId={classId}
                classNumber={classNumber}
              />
            );
          })}
        {!loadingMyPlaylists && (
          <Waypoint
            hasMore={pageInfoMyPlaylists?.hasNextPage}
            fetchData={fetchMoreMyPlaylists}
          />
        )}
      </Flex>
      <A onClick={() => setIsCreatePlaylistModalOpen(true)}>
        + Create a New Playlist
      </A>
    </Modal>
  );
}

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.monochrome[2]};
  border-radius: 2px;
  height: 16px;
  width: 16px;
  margin-right: 12px;
`;

function PlaylistOption({
  playlist,
  classId,
  classNumber,
}: {
  playlist: PartialPlaylistV2;
  classId: string;
  classNumber: number;
}) {
  const analyticsData = {
    class_id: classNumber.toString(),
    module: ANALYTICS_MODULE.playlist_details,
  };
  const { playlistRemoveStudioClass } = usePlaylistRemoveStudioClass({
    playlistId: playlist.id,
    studioClassId: classId,
    analyticsData,
  });
  const { playlistAddStudioClass } = usePlaylistAddStudioClass({
    playlistId: playlist.id,
    studioClassId: classId,
    analyticsData,
  });
  const playlistStudioClass = playlist.hasStudioClasses?.find(
    (hasStudioClass: any) => hasStudioClass.studioClassId === classId
  );
  const isStudioClassInPlaylist = playlistStudioClass?.hasStudioClass;

  const addOrRemoveStudioClass = () => {
    if (isStudioClassInPlaylist) {
      playlistRemoveStudioClass();
    } else {
      playlistAddStudioClass();
    }
  };

  return (
    <Flex alignItems="center">
      <Input
        type="checkbox"
        checked={isStudioClassInPlaylist}
        onClick={() => addOrRemoveStudioClass()}
      />
      {playlist.name}
    </Flex>
  );
}
