import React, { useState } from "react";
import { ShareModal } from "app/components/ClassComponents/ShareModal";
import { Share } from "app/components/Icon";

import { DropdownOption } from "app/components/Dropdown";
import { IS_MOBILE } from "constants/index";
import env from "helpers/env";
import { Class } from "services/graphql";

interface Props {
  classData: Partial<Class>;
}

export const ShareClassOption = ({ classData }: Props) => {
  const [isShareClassModalOpen, setIsShareClassModalOpen] = useState(false);

  if (!classData) {
    return null;
  }

  return (
    <>
      <DropdownOption
        Svg={Share}
        onClick={() => {
          if (IS_MOBILE) {
            navigator.share({
              url: `${env("PUBLIC_PARKER_URL")}/class/preview/${classData.id}`,
              text: `Check out this STEEZY Studio video from ${classData.instructor?.name}`,
              title: classData.title,
            });
          } else {
            setIsShareClassModalOpen(true);
          }
        }}
        text="Share Class"
      />
      <ShareModal
        classNumber={Number(classData.id)}
        classThumbnailUrl={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
          classData.thumbnail
        }-basic.jpg?w=600}`}
        isOpen={isShareClassModalOpen}
        onCancel={() => setIsShareClassModalOpen(false)}
        shareMessage={`Check out this STEEZY Studio video from ${classData.instructor?.name}`}
      />
    </>
  );
};
