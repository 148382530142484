import Div from "app/components/Div";
import useHlsQualityTracker from "hooks/useHlsQualityTracker";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Flex from "app/components/Flex";
import Loader from "app/components/Loader";
import { PlayCircle } from "app/components/Icon";
import useOnScreen from "hooks/useOnScreen";
import { Type, communityVideoActivity } from "services/typewriter/segment";
import { ChildControls } from "./ChildControls";
import { ReactPlayer } from "./styles";
import { PartialVideoUpload } from "../types";

interface Props {
  userVideoData: PartialVideoUpload;
  fromModule: string;
  canLearn?: boolean;
  onClickLearn?(): void;
  playOnLoad?: boolean;
  focusCommentInput?(): void;
}

export function UserVideoPlayer({
  userVideoData,
  fromModule,
  canLearn = false,
  onClickLearn,
  playOnLoad = false,
  focusCommentInput,
}: Props) {
  const trackHlsQuality = useHlsQualityTracker();
  const wrapperRef = useRef(null);
  const isOnScreen = useOnScreen(wrapperRef, "-300px");
  const [playerProgressInSeconds, setPlayerProgressInSeconds] = useState(0);
  const initialPlaying = playOnLoad && !userVideoData.reportedByMe;
  const [playing, setPlaying] = useState(initialPlaying);
  const [muted, setMuted] = useState(false);
  const [durationOfVideo, setDurationOfVideo] = useState(0);
  const [playerInstance, setPlayerInstance] = useState(null);
  const [loading, setLoading] = React.useState(true);
  const [hasPlayed, setHasPlayed] = React.useState(false);

  useEffect(
    function onAppearOnScreen() {
      if (!isOnScreen) {
        setPlaying(false);
      }
    },
    [isOnScreen]
  );

  useEffect(
    function onPlay() {
      if (playing && !hasPlayed) {
        setHasPlayed(true);
        communityVideoActivity({
          type: Type.View,
          video_id: userVideoData.id,
          to_user_id: userVideoData.uploadedBy?.uid,
        });
      }
    },
    [playing, hasPlayed]
  );

  useEffect(
    function scrollToOnPlay() {
      if (playing) {
        wrapperRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    [playing]
  );

  const seekTo = useCallback(
    (seconds: number) => {
      const safeSeconds = Math.min(Math.max(0, seconds), durationOfVideo);
      setPlayerProgressInSeconds(safeSeconds);
      playerInstance.seekTo(safeSeconds);
    },
    [playerInstance, durationOfVideo, setPlayerProgressInSeconds]
  );

  const onClickLearnButton = () => {
    setPlaying(false);
    if (!canLearn) {
      return;
    }
    onClickLearn();
  };

  if (!userVideoData) {
    return <></>;
  }

  const { thumbnailUrl } = userVideoData;

  return (
    <Div
      width="100%"
      height="100%"
      position="relative"
      overflow="hidden"
      ref={wrapperRef}
    >
      <Div pt="56.25%" position="relative" height="100%">
        {loading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
          >
            <Loader />
          </Flex>
        )}
        <ReactPlayer
          url={userVideoData.playbackUrl}
          width="100%"
          height="100%"
          playing={playing}
          playsinline
          loop
          muted={muted}
          onError={(err: any) => {
            if (err && err.name === "NotAllowedError") {
              setMuted(true);
            }
          }}
          onReady={(player: any) => {
            trackHlsQuality(player);
            setLoading(false);
            setPlayerInstance(player);
          }}
          onProgress={(playerProgress: any) => {
            setPlayerProgressInSeconds(playerProgress.playedSeconds);
          }}
          onDuration={(duration: any) => {
            setDurationOfVideo(duration);
          }}
        />
      </Div>
      <Div
        position="absolute"
        height="100%"
        width="100%"
        bottom={0}
        backgroundColor="black"
        backgroundImage={thumbnailUrl ? `url('${thumbnailUrl}')` : ""}
        opacity={playing || playerProgressInSeconds > 0 ? 0 : 1}
        backgroundSize="cover"
        backgroundPosition="center"
      />
      {/* Overlay to pause video that's rendered under controls */}
      {playing && (
        <Flex
          position="absolute"
          top={0}
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
          bg="transparent"
          cursor="pointer"
          onClick={() => setPlaying(false)}
        />
      )}
      {/* Overlay to play video that's rendered over controls */}
      {!playing && (
        <Flex
          position="absolute"
          top={0}
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
          bg="rgb(0, 0, 0, 0.5)"
          cursor="pointer"
          onClick={() => setPlaying(true)}
        >
          <Flex height="60px" width="60px">
            <PlayCircle color="white" />
          </Flex>
        </Flex>
      )}
      <ChildControls
        canLearn={canLearn}
        onClickLearn={onClickLearnButton}
        durationOfVideo={durationOfVideo}
        fromModule={fromModule}
        muted={muted}
        playerProgressInSeconds={playerProgressInSeconds}
        playing={playing}
        seekTo={seekTo}
        setMuted={setMuted}
        setPlaying={setPlaying}
        userVideoData={userVideoData}
        focusCommentInput={focusCommentInput}
      />
    </Div>
  );
}
