import { cloneDeep } from "lodash";
import { useGetChallengeCommentsQuery } from "services/graphql";

const PER_PAGE = 10;

export default variables => {
  const { data = {}, fetchMore } = useGetChallengeCommentsQuery({
    variables: {
      ...variables,
      first: PER_PAGE,
    },
  });

  const challenge = data?.challengeById;

  if (!challenge || !challenge.comments) {
    return [[], false, () => {}];
  }

  const hasMore = challenge.comments.pageInfo?.hasNextPage;
  const fetchNextPage = () => {
    const lastComment =
      challenge.comments.edges[challenge.comments.edges.length - 1];
    return (
      hasMore &&
      fetchMore({
        variables: {
          ...variables,
          after: lastComment.cursor,
          first: PER_PAGE,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }
          const newResult = cloneDeep(previousResult);
          newResult.challengeById.comments.edges = previousResult.challengeById.comments.edges.concat(
            fetchMoreResult.challengeById.comments.edges
          );
          newResult.challengeById.pageInfo = fetchMoreResult.pageInfo;
          newResult.challengeById.totalCount = fetchMoreResult.totalCount;
          return newResult;
        },
      })
    );
  };
  return [challenge.comments.edges.map(c => c.node), hasMore, fetchNextPage];
};
