import ProgressBar from "app/components/ProgressBar";
import { VIDEO_RATIO } from "constants/index";
import styled from "styled-components";
import {
  flexbox,
  grid,
  size,
  space,
  SizeProps,
  SpaceProps,
  FlexProps,
  GridProps,
  variant,
} from "styled-system";
import transition from "styled-transition-group";
import IconButton from "app/components/Button/IconButton";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { H3, H7 } from "app/components/Typography";
import classLabelVariants from "theme/components/classLabelVariants";

export const CARD_PREVIEW_PADDING_RIGHT = `${(1 - 1 / VIDEO_RATIO) * 230}%`;
export const CARD_RATIO = 130 / 280;

interface WrapperProps extends SpaceProps, FlexProps, GridProps, SizeProps {
  height?: string;
}

export const Wrapper = styled.a<WrapperProps>`
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  ${space};
  ${flexbox};
  ${grid};
  ${size};
`;

export const ThumbnailContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  padding-top: ${CARD_RATIO * 100}%;
  position: relative;
`;

export const CardInfoContainer = styled.div`
  margin: 8px 10px 0 10px;
  max-height: 140px;
`;

export const FadeOutThumbnail = transition.div`
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;

  &:exit {
    opacity: 1;
  }
  &:exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
  }
`;

export const LockOverlay = styled.div`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
`;

export const StyledProgressBar = styled(ProgressBar)`
  position: absolute;
  bottom: 0;
  height: 6px;
  width: 100%;

  ${({ hide }) => hide && "opacity: 0"};
`;

export const Label = styled(Flex).attrs({
  borderRadius: "2px",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  backgroundColor: "monochrome.10",
  height: "16px",
})``;

export const LabelText = styled(H7)`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  white-space: nowrap;
`;

export const ClassTypeLabel = styled(Label)(
  variant({
    variants: classLabelVariants,
  })
);

export const Title = styled(H3)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 260px;

  ${({ theme }) => theme.mediaQueries.ltsm} {
    width: 175px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ThumbnailWrapper = styled(Div).attrs({
  height: "100%",
  bg: "darkGrey",
  position: "absolute",
  overflow: "hidden",
})`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  top: 0;
  mask-image: -webkit-radial-gradient(white, black);
`;

export const OverlayShadow = styled(Div)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${({ height }) => height || "50%"};
`;

const textGradientBackground =
  "linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, 0.2) 50%, rgb(34, 34, 34, 0.6) 80%, rgb(34, 34, 34) 100%);";
const iconGradientBackground =
  "linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, 0.2) 50%, rgb(34, 34, 34, 0.6) 90%, rgb(34, 34, 34) 100%);";

interface VideoDetailsProps {
  isPreviewVideoShowing: boolean;
}

export const VideoDetails = styled.div<VideoDetailsProps>`
  position: absolute;
  left: 0;
  top: 0;
  color: ${({ theme }) => theme.colors.white};
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  font-size: 11px;
  border-radius: 8px;
  background: ${({ isPreviewVideoShowing }) =>
    isPreviewVideoShowing ? iconGradientBackground : textGradientBackground};

  a {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const CardIconButton = styled(IconButton).attrs({
  width: "24px",
  height: "24px",
  color: "monochrome.2",
  p: 0,
  hoverColor: "white",
})``;

export const ExclusiveUpgradeSpan = styled.span`
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.colors.blue} 15%, ${theme.colors.bulbaGreen} 100%)`};
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const FreeClassSpan = styled.span`
  color: ${({ theme }) => theme.colors.orange};
`;
