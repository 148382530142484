import styled from "styled-components";
import IconButton from "./index";

export default styled(IconButton).attrs(
  ({ color, hoverColor, width, height }) => ({
    width: width || "24px",
    height: height || "24px",
    color: color || "monochrome.2",
    p: 0,
    hoverColor: hoverColor || "white",
  })
)``;
