import { useEffect, useState } from "react";
import { AlgoliaClassData } from "app/components/Algolia/types";
import { searchClient } from "app/components/Algolia/constants";

function concatClassDataAttributes(classData: AlgoliaClassData) {
  const queryAttributesArray = [];
  const { style, level, type } = classData;
  if (style) {
    queryAttributesArray.push(style);
  }

  if (level) {
    queryAttributesArray.push(level);
  }

  if (type) {
    queryAttributesArray.push(type);
  }

  return queryAttributesArray.join(" ");
}

export function useSimilarClasses(classData: AlgoliaClassData) {
  const [loading, setLoading] = useState(true);
  const [similarClasses, setSimilarClasses] = useState<object[]>([]);

  useEffect(() => {
    if (!classData) {
      return;
    }

    const getSimilarClasses = async () => {
      /* @TODO: move this to back end to re-use for other platforms */
      const index = searchClient.initIndex("classes");
      const similarQuery = concatClassDataAttributes(classData);

      const response = await index.search("", {
        similarQuery,
        filters: `NOT id:${classData.id}`,
        hitsPerPage: 10,
      });

      setSimilarClasses(response.hits);
      setLoading(false);
    };

    getSimilarClasses();
  }, [classData]);

  return { similarClasses, loading };
}
