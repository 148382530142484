import styled from "styled-components";
import Button from "app/components/Button";

export const MoreContentSection = styled.div`
  width: 100%;
  border-style: none;
  margin-bottom: 8px;
  padding: 10px;
`;

export const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.monochrome[9]};
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: 0.01em;
  margin: 0 0 16px 0;
`;

export const AppIcon = styled.div`
  height: 48px;
  width: auto;
`;

export const DownloadButton = styled(Button)`
  height: 48px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 12px;
  width: 100%;
  padding: 0 8px;
  text-transform: uppercase;
  white-space: normal;
  line-height: 1;
`;

export const BrowserWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const BrowserContainer = styled.div`
  max-width: 1314px;
  width: 100%;
`;
