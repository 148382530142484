import React, { useState } from "react";
import Flex from "app/components/Flex";
import styled from "styled-components";
import { UploadUserVideoModal } from "app/components/UploadUserVideoModal";
import { VideoUploadEdge } from "services/graphql";
import { UserVideoUpload } from "./UserVideoUpload";
import { UserVideoModal } from "./UserVideoModal";

const Wrapper = styled(Flex)`
  gap: 16px;
`;

interface Props {
  classNumber: number;
  videos: VideoUploadEdge[];
  fromModule: string;
}

export const UserVideoUploadList = ({
  classNumber,
  videos = [],
  fromModule,
}: Props) => {
  const [userVideoId, setUserVideoId] = useState(null);
  const openModal = ({ id, encodingStatus }: any) => {
    if (encodingStatus !== "available") {
      return;
    }

    setUserVideoId(id);
  };

  return (
    <>
      <Wrapper flexWrap="wrap" position="relative" width="100%">
        {videos.map(({ node }) => (
          <UserVideoUpload
            key={node.id}
            userVideo={node}
            openModal={openModal}
          />
        ))}
      </Wrapper>
      {!!userVideoId && (
        <UserVideoModal
          userVideoId={userVideoId}
          closeModal={() => setUserVideoId(null)}
          fromModule={fromModule}
        />
      )}
      <UploadUserVideoModal
        classNumber={classNumber}
        buttonProps={{ width: "200px" }}
      />
    </>
  );
};
