import React from "react";
import { Link } from "react-router-dom";
import { DropdownOption } from "app/components/Dropdown";

interface Props {
  classLevel: string;
}

export const GoToClassLevelOption = ({ classLevel }: Props) => {
  if (!classLevel) {
    return null;
  }

  return (
    <Link to={`/results/classes?refinementList[level][0]=${classLevel}`}>
      <DropdownOption text="Go to Level" />
    </Link>
  );
};
