import React from "react";
import {
  InstantSearch,
  SearchBox,
  useInfiniteHits,
  useInstantSearch,
} from "react-instantsearch-hooks-web";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { searchClient } from "app/components/Algolia/constants";
import Flex from "app/components/Flex";
import Icon, { Search } from "app/components/Icon";
import { AlgoliaClassRow } from "app/routes/Playlists/PlaylistClassRow";
import { Waypoint } from "app/components/Waypoint";
import { EmptyStateList } from "./EmptyStateList";
import { SearchInputWrapper, ClassRowWrapper } from "./styles";

export function SearchClassTagInner({
  setClassNumberToTag,
}: {
  setClassNumberToTag(classNumber: number): void;
}) {
  const { status } = useInstantSearch();
  const { hits, isLastPage, showMore } = useInfiniteHits();
  const isLoading = status === "loading";

  return (
    <>
      <Flex gap="16px" my="16px" px="16px">
        <SearchInputWrapper>
          <SearchBox
            autoFocus
            searchAsYouType={false}
            submitIconComponent={({ classNames }) => (
              <div className={classNames.submitIcon}>Search</div>
            )}
            placeholder="Search"
          />
          <Icon as={Search} height="100%" />
        </SearchInputWrapper>
      </Flex>
      <Flex
        flexDirection="column"
        flex="1"
        gap="16px"
        overflow="auto"
        pb="100px"
      >
        {hits.length > 0 ? (
          hits.map((classHit: any) => (
            <ClassRowWrapper onClick={() => setClassNumberToTag(classHit.id)}>
              <AlgoliaClassRow key={classHit.id} classHit={classHit} />
            </ClassRowWrapper>
          ))
        ) : (
          <EmptyStateList />
        )}
        {!isLoading && (
          <Waypoint fetchData={showMore || (() => {})} hasMore={!isLastPage} />
        )}
      </Flex>
    </>
  );
}

export function SearchClassTag({
  setClassNumberToTag,
}: {
  setClassNumberToTag(classNumber: number): void;
}) {
  return (
    <InstantSearch
      indexName={AlgoliaIndexes.classes}
      searchClient={searchClient}
    >
      <SearchClassTagInner setClassNumberToTag={setClassNumberToTag} />
    </InstantSearch>
  );
}
