import Axios from "axios";

const FILE_CHUNK_SIZE = 10_000_000;

const uploadFile = async (
  classNumber,
  videoFile,
  videoUploadCreate,
  videoUploadComplete,
  setVideoFileState
) => {
  try {
    const axios = Axios.create();
    delete axios.defaults.headers.put["Content-Type"];

    const numParts = Math.ceil(videoFile.size / FILE_CHUNK_SIZE);
    const partIndexToProgress = [...Array(numParts)].map(() => 0);
    const updateVideoProgress = (partIndex, partPercentage) => {
      partIndexToProgress[partIndex] = partPercentage;
      const totalPartProgress = partIndexToProgress.reduce(
        (acc, curr) => acc + curr,
        0
      );
      setVideoFileState(oldVideoFileState => ({
        ...oldVideoFileState,
        percentProgress: Math.round(
          (totalPartProgress / (numParts * 100)) * 100
        ),
      }));
    };

    const videoUploadCreateResp = await videoUploadCreate(
      classNumber,
      numParts
    );
    if (!videoUploadCreateResp) {
      return false;
    }
    const { s3PartsUrls, videoId, uploadId } = videoUploadCreateResp;
    setVideoFileState(oldVideoFileState => ({ ...oldVideoFileState, videoId }));

    const partPromises = [];
    for (let i = 0; i < s3PartsUrls.length; i++) {
      const start = FILE_CHUNK_SIZE * i;
      const end = FILE_CHUNK_SIZE * (i + 1);
      const blob =
        i < s3PartsUrls.length
          ? videoFile.slice(start, end)
          : videoFile.slice(start);
      partPromises.push(
        retriedPartUpload(
          axios,
          s3PartsUrls[i].url,
          blob,
          i,
          updateVideoProgress,
          3
        )
      );
    }

    const parts = await Promise.all(partPromises);
    setVideoFileState(oldVideoFileState => ({
      ...oldVideoFileState,
      percentProgress: 100,
    }));

    // complete upload
    const success = await videoUploadComplete(videoId, parts, uploadId);
    return success;
  } catch (e) {
    return false;
  }
};

const retriedPartUpload = async (
  axios,
  url,
  blob,
  partIndex,
  updateVideoProgress,
  numTries
) => {
  if (numTries === 0) {
    throw new Error("Failed to upload part. Exhausted all retries");
  }

  try {
    const resp = await axios.put(url, blob, {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        updateVideoProgress(partIndex, percentCompleted);
      },
    });
    updateVideoProgress(partIndex, 100);
    return {
      partNumber: partIndex + 1,
      eTag: resp.headers.etag,
    };
  } catch (e) {
    await timeoutPromise(1000);
    return retriedPartUpload(axios, url, blob, numTries - 1);
  }
};

const timeoutPromise = msTimeout => {
  return new Promise(resolve => {
    setTimeout(() => resolve(), msTimeout);
  });
};

export default uploadFile;
