import React, { useState } from "react";
import { ShareModal } from "app/components/ClassComponents/ShareModal";
import { Share } from "app/components/Icon";

import { DropdownOption } from "app/components/Dropdown";
import { IS_MOBILE } from "constants/index";
import env from "helpers/env";
import { StudioClass } from "services/graphql";

type RecursivePartialSC<StudioClass> = {
  [key in keyof StudioClass]?: RecursivePartialSC<StudioClass[key]>;
};

type PartialStudioClass = RecursivePartialSC<StudioClass>;

interface Props {
  studioClass: PartialStudioClass;
}

export const ShareStudioClassOption = ({ studioClass }: Props) => {
  const [isShareClassModalOpen, setIsShareClassModalOpen] = useState(false);

  if (!studioClass) {
    return null;
  }

  return (
    <>
      <DropdownOption
        Svg={Share}
        onClick={() => {
          if (IS_MOBILE) {
            navigator.share({
              url: `${env("PUBLIC_PARKER_URL")}/class/preview/${
                studioClass.id
              }`,
              text: `Check out this STEEZY Studio video from ${studioClass.instructor?.name}`,
              title: studioClass.title,
            });
          } else {
            setIsShareClassModalOpen(true);
          }
        }}
        text="Share Class"
      />
      <ShareModal
        classNumber={studioClass.classId}
        classThumbnailUrl={studioClass.thumbnailImageUrl}
        isOpen={isShareClassModalOpen}
        onCancel={() => setIsShareClassModalOpen(false)}
        shareMessage={`Check out this STEEZY Studio video from ${studioClass.instructor?.name}`}
      />
    </>
  );
};
