import React from "react";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import { Modal, CloseModalButton } from "./styles";
import { useCreatePlaylistAddStudioClass } from "./hooks";
import { PlaylistForm } from "./PlaylistForm";

interface Props {
  closeModal(): void;
  classNumber: number;
  classId: string;
}

export function CreatedPlaylistAddClassModal({
  classNumber,
  classId,
  closeModal,
}: Props) {
  const { createPlaylistAndAddClass } = useCreatePlaylistAddStudioClass({
    classId,
    classNumber,
  });

  return (
    <Modal isOpen onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <H2>Create Playlist</H2>
        <CloseModalButton onClick={closeModal} variant="light" />
      </Flex>
      <PlaylistForm
        onSubmit={createPlaylistAndAddClass}
        submitButtonText="Create"
      />
    </Modal>
  );
}
