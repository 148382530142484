import React from "react";
import { Link } from "react-router-dom";
import { DropdownOption } from "app/components/Dropdown";

interface Props {
  styleName: string;
}

export const GoToClassStyleOption = ({ styleName }: Props) => {
  if (!styleName) {
    return null;
  }

  return (
    <Link to={`/results/classes?refinementList[style][0]=${styleName}`}>
      <DropdownOption text="Go to Style" />
    </Link>
  );
};
