import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Div from "app/components/Div";
import { color } from "styled-system";

const ProgressCircleWrapper = styled.div`
  position: relative;
`;

const Svg = styled.svg`
  transform: rotate(-90deg);
  circle {
    transition: stroke-dashoffset 0.35s;
  }
`;

const ProgressText = styled(Div).attrs(props => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  ...props,
}))`
  font-size: ${({ fontSize }) => fontSize};
`;

const Circle = styled.circle`
  ${color}
`;

const ProgressCircle = ({
  fontSize = "14px",
  percentProgress,
  progressColor = "monochrome.0",
  progressPathColor = "rgba(255, 255, 255, 0.2)",
  text = null,
  textColor = "monochrome.0",
  width = 64,
}) => {
  const radius = width / 2;
  const stroke = 2;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  return (
    <ProgressCircleWrapper
      style={{ height: `${width}px`, width: `${width}px` }}
    >
      <Svg height={width} width={width}>
        <Circle
          color={progressColor}
          stroke="currentColor"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{
            strokeDashoffset:
              circumference - (percentProgress / 100) * circumference,
          }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <Circle
          color={progressPathColor}
          stroke="currentColor"
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </Svg>
      <ProgressText color={textColor} fontSize={fontSize}>
        {text != null ? text : `${percentProgress}%`}
      </ProgressText>
    </ProgressCircleWrapper>
  );
};

ProgressCircle.defaultProps = {
  fontSize: "14px",
  percentProgress: 0,
  progressColor: "monochrome.0",
  progressPathColor: "rgba(255, 255, 255, 0.2)",
  textColor: "monochrome.0",
};

ProgressCircle.propTypes = {
  fontSize: PropTypes.string,
  percentProgress: PropTypes.number, // 0 to 100
  progressColor: PropTypes.string,
  progressPathColor: PropTypes.string, // must have opacity
  text: PropTypes.string,
  textColor: PropTypes.string,
  width: PropTypes.number, // num pixels
};

export default ProgressCircle;
