import React from "react";
import { LabelText, ClassTypeLabel } from "./styles";

interface Props {
  label: string;
}

export function ClassInfoLabel({ label }: Props) {
  return (
    <ClassTypeLabel p="2px 5px" variant={label}>
      <LabelText>{label}</LabelText>
    </ClassTypeLabel>
  );
}
