import React from "react";
import styled from "styled-components";
import Div from "app/components/Div";
import { SkeletonTextAnimation } from "app/components/SkeletonAnimation";

type Props = {
  width?: string;
  height?: string;
  [x: string]: any;
};

export const SkeletonTextLoader = ({ width, height }: Props) => {
  return (
    <Div width={width || "100%"} height={height || "100%"}>
      <SkeletonTextAnimation/>
    </Div>
  );
}

export const SkeletonText = styled(Div)`
    background-color: ${({ theme }) => theme.colors.monochrome[2]};
    border-radius: 2px;
    overflow:hidden;
`;