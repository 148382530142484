import React, { useEffect, useMemo, useState } from "react";
import SpanLink from "app/components/SpanLink";
import {
  linkCredentialsAction,
  connectProviderAction,
} from "modules/auth/actions";
import { useDispatch } from "react-redux";
import Modal from "app/components/Modal";
import styled from "styled-components";
import IconButton from "app/components/Button/IconButton";
import STEEZYStudioLogo from "app/assets/svg/steezy-studio-logo.svg";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Icon, { Close } from "app/components/Icon";
import { H3, P } from "app/components/Typography";
import { Formik, Form } from "formik";
import Button from "app/components/Button";
import Alert from "app/components/Alert";
import InputEmail from "app/components/Input/InputEmail";
import InputPassword from "app/components/Input/InputPassword";
import { useHistory } from "react-router-dom";
import LineThroughText from "app/components/LineThroughText";
import { LoginProvider } from "modules/auth/enums";
import isServer from "helpers/isServer";
import { onboardingAccountInfoError } from "services/typewriter/segment";
import {
  GoogleSignUpButton,
  FacebookSignUpButton,
} from "./ProviderSignUpButtons";
import { useNodeRef } from "./hooks";

const StyledModal = styled(Modal)`
  width: 450px;
  max-width: 100vw;
  max-height: ${({ maxHeight }) => maxHeight}px;
  overflow: auto;
  padding: 0;
`;

interface Props {
  closeModal: () => void;
  routeToRedirectAfterLogin?: string;
  routeToRedirectAfterClose?: string;
  eventAttributes: SignUpParams;
  isOpen: boolean;
}

interface SignUpParams {
  variant?: string;
  class_id?: number;
  module?: string;
}

interface EmailSignUpFormValues {
  email: string;
  password: string;
}

export const SignUpModal = ({
  closeModal,
  routeToRedirectAfterLogin,
  routeToRedirectAfterClose,
  eventAttributes = {},
  isOpen,
}: Props) => {
  const dispatch = useDispatch();
  const [emailInputNode, emailInputRef] = useNodeRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const history = useHistory();

  const signUpWithEmail = async (values: EmailSignUpFormValues) => {
    setLoading(true);

    try {
      await dispatch(linkCredentialsAction(values, eventAttributes));
      closeModal();
    } catch (e) {
      const err = e as any;
      onboardingAccountInfoError({
        code: err.code,
        email: values.email,
        provider: LoginProvider.Email,
        Message: err.message,
      });
      setError(err);
    }

    setLoading(false);
  };

  const signUpWithProvider = async (provider: LoginProvider) => {
    setLoading(true);

    try {
      await dispatch(connectProviderAction(provider, eventAttributes));
      closeModal();
    } catch (e) {
      const err = e as any;
      onboardingAccountInfoError({
        code: err.code,
        email: err.email,
        provider,
        Message: err.message,
      });
      setError(err);
    }

    setLoading(false);
  };

  const redirectAnonUsersOnClose = () => {
    if (routeToRedirectAfterClose) {
      history.push(routeToRedirectAfterClose);
    }
    closeModal();
  };

  useEffect(() => {
    if (emailInputNode) {
      emailInputNode.focus();
    }
  }, [emailInputNode]);

  const windowHeight = useMemo(() => {
    if (isServer) {
      return "100%";
    }
    if (navigator.userAgent.match(/instagram/i)) {
      return "calc(100% - 80px)";
    }
    return "100%";
  }, []);

  return (
    <StyledModal
      maxHeight={windowHeight}
      onBackgroundClick={redirectAnonUsersOnClose}
      onEscapeKeydown={redirectAnonUsersOnClose}
      isOpen={isOpen}
    >
      <Flex
        bg="black"
        width="100%"
        justifyContent="center"
        alignItems="center"
        p={3}
      >
        <Flex flexDirection="column" width="100%">
          <Flex justifyContent="flex-end" width="100%">
            <IconButton
              Icon={Close}
              width="20px"
              height="auto"
              color="white"
              onClick={redirectAnonUsersOnClose}
            />
          </Flex>
          <Icon height="50px" as={STEEZYStudioLogo} color="white" mb={3} />
        </Flex>
      </Flex>
      <Flex flexDirection="column" width="100%" p={4} bg="white">
        <H3 mb={3} textAlign="center">
          Create a FREE account to get started
        </H3>
        <GoogleSignUpButton
          onClick={() => signUpWithProvider(LoginProvider.Google)}
        />
        <FacebookSignUpButton
          onClick={() => signUpWithProvider(LoginProvider.Facebook)}
        />
        <LineThroughText>OR</LineThroughText>
        <Formik
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
          }}
          onSubmit={signUpWithEmail}
          render={({ touched, errors, handleChange, isValid }) => (
            <Form>
              <Div mb={3}>
                <InputEmail
                  innerRef={emailInputRef}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                />
              </Div>
              <Div mb={3}>
                <InputPassword
                  name="password"
                  id="password"
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  placeholder="Password"
                  required
                />
              </Div>
              {error && (
                <Alert variant="danger" closeAlert={() => setError(null)}>
                  {error.message}
                </Alert>
              )}
              {success && (
                <Alert variant="success" closeAlert={() => setSuccess(null)}>
                  {success.message}
                </Alert>
              )}
              <Button width="100%" disabled={loading || !isValid} type="submit">
                Sign Up
              </Button>
            </Form>
          )}
        />
        <P mt={3} textAlign="center">
          By creating a STEEZY account, you agree to our{" "}
          <SpanLink
            color="black"
            fontWeight="bold"
            onClick={() => window.open("https://www.steezy.co/terms", "blank")}
          >
            Terms of Service
          </SpanLink>
        </P>
        <P mt={3} textAlign="center">
          Already a member?{" "}
          <SpanLink
            onClick={() =>
              history.push(
                routeToRedirectAfterLogin
                  ? `/login?redirect=${routeToRedirectAfterLogin}`
                  : "/login"
              )
            }
          >
            Log in here.
          </SpanLink>
        </P>
      </Flex>
    </StyledModal>
  );
};
