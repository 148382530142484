import React from "react";
import { Heart, HeartFilled } from "app/components/Icon";
import { DropdownOption } from "app/components/Dropdown";
import useToggleSaveClass from "hooks/Classes/useToggleSaveClass";
import { useIsClassSaved } from "modules/selectors/content";
import { useGetUserIsAnonymous } from "modules/selectors";
import { PartialStudioClass } from "./types";

interface Props {
  studioClass: PartialStudioClass;
}

export const SaveStudioClassOption = ({ studioClass }: Props) => {
  const isAnonymous = useGetUserIsAnonymous();
  const toggleSaveClassMutation = useToggleSaveClass();
  const isSaved = useIsClassSaved({ classNumber: Number(studioClass.classId) });

  if (isAnonymous) {
    return null;
  }

  return (
    <>
      <DropdownOption
        Svg={isSaved ? HeartFilled : Heart}
        onClick={() => {
          toggleSaveClassMutation({
            component: "DropdownMenuOptions",
            variables: {
              classId: studioClass.classId,
              willSave: !isSaved,
            },
            classData: {
              instructor: studioClass.instructor?.name,
              level: studioClass.level,
              style: studioClass.styleV2,
              title: studioClass.title,
            },
          });
        }}
        text={isSaved ? "Class Saved" : "Save Class"}
      />
    </>
  );
};
