import React from "react";
import { PlayOutline } from "app/components/Icon";
import { DropdownOption } from "app/components/Dropdown";
import { Class } from "services/graphql";
import { useSelectClass } from "app/components/ClassCard/hooks";
import { PartialStudioClass } from "./types";

interface Props {
  classData: Partial<Class> | PartialStudioClass;
}

export const MobilePreviewClassOption = ({ classData }: Props) => {
  const { selectClass } = useSelectClass({
    classData,
    selectedFrom: "DropdownMenuOptions",
    element: "StartClassButton",
  });

  return (
    <DropdownOption
      Svg={PlayOutline}
      onClick={() => {
        selectClass();
      }}
      text="Preview Class"
    />
  );
};
