import React from "react";
import PropTypes from "prop-types";
import { Heart, HeartFilled } from "app/components/Icon";
import Tooltip from "app/components/Tooltip";
import CardIconButton from "./CardIconButton";

const HeartIconButton = ({ isActive, onClick, ...rest }) => (
  <Tooltip overlay={isActive ? "Saved" : "Save"}>
    <CardIconButton
      Icon={isActive ? HeartFilled : Heart}
      onClick={e => {
        // Stop propagation since we handle clicks on Wrapper
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        onClick(e);
      }}
      {...rest}
    />
  </Tooltip>
);

HeartIconButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HeartIconButton;
