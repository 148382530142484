import Flex from "app/components/Flex";
import Text from "app/components/Text";
import { P2 } from "app/components/Typography";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  font-weight: bold;
`;

export const ExplicitBadge = (props: any) => (
  <Flex
    backgroundColor="monochrome.10"
    borderRadius="2px"
    height="18px"
    width="18px"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    <Text
      color="white"
      lineHeight="12px"
      fontSize="10px"
      fontWeight="700"
      letterSpacing="0"
    >
      E
    </Text>
  </Flex>
);

export const InstructorName = connect(({ auth }: any) => ({
  auth,
}))(({ auth, instructor }: any) =>
  auth.isAnonymous ? (
    <P2 fontWeight="bold">{instructor.name}</P2>
  ) : (
    <StyledLink to={`/library/instructors/${instructor.slug}`}>
      {instructor.name}
    </StyledLink>
  )
);
