import React from "react";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import {
  useCreatePlaylistMutation,
  GetMyPlaylistsDocument,
} from "services/graphql";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router";
import { playlistsEditPlaylist } from "services/typewriter/segment";
import {
  getAnalyticsLocationFromPath,
  getAnalyticsModuleFromPath,
} from "helpers/analytics";
import { ANALYTICS_ACTION } from "constants/analytics";
import { Modal, CloseModalButton } from "./styles";
import { PartialPlaylistV2 } from "../types";
import { PlaylistForm } from "./PlaylistForm";

interface Props {
  closeModal(playlistCreate?: PartialPlaylistV2): void;
}

export function CreatePlaylistModal({ closeModal }: Props) {
  const history = useHistory();
  const location = useLocation();
  const [createPlaylistMutation] = useCreatePlaylistMutation();

  const createPlaylist = ({
    playlistName,
    playlistPrivacy,
  }: {
    playlistName: string;
    playlistPrivacy: string;
  }) => {
    createPlaylistMutation({
      variables: {
        input: {
          name: playlistName,
          hidden: playlistPrivacy === "true",
        },
      },
      refetchQueries: [
        {
          query: GetMyPlaylistsDocument,
          variables: { pagination: { first: 10 }, studioClassIds: [] },
        },
      ],
      onCompleted({ playlistCreate }) {
        playlistsEditPlaylist({
          action: ANALYTICS_ACTION.playlistsCreate,
          playlist_id: playlistCreate.id,
          playlist_name: playlistCreate.name,
          privacy: playlistCreate?.hidden === true ? "private" : "public",
          location: getAnalyticsLocationFromPath(location.pathname),
          module: getAnalyticsModuleFromPath(location.pathname),
        });
        toast.success(`Created ${playlistCreate.name} playlist`);
        history.push(`/playlists/${playlistCreate.id}`);
        closeModal(playlistCreate);
      },
    });
  };

  return (
    <Modal isOpen onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <H2>Create Playlist</H2>
        <CloseModalButton onClick={closeModal} variant="light" />
      </Flex>
      <PlaylistForm onSubmit={createPlaylist} submitButtonText="Create" />
    </Modal>
  );
}
