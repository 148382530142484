import React, { useState } from "react";
import Flex from "app/components/Flex";
import Button from "app/components/Button";
import { Label, Input, Option, Select } from "./styles";

interface Props {
  playlistName?: string;
  playlistHidden?: boolean;
  submitButtonText: string;
  onSubmit({
    playlistName,
    playlistPrivacy,
  }: {
    playlistName: string;
    playlistPrivacy: string;
  }): void;
}

export function PlaylistForm({
  onSubmit,
  submitButtonText,
  playlistName = "",
  playlistHidden = true,
}: Props) {
  const [playlistNameInput, setPlaylistNameInput] = useState(playlistName);
  const [playlistPrivacyInput, setPlaylistPrivacyInput] = useState(
    playlistHidden?.toString()
  );
  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    setPlaylistNameInput(input?.value);
  };
  const handlePrivacyInputChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const input = e.target;
    setPlaylistPrivacyInput(input?.value);
  };

  const areInputFieldsValid = () => {
    if (!playlistNameInput) {
      return false;
    }
    if (playlistNameInput.length <= 0) {
      return false;
    }
    if (!playlistPrivacyInput) {
      return false;
    }

    return true;
  };

  return (
    <Flex flexDirection="column" gap="16px" width="100%">
      <Flex flexDirection="column">
        <Label>Name</Label>
        <Input
          value={playlistNameInput}
          onChange={handleNameInputChange}
          maxLength={100}
        />
      </Flex>
      <Flex flexDirection="column">
        <Label>Privacy</Label>
        <Select
          value={playlistPrivacyInput}
          onChange={handlePrivacyInputChange}
        >
          <Option value="false">Public</Option>
          <Option value="true">Private</Option>
        </Select>
      </Flex>
      <Button
        onClick={() =>
          onSubmit({
            playlistName: playlistNameInput,
            playlistPrivacy: playlistPrivacyInput,
          })
        }
        disabled={!areInputFieldsValid()}
      >
        {submitButtonText}
      </Button>
    </Flex>
  );
}
