import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const ClassInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

export const ClassInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  :hover {
    opacity: 0.8;
    text-decoration: none;
  }
`;

const valueCss = css`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.monochrome[9]};
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: 0;
`;

export const Value = styled.a`
  ${valueCss};
  color: ${({ theme }) => theme.colors.blue};
`;

export const Time = styled.h3`
  ${valueCss};
`;
