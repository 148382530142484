import React from "react";
import { Slider } from "app/components/Slider";
import ClassCard from "app/components/ClassCard";
import { ClassCardSkeleton } from "app/components/ClassCard/ClassCardSkeleton";
import { Class } from "services/graphql";
import { AlgoliaClassData } from "app/components/Algolia/types";
import { useSimilarClasses } from "./useSimilarClasses";

interface Props {
  classData: Class | AlgoliaClassData;
}

const LoadingSlider = () => {
  return (
    <Slider
      data={[{}, {}, {}, {}, {}]}
      slideComponent={ClassCardSkeleton}
      slidesSpacing={24}
      cardHeight="190px"
    />
  );
};

export function SimilarClasses({ classData }: Props) {
  const { loading, similarClasses } = useSimilarClasses(classData);

  if (loading) {
    return <LoadingSlider />;
  }

  return (
    <Slider
      data={similarClasses}
      slideComponent={ClassCard}
      selectedFrom="Similar Classes"
      slidesSpacing={24}
      cardHeight="190px"
      centered={false}
    />
  );
}
