import { useMutation } from "@apollo/client";
import { cloneDeep } from "lodash";
import { ACTIVITY_REACTION_DELETE } from "graphql/activities/mutations";
import { readActivityTypeFragment, writeActivityTypeFragment } from "./utils";

export default () => {
  const [activityReactionDelete] = useMutation(ACTIVITY_REACTION_DELETE);

  return async ({ activityId, activityType, reactionType }) => {
    const resp = await activityReactionDelete({
      variables: {
        input: {
          contentId: activityId,
          contentType: activityType,
          reactionType,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        activityReactionDelete: true,
      },
      // updates local cache with reaction data
      update(_, { data }) {
        if (!data.activityReactionDelete) {
          return;
        }

        const activityFragment = readActivityTypeFragment(
          activityType,
          activityId
        );
        const newActivityFragment = cloneDeep(activityFragment);
        // remove indicator that user has reacted to this content
        if (newActivityFragment.userReactions) {
          const reactionIndex = newActivityFragment.userReactions.indexOf(
            reactionType
          );
          if (reactionIndex >= 0) {
            newActivityFragment.userReactions.splice(reactionIndex, 1);
          }
        }
        // subtract from total count of reactions for this content
        const reaction =
          newActivityFragment.reactions &&
          newActivityFragment.reactions.filter(
            r => r.reactionType === reactionType
          )[0];
        if (reaction && reaction.totalCount > 0) {
          reaction.totalCount--;
        }
        writeActivityTypeFragment(activityType, newActivityFragment);
      },
    });
    const success = !!resp.data?.activityReactionDelete;
    return success;
  };
};
