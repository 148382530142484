import React from "react";

export function useNodeRef() {
  const [node, setNode] = React.useState(null);
  const ref = React.useCallback((refNode: any) => {
    setNode(refNode);
  }, []);

  return [node, ref];
}
