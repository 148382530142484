import React from "react";
import { useDispatch } from "react-redux";
import { toggleAddClassModalAction } from "modules/components";
import { ScheduleAdd } from "app/components/Icon";
import { DropdownOption } from "app/components/Dropdown";
import { useGetUserIsAnonymous } from "modules/selectors";

interface Props {
  classNumber: number;
}

export const AddClassToScheduleOption = ({ classNumber }: Props) => {
  const isAnonymous = useGetUserIsAnonymous();
  const dispatch = useDispatch();

  if (isAnonymous) {
    return null;
  }

  if (!classNumber) {
    return null;
  }

  return (
    <DropdownOption
      Svg={ScheduleAdd}
      onClick={() => {
        dispatch(toggleAddClassModalAction(classNumber.toString()));
      }}
      text="Add to Schedule"
    />
  );
};
