import colors from "../colors";

export default {
  Beginner: {
    color: colors.white,
    background: colors.orange,
  },
  Intermediate: {
    color: colors.white,
    background: colors.blueberry,
  },
  Advanced: {
    color: colors.white,
    background: colors.sky,
  },
};
