import React from "react";
import Flex from "app/components/Flex";
import { ControlButton } from "app/components/ClassPlayer/components";
import Text from "app/components/Text";
import { SpaceProps } from "styled-system";

interface Props extends SpaceProps {
  onClick: (_e: any) => void;
  Icon: any;
  text?: string;
  iconWidth?: number;
  show?: boolean;
  disabled?: boolean;
}

export const BaseButton = ({
  onClick,
  Icon,
  text = "",
  iconWidth = 24,
  show = true,
  disabled = false,
  ...rest
}: Props) => {
  if (!show) {
    return null;
  }

  return (
    <ControlButton mb={3} onClick={onClick} disabled={disabled} {...rest}>
      <Flex flexDirection="column" alignItems="center">
        <Icon width={iconWidth} color="#FFFFFF" />
        <Text fontSize="11px" mt={2}>
          {String(text)}
        </Text>
      </Flex>
    </ControlButton>
  );
};
