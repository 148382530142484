import React from "react";
import Flex from "app/components/Flex";
import Thumbnail from "app/components/Thumbnail";
import { H3 } from "app/components/Typography";
import { useHistory, useLocation } from "react-router";
import { formatDuration } from "helpers/formatDuration";
import { useGetUserClassProgressPercent } from "modules/selectors";
import { AccessType, Class } from "services/graphql";
import { getAnalyticsLocationFromPath } from "helpers/analytics";
import { useContentSelectClassEvent } from "hooks/analytics";
import env from "helpers/env";
import { ExplicitBadge } from "app/components/ClassCard/Card/components";
import { useCanUserTakeClass } from "hooks/Classes";
import {
  CompletedIcon,
  LinkWrapper,
  ProgressBar,
  ThumbnailContainer,
  ThumbnailWrapper,
  ClassInfoText,
  LockedLabel,
} from "../styles";
import { ClassInfoLabel } from "../ClassInfoLabel";
import { DropdownMenuOptions } from "./DropdownMenuOptions";

interface Props {
  classData: Partial<Class> | any;
}

export function ClassRow({ classData }: Props) {
  const history = useHistory();
  const location = useLocation();
  const {
    classProgressPercent,
    isClassCompleted,
  } = useGetUserClassProgressPercent({
    classId: classData.id,
    classDurationInSeconds: classData.duration_in_seconds,
  });
  const classUrl = `/class/${classData.id}`;
  const { contentSelectClassEvent } = useContentSelectClassEvent({
    classData,
  });
  const { canUserTakeClass } = useCanUserTakeClass({
    classId: classData.id,
  });

  const goToClass = () => {
    contentSelectClassEvent({
      location: getAnalyticsLocationFromPath(location.pathname),
      selected_from: "Weekly Recommendations",
    });

    history.push(classUrl, {
      previousLocation: location,
    });
  };

  const handleClick = (e: any) => {
    e.preventDefault(); // To ignore link tag href

    goToClass();
  };

  return (
    <Flex width="100%" justifyContent="space-between" maxWidth="768px">
      <Flex gap="16px" flexWrap="wrap">
        <LinkWrapper onClick={handleClick} href={classUrl}>
          <ThumbnailContainer>
            <ThumbnailWrapper>
              <Thumbnail
                src={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                  classData.thumbnail
                }-basic.jpg?w=600}`}
                placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                  classData.thumbnail
                }-basic.jpg?blur=200`}
              />

              <Flex
                position="absolute"
                top="12px"
                left="12px"
                alignItems="center"
                gap="5px"
              >
                {!canUserTakeClass &&
                  classData.accessType !== AccessType.Free && <LockedLabel />}
                {classData.isExplicit && <ExplicitBadge />}
              </Flex>
              {isClassCompleted && <CompletedIcon />}
              {classProgressPercent > 0 && (
                <ProgressBar percent={classProgressPercent} />
              )}
            </ThumbnailWrapper>
          </ThumbnailContainer>
        </LinkWrapper>
        <Flex flexDirection="column">
          <LinkWrapper onClick={handleClick} href={classUrl}>
            <H3 mb="12px" limitLines={2}>
              {classData.title}
            </H3>
          </LinkWrapper>
          <Flex gap="8px">
            {classData.level && <ClassInfoLabel label={classData.level} />}
            <ClassInfoLabel label={formatDuration(classData.duration)} />
            <ClassInfoLabel label={classData.type} />
          </Flex>
          <Flex gap="8px" alignItems="center">
            <ClassInfoText>{classData.style}</ClassInfoText>
            <div> • </div>
            <ClassInfoText>{classData.instructor?.name}</ClassInfoText>
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <DropdownMenuOptions
          classData={classData}
          onUserSelectsClass={goToClass}
        />
      </Flex>
    </Flex>
  );
}
