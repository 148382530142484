import { MOBILE_CUSTOM_BREAKPOINT } from "app/components/Navbar/constants";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export const useCentered = (centered: boolean, centerPadding: number) => {
  const [shouldShowCenteredMode, setShouldShowCenteredMode] = useState(
    centered
  );
  const [centeredModePadding, setCenteredModePadding] = useState(centerPadding);

  const isMobileScreen = useMediaQuery({
    maxDeviceWidth: MOBILE_CUSTOM_BREAKPOINT,
  });

  useEffect(() => {
    if (isMobileScreen) {
      setShouldShowCenteredMode(centered);
      setCenteredModePadding(centered ? centerPadding : 0);
    } else {
      setShouldShowCenteredMode(false);
      setCenteredModePadding(0);
    }
  }, [centered, centerPadding, isMobileScreen]);

  return { shouldShowCenteredMode, centeredModePadding };
};
