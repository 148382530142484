import env from "helpers/env";
import useToggleSaveClass from "hooks/Classes/useToggleSaveClass";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  HomeSelectContentContainer,
  LibrarySelectContentContainer,
} from "services/typewriter/segment";
import { useCanUserTakeClass } from "hooks/Classes";
import { Card } from "./Card";
import { DesktopClassPreviewModal } from "../ClassComponents/ClassPreviewModal";
import { useClassProgress, useSelectClass } from "./hooks";

interface Props {
  data: any;
  container?: HomeSelectContentContainer & LibrarySelectContentContainer;
  selectedFrom: string;
  thumbnailWidth?: string;
  isSubscribeModalShowing: boolean;
  contentType?: string;
  cardHeight?: string;
}

const ClassCard = ({
  data: classData,
  thumbnailWidth = "1200",
  selectedFrom = "classCard",
  container,
  isSubscribeModalShowing,
  ...classCardProps
}: Props) => {
  const { progressPercent, isProgressCompleted } = useClassProgress({
    classData,
  });
  const toggleSaveClassMutation = useToggleSaveClass();
  const [isPreviewModalShowing, setIsPreviewModalShowing] = useState(false);
  const { selectClass } = useSelectClass({
    classData,
    container,
    selectedFrom,
    element: "StartClassButton",
  });
  const { canUserTakeClass } = useCanUserTakeClass({
    classId: classData?.id,
  });

  const goToClass = () => {
    if (isSubscribeModalShowing) {
      return;
    }

    selectClass();
  };

  const isSaved = useSelector(
    ({ user }: any) =>
      classData &&
      user.public.saved_classes &&
      !!user.public.saved_classes[classData.id]
  );

  if (!classData) {
    return null;
  }

  const { contentType, cardHeight } = classCardProps;
  const contentData = {
    ...classData,
    canUserTakeClass,
  };

  const props = {
    progressPercent,
    isProgressCompleted,
    isSaved,
    contentData,
    contentType: contentType || "class",
    thumbnailSrc: `${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
      classData.slug
    }-basic.jpg?w=${thumbnailWidth}`,
    thumbnailPlaceholderSrc: `${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
      classData.slug
    }-basic.jpg?blur=200&px=16&auto=format`,
    selectedFrom,
    cardHeight,
  };

  return (
    <>
      <Card
        {...classCardProps}
        {...props}
        isSubscribeModalShowing={isSubscribeModalShowing}
        goToContent={goToClass}
        togglePreviewModal={() => setIsPreviewModalShowing(true)}
        saveUserContent={() => {
          toggleSaveClassMutation({
            component: selectedFrom,
            variables: {
              classId: classData.id,
              willSave: !isSaved,
              programClassRefId: classData.refId,
            },
            classData,
          });
        }}
      />
      {isPreviewModalShowing && (
        <DesktopClassPreviewModal
          classId={classData.id?.toString()}
          closeModal={() => setIsPreviewModalShowing(false)}
          goToClass={goToClass}
        />
      )}
    </>
  );
};

export default ClassCard;
