import React from "react";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import {
  useEditPlaylistMutation,
  GetMyPlaylistsDocument,
} from "services/graphql";
import { toast } from "react-toastify";
import { playlistsEditPlaylist } from "services/typewriter/segment";
import { ANALYTICS_ACTION, ANALYTICS_MODULE } from "constants/analytics";
import { useLocation } from "react-router";
import { getAnalyticsLocationFromPath } from "helpers/analytics";
import { PartialPlaylistV2 } from "../types";
import { Modal, CloseModalButton } from "./styles";
import { PlaylistForm } from "./PlaylistForm";

interface Props {
  closeModal(): void;
  playlist: PartialPlaylistV2;
}

export function EditPlaylistModal({ closeModal, playlist = {} }: Props) {
  const location = useLocation();
  const [editPlaylistMutation] = useEditPlaylistMutation({
    onCompleted: ({ playlistUpdateDetails }) => {
      playlistsEditPlaylist({
        action: ANALYTICS_ACTION.playlistsEdit,
        playlist_id: playlistUpdateDetails.id,
        playlist_name: playlistUpdateDetails.name,
        privacy: playlistUpdateDetails?.hidden === true ? "private" : "public",
        location: getAnalyticsLocationFromPath(location.pathname),
        module: ANALYTICS_MODULE.playlist_details,
      });
      toast.success(`Edited ${playlistUpdateDetails.name} playlist`);
      closeModal();
    },
  });

  const editPlaylist = ({
    playlistName,
    playlistPrivacy,
  }: {
    playlistName: string;
    playlistPrivacy: string;
  }) => {
    editPlaylistMutation({
      variables: {
        input: {
          playlistId: playlist.id,
          name: playlistName,
          hidden: playlistPrivacy === "true",
        },
      },
      refetchQueries: [
        {
          query: GetMyPlaylistsDocument,
          variables: { pagination: { first: 10 }, studioClassIds: [] },
        },
      ],
    });
  };

  return (
    <Modal isOpen onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <H2>Edit Playlist</H2>
        <CloseModalButton onClick={closeModal} variant="light" />
      </Flex>
      <PlaylistForm
        onSubmit={editPlaylist}
        playlistHidden={playlist.hidden}
        playlistName={playlist.name}
        submitButtonText="Edit"
      />
    </Modal>
  );
}
