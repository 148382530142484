import React from "react";
import Icon, { PlayOutline } from "app/components/Icon";
import Tooltip from "app/components/Tooltip";
import { StyledButton } from "./styles";

interface Props {
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export function PreviewIconWithTextButton({ onClick }: Props) {
  return (
    <Tooltip overlay="Preview class">
      <StyledButton
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          // Stop propagation since we handle clicks on Wrapper
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();

          onClick(e);
        }}
      >
        <Icon as={PlayOutline} width="20px" height="20px" />
        Preview
      </StyledButton>
    </Tooltip>
  );
}
