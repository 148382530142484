import React, { useEffect, useRef } from "react";
import Button from "app/components/Button";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import ProgressCircle from "app/components/ProgressCircle";
import { ChevronRight, TagClass } from "app/components/Icon";
import { H4 } from "app/components/Typography";
import Text from "app/components/Text";
import { TagClassWrapper, UploadUserVideoContentWrapper } from "./styles";
import {
  Content,
  VideoPreviewContainer,
  Footer,
  UploadingText,
  UploadedText,
  VideoPreviewOverlay,
  VideoDescriptionLabel,
  Video,
  VideoDescriptionTextarea,
} from "./SubmitPostContent.styles";
import CharacterCountCircle from "./CharacterCountCircle";
import { TaggedClass } from "./TaggedClass";

const CHARACTER_LIMIT = 255;

interface Props {
  videoFile: any;
  canSubmit: boolean;
  percentProgress: number;
  description: string;
  onDescriptionChange(e: any): void;
  onSubmitVideo(): void;
  setIsTaggingClass(isTaggingClass: boolean): void;
  classNumberToTag: number;
  resetTaggedClass(): void;
}

export const SubmitPostContent = ({
  videoFile,
  canSubmit = false,
  percentProgress = 0,
  description,
  onDescriptionChange,
  onSubmitVideo,
  setIsTaggingClass,
  classNumberToTag,
  resetTaggedClass,
}: Props) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoFile && videoRef.current) {
      const fileURL = URL.createObjectURL(videoFile);
      videoRef.current.muted = true;
      videoRef.current.isPlayingFromParent = true;
      videoRef.current.loop = true;
      videoRef.current.src = fileURL;
    }
  }, [videoFile]);

  return (
    <UploadUserVideoContentWrapper>
      <Content>
        <VideoPreviewContainer>
          {videoFile && <Video ref={videoRef} />}
          <VideoPreviewOverlay />
          <ProgressCircle percentProgress={percentProgress} />
        </VideoPreviewContainer>
        <Flex flexDirection="column" width="100%" pl="24px">
          <div>
            <VideoDescriptionLabel>Describe your post</VideoDescriptionLabel>
            <VideoDescriptionTextarea
              placeholder="Add a description (optional)"
              value={description}
              onChange={onDescriptionChange}
            />
            <Flex justifyContent="flex-end" position="relative">
              <Div position="absolute" top="-40px" right="8px">
                <CharacterCountCircle
                  maxLength={CHARACTER_LIMIT}
                  currentLength={description ? description.length : 0}
                />
              </Div>
            </Flex>
          </div>
          <TagClassWrapper onClick={() => setIsTaggingClass(true)}>
            <Flex gap="16px">
              <TagClass />
              <Flex flexDirection="column">
                <H4>Tag a class</H4>
                <Text fontSize="10px" color="monochrome.3">
                  Tag a class to get noticed in the class view
                </Text>
              </Flex>
            </Flex>
            <ChevronRight />
          </TagClassWrapper>
          {classNumberToTag && (
            <TaggedClass
              classNumber={classNumberToTag}
              resetTaggedClass={resetTaggedClass}
            />
          )}
        </Flex>
      </Content>
      <Footer>
        {!canSubmit ? (
          <UploadingText>Uploading...</UploadingText>
        ) : (
          <UploadedText>Uploading complete!</UploadedText>
        )}
        <Button
          disabled={!canSubmit || description?.length > CHARACTER_LIMIT}
          onClick={onSubmitVideo}
        >
          Post Video
        </Button>
      </Footer>
    </UploadUserVideoContentWrapper>
  );
};
